@include media-breakpoint-up(lg) {

    #flyout-cart {
        &.active {
            .flyout-cart {
                display: block;
                transform: translate(0);
            }
        }
    }

    .flyout-cart {
            display: block !important;
            position: absolute;
            top: 68px;
            right: -16px;
            z-index: 100;
            width: 450px;
            box-shadow: 0 0 25px rgba(0,0,0,.15);
            background: white;
            transform: translate(100%);
            transition: all .5s ease-in-out;
            .subtotal {
                color: $brand-secondary;
            }
    }
    .mini-shopping-cart {
        padding: 0 $grid-gutter-width * 2;
        &-header {
            background-color: $gray-200;
            padding: $grid-gutter-width;
            margin-left: -$grid-gutter-width * 2;
            margin-right: -$grid-gutter-width * 2;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
        }
        .items {
            width: 100%;
            .item {
                position: relative;
                padding-top: .6rem;
                padding-bottom: .6rem;
                border-bottom: 1px solid #ddd;
                .item-image {
                    position: absolute;
                    top: $grid-gutter-width;
                }
                .w-100 {
                    padding-left: 70px;
                }
                &:last-of-type {
                    border-bottom: none;
                }
                .quantity {
                    position: absolute;
                    top: 8px;right: 0;
                }
                .price {
                    color: block;
                    font-weight: bold;
                }
            }
        }
        .navbar-cart-product-image {
            max-width: 70px;
            background-color: white;
            padding: 4px;
        }

        .totals {
            display: flex;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 0.7rem 1rem;
            font-size: 1rem;
            border-top: 1px dashed $border-color;
            border-bottom: 1px dashed $border-color;
            margin-top: .5rem;
        }
        .buttons {
            flex-direction: column;
            padding: $grid-gutter-width 0;
        }
    }
     
    .mini-shopping-cart .count a {
        margin: 0 2px;
        font-weight: bold;
    }
    
    .mini-shopping-cart .picture a {
        display: block;
        position: relative;
        overflow: hidden;
    }
    
    .mini-shopping-cart .picture a:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    
    .mini-shopping-cart .picture img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        max-width: 100%;
    }
    
    .mini-shopping-cart .picture + .product {
        margin: 0 0 0 80px;
    }
    
    .mini-shopping-cart .name {
        font-size: 14px;
        width: 70%;
        font-weight: bold;
    }
    
    .mini-shopping-cart .name a {
        color: black;
    }
    
    .mini-shopping-cart .name a:hover {
        color: #4a357e;
    }
    
    .mini-shopping-cart .attributes {
        margin: 5px 0;
        color: #444;
    }
    
}