.filter-close-btn {
    position: relative;
    width: 100%;height: 35px;
    z-index: 100;
    cursor: pointer;
    padding: 6px 12px;
    color: $white;
    background-color: $gray-700;
    border-radius: 0;
    &:hover {
        background-color: $gray-600;
    }
}

.sidebar-offcanvas {
    .left-panel {
        float: left;
        width: 100%;
        padding-top: 20px;
        position: relative;
        min-height: 1px;
        margin-bottom: 30px;
        background-color: $gray-100;
        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }
    }
}

@include media-breakpoint-down(sm) {

    body {
        .sidebar-offcanvas {
            right: -80%;
        }
    }

    body {
        .master-wrapper-page {
            &.row-offcanvas-right {
                &.active {
                    right: 80%;
                }
            }
        }
    }    

    body {
        .row-offcanvas-right {
            &.active {
                .sidebar-offcanvas {
                    width: 80%;
                }
            }
        }
    }

}

@include media-breakpoint-down(md) {

    body {
        .row-offcanvas {
            position: relative;
            -webkit-transition: none .4s ease;
            transition: none .4s ease;
            -webkit-transition-property: top,right,bottom,left,border;
            transition-property: top,right,bottom,left,border;

        }
    }

    body {
        .row-offcanvas-right {
            &.active {
                right: 50%;
            }
        }

        .row-offcanvas-right {
                right: 0;
        }

    }

    .sidebar-offcanvas {
        height: 100%;
        width: 50%;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: white;
        overflow-x: hidden;
        transition: 0.4s;
        padding: 0;
    }

    body {
        .sidebar-offcanvas {
            right: -50%;
        }
    }

    body {
        .row-offcanvas-right {
            &.active {
                .sidebar-offcanvas {
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .offcanvasBtn {
        clear: both;
        display: block;
        width: 100%;
        margin: 0;
        margin-bottom: $grid-gutter-width;
        -webkit-transition: background-color .2s linear;
        transition: background-color .2s linear;
    }

    .sidebar-offcanvas {
        .content {
            overflow: scroll;
            overflow-x: hidden;
            left: 0;
        }
        .content {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .left-panel {
            margin-top: 35px;
            float: left;
            width: 100%;
        }
    }

}

@media (min-width: 992px) {

    .offcanvasBtn,
    .filter-close-btn {
        display: none!important;
    }
}


// MM Menu

