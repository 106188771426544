/********** CHECKOUT **********/

.jumbotron {
    background-color: $gray-100;
}



.checkout-page .section.order-summary {
    margin: 100px 0 30px;
}

.checkout-page .cart-options {
    min-height: 60px;
    overflow: hidden;
}

.checkout-page .cart-footer {
    border-top: none;
}

.checkout-page .cart-footer .total-info {
    margin-bottom: 0;
}

.checkout-page .total-info {
    .cart-total {
        tbody {
            tr {
                &:last-child {
                    border: 0;                            
                }
            }
        }
    }
}

.checkout-page .address-item {
    /*width: 350px;
	max-width: 100%;*/
    margin: 0 auto 40px;
}

.checkout-page .address-item ul {
    margin: 0 0 15px;
    background-color: #f9f9f9;
    padding: 20px;
    color: #444;
}

.checkout-page .address-item li {
    padding: 5px 0;
}

.checkout-page .address-item li.name {
    font-size: 16px;
    font-weight: bold;
}

.enter-address .message-error {
    margin: 20px 0;
}

.enter-address .edit-address {
    border-top: 1px dashed $border-color;
    padding-top: 1rem;
    text-align: left;
}

.checkout-page .ship-to-same-address {
    padding: 30px 0;
}

.checkout-page .ship-to-same-address .selector {
    margin: 0 0 5px;
    font-weight: bold;
    color: #444;
}

.checkout-page .pickup-in-store {
    text-align: center;
}

.checkout-page .pickup-in-store .selector {
    margin: 0 0 5px;
    font-weight: bold;
    color: #444;
}

.select-pickup-point {
    text-align: center;
}

.checkout-page .pickup-points-map {
    min-width: 400px;
    min-height: 350px;
    vertical-align: middle;
    margin-top: 5px;
    margin-bottom: 5px;
}

.shipping-method .method-list,
.payment-method .method-list {
    margin: 0 auto 30px;
    overflow: hidden;
    text-align: center;
    font-size: 0;
}

.method-content {
    border: 1px dashed $border-color;
    padding: $grid-gutter-width / 2;
    border-radius: $border-radius;
    .method-name {
        padding: $grid-gutter-width / 4;
    }
    .method-description,
    .payment-description,
    .custom-radio {
        padding: $grid-gutter-width / 4;
    }
}


.shipping-method .method-list li,
.payment-method .method-list li {
    margin: 20px 0;
    font-size: 14px; /*reset zeroing*/
}

.shipping-method .method-list li label,
.payment-method .method-list li label {
    font-size: 15px;
    font-weight: bold;
    color: black;
    line-height: 19px;
}

.order-review-data {
    .billing-info-wrap,
    .shipping-info-wrap {
        margin-bottom: $grid-gutter-width*2;
        > div {
            padding: $grid-gutter-width;
            border: 1px dashed $border-color;
            border-radius: 6px;
        }
    }
}

.payment-method .use-reward-points {
    margin: 0 0 30px;
    text-align: center;
    color: #444;
}

.payment-method .payment-logo {
    display: inline-block;
    vertical-align: middle;
}

.payment-method .payment-logo label {
    display: block;
    font-size: 0 !important;
}

.payment-method .payment-details {
    min-width: 180px;
    display: inline-block;
    margin: 0;
    text-align: left;
    vertical-align: middle;
}

.payment-info .info {
    padding: 30px 15px;
    color: #444;
}

.payment-info .info tr {
    display: block;
    margin: 0 0 15px;
    font-size: 0;
}

.payment-info .info td {
    display: inline-block;
    width: 100% !important;
    max-width: 400px !important;
    font-size: 14px; /*reset zeroing*/
}

.payment-info .info td:only-child {
    width: 100% !important;
    max-width: 100% !important;
}

.payment-info .info td input[type="text"] {
    width: 100% !important;
}

.payment-info .info td input[name="CardCode"] {
    width: 65px !important;
}

.payment-info .info td select {
    min-width: 70px;
}

.payment-info .info td:first-child {
    margin: 0 0 10px;
}

.payment-info .info p {
    text-align: center;
}

.confirm-order .buttons {
    padding: 10px 0;
}

.confirm-order .button-1 {
    font-size: 16px;
}


.order-review-data li,
.order-details-area li,
.shipment-details-area li {
    padding: 3px 0;
}

.order-review-data .title,
.order-details-area .title,
.shipment-details-area .title {
    margin: 0 0 5px;
    padding: 0;
    @include media-breakpoint-up(lg) {
        text-align: left;
    }

}

#checkout-confirm-order-load {
    #shopping-cart-form {
        
    }

}

.order-review-data .title strong,
.order-details-area .title strong,
.shipment-details-area .title strong {
    font-weight: 700;
}

.order-review-data .payment-method-info,
.order-review-data .shipping-method-info,
.order-details-area .payment-method-info,
.order-details-area .shipping-method-info {
    margin-top: 20px;
}

.order-completed .details {
    margin: 0 0 30px;
}

.order-completed .details div {
    margin: 5px 0;
}

.order-completed .details a {
    color: $brand;
}

.order-completed .details a:hover {
    text-decoration: underline;
}

.opc .step-title {
    margin: 0 0 4px;
    overflow: hidden;
    color: $border-color;
    border-bottom: 1px solid $border-color;
    h2 {
        color: $border-color;
    }
}

.order-title {
    margin-bottom: 1rem;
}



.opc .allow .step-title .title {
    cursor: pointer;
}

.opc .step-title .number,
.opc .step-title .title {
    float: left;
    padding: $grid-gutter-width;
    margin-bottom: 0 !important;
    line-height: 20px;
    font-size: 16px;
}

.opc .allow .step-title .number {
    cursor: pointer;
}

.opc .step-title .number {
    position: relative;
    width: 42px;
    text-align: center;
    font-weight: 700;
    &::before {
        content: "";
        position: absolute;
        left: 0;bottom: 0;
        display: block;
        width: 42px;height: 3px;
        background: $gray-200;
    }
}

.opc .allow .step-title .number {
    &::before {
        content: "";
        background: rgb(255,220,100);
        background: linear-gradient(90deg, rgba(255,220,100,1) 27%, rgba(255,198,0,1) 68%);;
    }
}

.opc .allow {
    .step-title {
        margin: 0 0 4px;
        overflow: hidden;
        color: black;
        border-bottom: 1px solid $border-color;
        h2 {
            color: black;
        }
    }
}



.opc .step {
    margin: 10px 0;
    padding: 35px;
    text-align: center;
}

.opc .section {
    margin: 0 0 30px;
}

.opc .section > label {
    display: block;
    margin: 0 0 10px;
}

.opc input[type="text"],
.opc select {
    max-width: 100%;
}

.opc .buttons {
    margin-bottom: 0;
}

.opc .back-link {
    margin: 0 0 10px;
}

.opc .back-link small {
    display: none;
}

/*
.opc .back-link a {
    display: inline-block;
    margin: 0 0 0 -15px;
    background: url('../images/back-button.png') left center no-repeat;
    padding: 0 0 0 18px;
    color: #444;
}
*/

.opc .buttons .please-wait {
    display: block;
    margin: 10px 0 0;
    background: none;
}

.opc .section.ship-to-same-address {
    margin: 0 0 30px;
    padding: 0;
}

.opc .section.pickup-in-store {
    margin: 0 0 30px;
    padding: 0;
}

.opc .payment-info .info tr {
    text-align: left;
}

.opc .section.order-summary {
    margin: 0;
}



@media all and (min-width: 769px) {

/*** CHECKOUT ***/

    .checkout-page .selected-checkout-attributes {
        float: right;
        text-align: right;
    }

    .checkout-page .cart-footer .totals {
        max-width: 350px;
        margin: 0;
    }

    .checkout-page .address-grid {
        overflow: hidden;
    }

    .checkout-page .address-item {
        float: left;
        width: 48%;
        margin: 0 1% 40px;
    }

    .shipping-method .method-list li,
    .payment-method .method-list li {
        display: inline-block;
        width: 25%;
        margin: 20px 2%;
        vertical-align: top;
    }


    .payment-method .payment-logo {
        display: block;
        background-color: #f6f6f6;
        padding: 10px;
    }

    .payment-method .payment-details {
        display: block;
        width: auto;
        min-width: 0;
        margin: 0;
        text-align: center;
    }

    .payment-info .info {
        padding: 30px 75px;
    }

    /*** ORDER DETAILS & SHIPPING DETAILS ***/

    .order-details-page .section.options {
        text-align: right;
    }

    .order-details-page .selected-checkout-attributes {
        padding: 0 10px;
    }

    .order-details-page .tax-shipping-info {
        text-align: right;
    }
}

@media all and (min-width: 1001px) {

    /*** CHECKOUT ***/

    .order-progress {
        margin: -30px 0 50px;
    }

    .checkout-page .address-item {
        width: 31.33333%;
    }

    .checkout-page .terms-of-service {
        text-align: center;
    }

    .payment-info .info {
        padding: 30px 15px;
    }

    .payment-info .info td:first-child {
        width: 265px !important;
        margin: 0 10px 0 0;
        text-align: right;
    }

    .payment-info .info td:only-child {
        width: 100% !important;
    }

    .opc .order-review-data ul {
        text-align: left;
    }
}

@media all and (min-width: 1367px) {

    /*** CHECKOUT ***/

    .shipping-method .method-list li,
    .payment-method .method-list li {
        margin: 20px 1%;
    }

    .payment-info .info td:first-child {
        width: 375px !important;
    }

    .payment-info .info td:only-child {
        width: 100% !important;
    }
}

.card {
    border:none;
}

.card-block {
    padding:0;
}

.card-header {
    background-color:transparent;
}

.nobr {
    white-space: nowrap !important;
}
.terms-of-service > input, .use-reward-points .checkbox input {
    display: inline-block;
    vertical-align: top !important;
    margin-right: 5px;
}
.terms-of-service > label, .use-reward-points .checkbox label {
    width: auto;
    display: inline-block;
}
.confirm-order .terms-of-service > label {
    width: auto;
}
.order-review-data .shipping-info-wrap {
    float: right;
}

.confirm-order .terms-of-service a {
    position: inherit;
    line-height: 24px;
}

.order-summary-content .table-wrapper,
.wishlist-content .table-wrapper .table {
    background-color: white;
    margin: 0 !important;
    border-color: $border-color !important;
    border-radius: 6px;
}

.order-summary-content {
    
}

.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 0;
    border-top: 0;
}

#checkout-step-confirm-order {
    #confirm-order-buttons-container {
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
}