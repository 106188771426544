/*********** SIDE COLUMN ***********/

.block {
    .title {
        padding: $grid-gutter-width;
        color: black;
        strong {
            font-weight: bold;
        }
    }
}

.block .list .active > a {
    &:hover,
    &:active {
        color: $gray-600;
     }
}

.block .sublist {
    margin: 15px 0 5px;
}

.block .view-all {
    margin: 10px 0 0;
}

.block .tags {
    margin: 5px 0 10px;
}

.block .tags ul {
    font-size: 0;
}

.block .tags li,
.product-tags-all-page li {
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    overflow: hidden;
    font-size: 17px !important; /*setting base size*/
}

.block .tags li a,
.product-tags-all-page li a {
    float: left;
    line-height: 30px;
    color: #444;
}

.block .tags li a:hover,
.product-tags-all-page li a:hover {
    color: $brand;
}

@include media-breakpoint-down(md) {
    .sidebar-offcanvas {
        .block {
            width: 100%;
            padding: 0 8px 8px;
            margin: 0;
        }
    }
}




