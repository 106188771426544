
.admin-header-links {
    background-color: #333;
    text-align: center;
    color: #eee;
}

.admin-header-links * {
    display: inline-block;
    margin: 0 10px;
    line-height: 35px;
    font-size: 12px;
    font-weight: bold;
}

.admin-header-links .impersonate {
    display: inline-block;
}

.admin-header-links .impersonate a {
    background-color: #555;
    padding: 0 15px;
}

.admin-header-links .impersonate a:hover {
    background-color: #666;
}

// 4.2 Version

.c-navbar-top {
    position: relative;
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
        border-top: transparent;
        &::before {
            content: "";
            display: block;
            background: $brand;
            height: 6px;
            background: linear-gradient(90deg, #ffdc64 27%, #ffc600 68%);
        }
    }
    .navbar-nav {
        &--inner {
            position: relative;
            background: $brand;
            background: linear-gradient(90deg, #ffdc64 27%, #ffc600 68%);
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 0 $grid-gutter-width / 2 $grid-gutter-width / 2;
            border-bottom-left-radius: 16px;border-bottom-right-radius: 16px;
            &::before,
            &::after {
                position: absolute;
                content: "";
                top: -6px;height: 0;
                border-top: 32px solid $brand;
            }
            &::before {
                border-left: 32px solid transparent;
                left: -29px;
                border-top: 32px solid #ffdc64;

            }
            &:after {
                border-right: 32px solid transparent;
                right: -29px;
            }
            &-search {
                position: relative;
                button {
                    background-color: transparent;
                    border: 0;
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 1px;height: 1.2rem;
                    top: 50%;right: 0;
                    background-color: black;
                    transform: translateY(-50%);
                }
                input {
                    color: black;
                    background-color: transparent;
                    border: 0;
                    &::placeholder {
                        color: black;
                    }
                }
            }
            &-nav {
                li > a {
                    color: black;
                    font-weight: bold;
                    padding: 0.5rem;
                }
            }
            &-social {
                li {
                    display: inline-block;
                    a {
                        display: block;
                        width: 25px;height: 25px;
                        line-height: 1.6rem;
                        margin: 0 2px;
                        background-color: white;
                        border-radius: 50%;
                        text-align: center;

                    }
                }
            }
        }
    }
}

.c-navbar-middle {
    &--inner {
        @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width;
        }
    }
}

.header-links {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    @include media-breakpoint-up(xl) {
        margin-left: -70px;
    }
    @include media-breakpoint-down(md) {
        justify-content: flex-end;
        position: inherit;
    }
    .nav {
        span.c-user-icon,
        a.c-user-wishlist {
            position: relative;
            display: inline-block;
            border-radius: 50%;
            line-height: 40px;
            font-size: 20px;
            width: 40px;height: 40px;
            background-color: white;
            text-align: center;
            cursor: pointer;
            .wishlist-qty {
                position: absolute;
                top: -5px; right: 0;
                background-color: red;color: white;
                padding: 2px 5px;
                line-height: 1.3;
                font-size: 10px;
                border-radius: 50%;
            }
        }
    }
    .nav .topcartlink {
        display: block;
        color: inherit;
        border-radius: 30px;
        @include media-breakpoint-up(xl) {
            background-color: white;
            color: inherit;
            overflow: hidden;
        }

        &-icon {
            position: relative;
            display: inline-block;
            width: 40px;height: 40px;
            background: $brand-gradient;
            font-size: 20px;
            line-height: 40px;
            border-radius: 50%;
            text-align: center;
            @include media-breakpoint-up(xl) {
                position: absolute;
            }
            .shoppingcart-qty {
                position: absolute;
                top: -5px; right: 0;
                background-color: red;color: white;
                padding: 2px 5px;
                line-height: 1.3;
                font-size: 10px;
                border-radius: 50%;
            }
        }
        &-data {
            display: inline-block;
            width: 160px;height: 40px;
            text-align: center;
            &-qty,
            &-value {
                display: block;
            }
            &-qty {
                padding: 1px $grid-gutter-width /2  1px 40px;
                background-color: $gray-200;font-size: 12px;color: black;
                .number {
                    &::before {
                        content: '(';
                    }
                    &::after {
                        content: ')';
                    }
                }
            }
            &-value {
                padding: 2px $grid-gutter-width 2px 40px;
                color: red;
                font-weight: bold;
            }
        }
    }
    .loggedin,
    .loggedout {
        display: flex;
    }
}


// Logo

.navbar-brand {
    width: 100px;
    margin-right: 0;
    margin-top: -16px;
    @include media-breakpoint-up(lg) {
        width: 135px;
        margin-top: -35px;
    }
    img {
        width: 100%;
    }
}

.header {
    position: fixed;
    top: 0;
    z-index: 10070;
    width: 100%;
    background-color: black;
    @include media-breakpoint-up(lg) {
        position: relative;
        top: 0;left: 0;right: 0;
        z-index: 2;
        background: black;
    }
    .top-menu {
        order: 2;    
         @include media-breakpoint-only(xl) {
            display: block;
            order: 1;
        }
    }
    .main-menu {
       display: none;
    }
}

.topbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

header .topbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.header-selectors-wrapper > div {
    display: inline-block;
    margin: 10px auto;
    vertical-align: middle;
}

.header-selectors-wrapper select {
    width: 100%;
}

.language-list {
    max-width: 100%;
    font-size: 0;
}

.language-list li {
    display: inline-block;
    margin: 0 1px;
    vertical-align: middle;
}

.language-list a {
    display: block;
    position: relative;
    width: 24px;
    height: 32px;
    line-height: 0;
}

.language-list img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

@media (max-width: 991px) {
    #topcartlink {
        display: inline;
    }
}


.header-lower {
    position: relative;
    z-index: 0;
    padding: 35px 0;
    justify-content: space-between;
}

.header-logo {
    margin: 0;
    text-align: center;
    display: block;
}

.header-logo a {
    display: inline-block;
    max-width: 100%;
    line-height: 0;
}

.header-logo a img {
    max-width: 100%;
    opacity: 1;
}

.header-links-wrapper {
    position: relative;
    text-align: right;
}

// SearchBox

.ui-helper-hidden-accessible {
    display: none !important;
}

.ui-autocomplete {
    width: 285px !important;
    border: 1px solid #ddd;
    border-top: none;
    overflow: hidden;
    background-color: #fff;
    text-align: left;
    /*override jQuery UI styles, do not delete doubled properties*/
    border-radius: 0;
    padding: 0;
    font: normal 14px Arial, Helvetica, sans-serif;
}

.ui-autocomplete li {
    border-top: 1px solid #ddd;
}

.ui-autocomplete li:first-child {
    border-top: none;
}

.ui-autocomplete a {
    display: block;
    padding: 15px;
    font-size: 14px;
    /*override jQuery UI styles, do not delete doubled properties*/
    margin: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 15px !important;
    line-height: normal !important;
    color: #777 !important;
}

.ui-autocomplete a:hover,
.ui-autocomplete a.ui-state-focus {
    background-color: #f6f6f6 !important;
}

.ui-autocomplete img {
    display: none;
    min-width: 20px;
    margin: 0 10px 0 0;
    vertical-align: middle;
}

.header-overlay {
    z-index: inherit !important;
}

.header-selectors-wrapper select {
    height: 25px !important;
    padding: 2px !important;
    display: inherit !important;
}


@include media-breakpoint-up(sm) {

    .header-selectors-wrapper > div {
        display: inline-block;
        margin: 10px auto;
    }

    .ui-autocomplete {
        width: 280px !important;
    }

    .ui-autocomplete img {
        display: inline;
    }

}

@include media-breakpoint-up(lg) {

    // Top Language and Currency

    .header-selectors-wrapper {
        float: left;
        border: none;
        padding: 0;
        > div {
            width: auto;
            min-width: 100px;
            margin: 0 12px 0 0;
            line-height: 43px;
        }
    }

    /*** HEADER ***/
    
    .header-upper:after {
        content: "";
        display: block;
        clear: both;
    }

    .language-list {
        text-align: left;
    }
    
    .navbar-expand-lg .navbar-nav .dropdown-menu > .header {
        display:none;
    }

    .navbar > a {
        display: none;
    }

    a.arrow.next, .navbar-expand-lg .navbar-nav ul.dropdown-menu.sub-menusublist.first-level li a.arrow.next {
        display: none;
    }

    .header-logo {
        //display: table-cell;
        text-align: left;
        vertical-align: middle;
        font-size: 0;
    }
    
    
     /*** NAVIGATION ***/
    
    .header-menu {
        position: relative;
        z-index: 5;
        width: 100%;
        margin: 0 auto 30px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 25px 0;
        text-align: center;
    }
    
    .menu-toggle,
    .sublist-toggle {
        display: none;
    }
    
    .header-menu > ul {
        display: block !important; /* prevents toggle display:none on resize */
        font-size: 0;
    }
    
    .header-menu > ul.mobile {
        display: none !important;
    }
    
    .header-menu > ul > li {
        display: inline-block;
        border-left: 1px solid #ddd;
        padding: 0 15px;
        vertical-align: middle;
    }
    
    .header-menu > ul > li:first-child {
        border-left: none;
    }
    
    .header-menu > ul > li > a {
        display: block;
        padding: 5px 10px;
        line-height: 20px;
        font-size: 17px; /*reset zeroing*/
        color: #555;
        cursor: pointer;
    }
    
    .header-menu > ul > li > a:hover {
        color: #4ab2f1;
    }
    
    .header-menu .sublist {
        display: none;
        position: absolute;
        width: 200px;
        box-shadow: 0 0 2px rgba(0,0,0,0.2);
        background-color: #fff;
        padding: 10px 0;
        text-align: left;
    }
    
    .header-menu .sublist li {
        position: relative;
    }
    
    .header-menu .sublist li a {
        display: block;
        position: relative;
        padding: 10px 20px 10px 30px;
        font-size: 14px;
        color: #444;
    }
    
    .header-menu .sublist li a:before {
        content: "";
        position: absolute;
        top: 17px;
        left: 15px;
        width: 5px;
        height: 5px;
        background-color: #ddd;
    }
    
    .header-menu .sublist li:hover > a {
        background-color: #f6f6f6;
    }
    
    .header-menu ul li:hover > .sublist {
        display: block;
    }
    
    .header-menu .sublist .sublist {
        top: -10px;
        left: 100% !important;
    }

}

@include media-breakpoint-up(xl) {

    .header-menu > ul > li {
        padding: 0 20px;
    }

    .header-menu > ul > li > a,
    .header-menu > ul > li > span {
        font-size: 18px;
    }
}

@include media-breakpoint-down(xs) {

    .header-lower > div {
        padding-left: 0;
        padding-right: 0;
    }
    .overview .button-2 {
        width: 100% !important;
    }
    .overview .add-to-cart-button {
        width: auto !important;
    }
    .vendorinfo-page .vendor-picture {
        width: 156px;
    }

}  

@include media-breakpoint-down(sm) {

    .search-input .basic-search .inputs.reversed .custom-control {
        text-align: left;
    }

}  

@include media-breakpoint-down(lg) {

    .header .header-lower .hamburger-icon {
        //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAATCAMAAACwcE1OAAAAP1BMV…MwU0Pv/9/KB94jYW6iozaiQC/rCIPHXAQhPPRKmYhYhxgfpHsJ+WVQkvEAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: center center;
    }

}


.c-header-menu .menu-title {
    @media (max-width: 991px) {
        display: none;
    }
}

.c-header-menu.c-header-menu  {
    @media (max-width: 991px) {
        display: none;
    }
}


.header-links-right-wrapper {
    @include media-breakpoint-up(lg) {
        position: relative;
    }
}

.c-header-signup__wrapper {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block !important;
        position: absolute;
        top: 68px;right: -26px;
        z-index: 100;
        width: 300px;
        box-shadow: 0 0 25px rgba(0,0,0,.15);
        background: $gray-200;
        transform: translate(100%);
        transition: all .5s ease-in-out;
    }
    &.active {
        display: block;
        -webkit-transform: translate(0);
        transform: translate(0);
        &::before {
            content: "";
            position: absolute;
            top: -25px;left: 0;
            width: 100%;height: 30px;
            cursor: pointer;
        }
    }
    li {
        a {
            display: block;
            color: $gray-700;
            padding: $grid-gutter-width;
            &:hover,
            &:active,
            &:focus {
                color: $brand;
            }
            > span {
                color: inherit;
            }
            i {
                margin-right: $grid-gutter-width/2;
            }
        }
    }
}

.c-header-signup__wrapper--mobile { 
    list-style-type:none;
    width: 100%;
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    li {
        a {
            display: block;
            padding: $grid-gutter-width;
            &::before{
                margin-right: 10px;
                //border-bottom: 1px solid $gray-500;
                padding: 5px;
                color: $brand;
                font-size: 12px;
            }
            i {
                margin-right: 5px;
            }
        }
    }
}

.dropdown-menu {
    display: block;
    transform: translateY(-1em);
    transition: all 0.2s ease-in-out 0s, visibility 0s linear 0.2s, z-index 0s linear 0.01s;
    box-shadow: 0 5px 9px rgba(55, 125, 255, 0.075), 0 5px 9px rgba(119, 131, 143, 0.075);
    background: #ebebeb;
    border: 0;
    border-radius: 0;
}


@media (max-width:991px) {
    .header-lower{
        margin: 8px;
        position: static;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
    }
    .header-lower .logo{
        min-width: 114px;
        max-width: 40%;
        /* margin: 0 auto; */        
        display: inline;
    }
    #topcartlink {
        max-width: unset;
        margin: 0; 
        padding: 0 2px;
    }
    .header-links li {
        margin: 0 2px;
    }
    .c-header-kredit {
        width: unset;
    }
}

.c-header-signup__wrapper.active {
    display: block;
}

.c-header-stanje__section {
    background: $gray-200;
    border-bottom: 1px solid $brand;     
    padding-top: 5px;
    margin-bottom: 30px;
    cursor: pointer;
    .role-guests & {
        display: none;
    }
    @media (min-width:992px) {
        margin-bottom: 0;
        padding-top: 17px;
    }
}

.c-header-stanje__wrapper {
    position: relative;
}

.c-header-stanje {
    display: none;
}


.c-header-stanje__botun {
    border: 1px solid $brand;
    border-top: $gray-200;
    width: max-content;
    position: absolute;
    right: 0;
    top: 0px;
    background: $gray-200;
    z-index: 2;
    cursor: pointer;
    @media (max-width:991px) {
        right: 50%;
        transform: translate(50%, 0);
    }
}

.c-header-stanje__botun--white {
    width: 100%;
    position: absolute;
    height: 15px;
    top: -2px;
    left: 0;
    background:$gray-200;
}
.c-header-stanje__botun--border {
    height: 16px;
    text-transform: uppercase;
    padding: 5px 40px 0 40px;
    background:$gray-200;
    span {
        font-size: 14px;
        font-weight: 500;
        color: $brand;
        background:$gray-200;
        position: relative;
        top: -15px;
        z-index: 9999;
        @media (max-width:991px) {
            font-size: 12px;   
            top: -9px;
        }

    }
}

.c-header-stanje__botun:before {
    content: " ";
    width: 0px;
    height: 0px;
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 9px solid $brand;
    position: absolute;
    top: 17px;
    left: 80px;
    @media (max-width:991px) {
       display: none;
    }
}

.c-header-stanje__botun-wrap {
    position: relative;
}

.html-contact-page, 
.html-topic-page {
    .c-header-catalog-badge__wrapper{
        display: none;
    }
}

.c-header-stanje__text-wrap {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    text-align: left;
    @media (max-width:575px) {
        display: block;
     }
    .c-header-stanje__partner {
        @media (max-width:575px) {
            padding-left: 20px; 
         }
    }
    .c-header-stanje__ukupno,
    .c-header-stanje__isteklo {
        padding-left: 20px; 
    }
    span {
        padding-left: 5px;
    }
}


.l-mobile-header {
    &--inner {
        padding: 0 $grid-gutter-width $grid-gutter-width;
        &-decoration {
            position: relative;
            background-color: $brand;
            padding: 10px;
            width: 50%;
            margin: auto;
            border-bottom-left-radius: 16px;border-bottom-right-radius: 16px;
            &::before,
            &::after {
                position: absolute;
                content: "";
                top: 0;height: 0;
                border-top: 18px solid $brand;
            }
            &::before {
                border-left: 24px solid transparent;
                left: -16px;
            }
            &:after {
                border-right: 24px solid transparent;
                right: -16px;
            }
        }
        .mobile-links {
            li {
                margin: 0 2px;
            }
        }
        .search-icon {
            position: relative;
            display: inline-block;
            border-radius: 50%;
            line-height: 40px;
            font-size: 20px;
            width: 40px;
            height: 40px;
            background-color: white;
            text-align: center;
        }
    }
    .search-box {
        background-color: white;
        .c-search-box__wrapper {
            width: 100%;
            padding: 0;
        }

    }
}


