/* ==========================================================================
   GENERIC / #RESET
   ========================================================================== */


/**
 * A very simple reset that sits on top of Normalize.css.
 *
 */

 body,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 blockquote,
 pre,
 dl,
 dd,
 ol,
 ul,
 form,
 fieldset,
 legend,
 figure,
 table,
 th,
 td,
 caption,
 hr {
   margin: 0;
   padding: 0;
 }
 
 // Remove trailing margins from nested lists.
 li> {
   ul,
   ol {
     margin-bottom: 0;
   }
 }
 
 // Remove default table spacing.
 table {
   border-collapse: collapse;
   border-spacing: 0;
 }
 
 // We'll also add max height to svg elements
 svg {
   max-height: 100%;
 }
 
 ul, 
 ol {
  list-style-type:none;
 }

/*********** CSS RESET **********/

* {
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  margin: 0;
  -webkit-text-size-adjust: none;
}

body.dark-theme {
  background-color: $gray-100;
}

ol, ul {
  list-style: none;
}

a img {
  border: none;
}

a:active {
  outline: none;
}

input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  margin: 0;
  border: 0;
  padding: 0;
}

input:-webkit-autofill, textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f8f8f8 inset !important;
}


input[type="button"],
input[type="submit"],
input[type="reset"] {
  -webkit-appearance: none;
}

.form-group input[type="text"], .form-group input[type="password"], .form-group input[type="email"], .form-group input[type="tel"], .form-group textarea, .form-group select, .checkout-data select  {
  height: 50px !important;
  border: none;
  padding: $grid-gutter-width;
  vertical-align: middle;
  font-size: 15px;
  background: $gray-100;
  border-radius: 6px;
  &:focus {
    background: $gray-100;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #fff;
}

script {
  display: none !important;
}

 /*********** GLOBAL STYLES **********/

body {
  max-width: 100%;
  overflow-x: hidden;
 // background-color: #fff;
  font: normal 14px Roboto, Helvetica, sans-serif;
  color: $gray-700;
  background-color: $white;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
}

a {
  color: $gray-600;
  cursor: pointer;
  &:hover {
      color: $brand;
  }
}

a img {
  opacity: 0.99; /*firefox scale bug fix*/
}

table {
  width: 100%;
  border-collapse: collapse;
}

textarea {
  min-height: 150px;
}

select {
  min-width: 50px;
  height: 36px !important; /*safari padding fix*/
  padding: 6px !important;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus,
textarea:focus, select:focus {
  color: #444;
}

input[type="checkbox"],
input[type="radio"],
input[type="checkbox"] + *,
input[type="radio"] + * {
  vertical-align: middle;
}

input[type="button"], input[type="submit"],
button, .button-1, .button-2 {
  cursor: pointer;
}

label, label + * {
  vertical-align: middle;
}

.master-wrapper-content {
  position: relative;
  z-index: 0;
  margin: 0 auto;
  padding: 130px 0;
  @include media-breakpoint-up(lg) {
    padding: $grid-gutter-width * 3 0;
  }
}

.html-home-page,
.html-category-page {
   .master-wrapper-content {
    padding: 0;
   }
}

.master-column-wrapper:after {
  content: "";
  display: block;
  clear: both;
}

.center-1 {
  margin: 0 0 100px;
}

.center-2, .side-2 {
  margin: 0 0 50px;
}

.side-2:after {
  content: "";
  display: block;
  clear: both;
}

.page {
  min-height: 400px;
}

.page-title h1 {
  font-weight: normal;
}

.title-underline {
  position: relative;
  padding: 0 0 $grid-gutter-width;
  margin: 0 0 $grid-gutter-width * 2;
  border-bottom: 1px solid $border-color;
  text-transform: uppercase;
  text-align: center;
  &:before {
    content: '';
    width: 75px;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -2px;
    background: $brand;
    transform: skewX(-30deg);
  }
}

.cross-sells {
  .title {
    text-align: left;
  }
}


.title {
  position: relative;
  padding: 0 0 $grid-gutter-width;
  margin: 0 0 $grid-gutter-width * 2;
  text-transform: uppercase;
  text-align: center;
}

.articlepost-page,
.account-page,
.checkout-page,
.order-details-page,
.topic-page,
.contact-page,
.registration-page,
.password-recovery-page,
.compare-products-page,
.email-a-friend-page,
.search-page .search-input,
.shopping-cart-page
  {
  background-color: white;
  padding: $grid-gutter-width * 2;
  border-radius: 6px;
}

.c-global-breadcrumbs {
  display: none;
}

.block-title {
  font-weight: 300;
  margin-bottom: $grid-gutter-width * 3;
  text-transform: uppercase;
}

body {
  .block-title__underline {
    position: relative;
    line-height: normal;
  }
}

.center-1 .page-title,
.center-1 .title {
  text-align: center;
}

.link-rss {
  display: none;
  width: 24px;
  height: 24px;
  background: url('../images/rss.png') center no-repeat;
  font-size: 0 !important;
}

.category-description ul,
.manufacturer-description ul,
.full-description ul,
.topic-block ul,
.topic-page ul,
.post-body ul {
  margin: 12px 0;
  padding: 0 0 0 36px;
  list-style: disc;
}

.category-description ol,
.manufacturer-description ol,
.full-description ol,
.topic-block ol,
.topic-page ol,
.post-body ol {
  margin: 12px 0;
  padding: 0 0 0 36px;
  list-style: decimal;
}

.flyout-cart {
  display: none;
}

/*********** GLOBAL FORMS ***********/

.inputs {
    position: relative;
    /*margin: 0 0 15px;*/
    text-align: left;
    white-space: nowrap; /*fix for 'required' elements*/
}

.inputs:after {
  content: "";
  display: block;
  clear: both;
}

.inputs input[type="text"],
.inputs input[type="email"],
.inputs input[type="tel"],
.inputs input[type="password"],
.inputs select, .inputs textarea {
  display: block;
  height: 50px;
  width: 100%;
  max-width: 100%;
}

.inputs.custom-attributes {
  white-space: normal;
}

.inputs .option-list {
  display: inline-block;
  max-width: 95%;
  vertical-align: middle;
}

.inputs .option-list li {
  display: inline-block;
  margin: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 8px 10px;
}

.inputs .option-list label {
  display: inline-block;
  width: auto !important;
  margin: 0 0 0 5px;
}

.inputs.reversed {
  margin: 0 0 20px;
  text-align: center;
}

.required {
  vertical-align: baseline;
  font-size: 20px;
  font-weight: normal;
  color: #e4434b;
}

.message-error,
.field-validation-error,
.username-not-available-status,
.poll-vote-error, .password-error {
  display: block;
  font-size: 13px;
  color: #a94442;
  margin-top: 5px;
  display: inline-block;
}

.field-validation-valid,
.username-available-status {
  display: block;
  text-align: center;
  font-size: 13px;
  color: #4cb17c;
}

.captcha-box {
  text-align: right;
  line-height: 0; /*firefox line-height bug fix*/
}

.captcha-box > div {
  display: inline-block;
  max-width: 100%;
}

.captcha-box input {
  height: auto;
}

.custom-attributes ul.option-list li,
.vendor-attributes ul.option-list li {
  display: inline-block;
}

/*********** GLOBAL TABLES ***********/

.forum-table th.replies,
.forum-table th.views,
.forum-table th.votes,
.forum-table th.latest-post,
.forum-table th.topics,
.forum-table th.posts,
.forum-table td.replies,
.forum-table td.views,
.forum-table td.votes,
.forum-table td.latest-post,
.forum-table td.topics,
.forum-table td.posts {
  display: none;
}

.forum-table td.image {
  min-width: 0;
}

.forum-table td {
  min-width: 90px;
  padding: 10px;
}

.cart a:hover,
.data-table a:hover,
.forum-table a:hover {
  text-decoration: underline;
}

.cart .product,
.data-table .product,
.data-table .message,
.data-table .info,
.data-table .name {
  min-width: 225px;
  text-align: left;
}

.forum-table .forum-details,
.forum-table .topic-details {
  text-align: left;
}


.cart .product .edit-item {
  margin: 10px 0 0;
}

.cart .product .edit-item a:hover {
  text-decoration: underline;
}

.cart td.unit-price,
.data-table td.unit-price {
  white-space: nowrap;
}

.cart td.subtotal,
.data-table td.total {
  white-space: nowrap;
  color: #444;
}

.cart td.subtotal .discount, .cart td.subtotal .discount-additional-info {
  color: $brand;
}

.data-table em a {
  font-weight: bold;
  color: #444;
}

.data-table div.download {
  margin: 10px 0 0;
}

/*********** NOTIFICATIONS & POPUPS  ***********/

.bar-notification {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  padding: 15px 25px 15px 10px;
  line-height: 16px;
  color: #fff;
  opacity: 0.95;
}

.bar-notification.success {
  background-color: #4bb07a;
}

.bar-notification.error {
  background-color: #e4444c;
}

.bar-notification.warning {
  background-color: #f39c12;
}

.bar-notification .content {
  margin: 0 10px 0 0;
}

.bar-notification .content a {
  color: #fff;
  text-decoration: underline;
}

.bar-notification .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  margin: 7px;
  background: #fff url('../images/close.png') center no-repeat;
  cursor: pointer;
}

.noscript {
  border-bottom: 1px solid #333;
  background-color: #ff9;
  padding: 30px 15px;
  text-align: center;
  line-height: 22px;
  color: #444;
}

.ajax-loading-block-window {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  background: url('../images/loading.gif') center no-repeat;
}

.please-wait {
  background: url('../images/ajax-loader-small.gif') no-repeat;
  padding-left: 20px;
  font-size: 14px;
}

.ui-dialog {
  max-width: 90%;
  border: 1px solid #ddd;
  box-shadow: 0 0 2px rgba(0,0,0,0.15);
  overflow: hidden;
  background-color: #fff;
  /*override jQuery UI styles, do not delete doubled properties*/
  border-radius: 0;
  padding: 0;
  font: normal 14px Arial, Helvetica, sans-serif;
}

.ui-dialog:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.ui-dialog-titlebar {
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  background-color: #eee;
  padding: 10px 15px;
  /*override jQuery UI styles, do not delete doubled properties*/
  border-width: 0 0 1px;
  border-radius: 0;
  background-image: none;
  padding: 10px 15px !important;
  font-weight: normal;
  cursor: auto !important;
}

.ui-dialog-titlebar > span {
  float: left;
  font-size: 18px;
  /*override jQuery UI styles, do not delete doubled properties*/
  margin: 0 !important;
}

.ui-dialog-titlebar button {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  border: none;
  overflow: hidden;
  background: url('../images/close.png') center no-repeat;
  font-size: 0;
  /*override jQuery UI styles, do not delete doubled properties*/
  top: 0 !important;
  right: 0 !important;
  width: 42px !important;
  height: 42px !important;
  margin: 0 !important;
  border: none !important;
  border-radius: 0;
  background: url('../images/close.png') center no-repeat !important;
  padding: 0 !important;
}

.ui-dialog-titlebar button span {
  display: none !important;
}

.ui-dialog-content {
  padding: 15px;
  line-height: 20px;
  /*override jQuery UI styles, do not delete doubled properties*/
  background-color: #fff !important;
  padding: 15px 15px 20px 15px !important;
  color: #777;
}

.ui-dialog-content .page {
  min-height: 0;
}

.ui-dialog-content .page-title {
  min-height: 0;
  margin: 0 0 15px;
  padding: 0px 10px 10px 10px;
  text-align: center;
}

.ui-dialog-content .page-title h1 {
  font-size: 24px;
  line-height: 30px;
}

.ui-dialog-content .back-in-stock-subscription-page {
  text-align: center;
}

.ui-dialog-content .back-in-stock-subscription-page .tooltip {
  margin-bottom: 10px;
}

.ui-dialog-content .back-in-stock-subscription-page .button-1 {
  border: none;
  background-color: #4ab2f1;
  padding: 10px 15px;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}

.ui-dialog-content .back-in-stock-subscription-page .button-1:hover {
  background-color: #248ece;
}

.eu-cookie-bar-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  margin: 0;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), -3px -4px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), -3px -4px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 20px;
  text-align: center;
}

.eu-cookie-bar-notification .text {
  margin-bottom: 20px;
  line-height: 20px;
}

.eu-cookie-bar-notification button {
  margin: 5px 0 10px;
}

.eu-cookie-bar-notification a {
  display: block;
}

.eu-cookie-bar-notification a:hover {
  text-decoration: underline;
}

/* Web Styles for Root Theme */

.widget-block {
  margin-bottom: $grid-gutter-width * 4;
}


.home-page-polls li.answer label > span {
  margin-bottom:0 !important;
}

.custom-control, .write-review .review-rating li.first, .write-review .review-rating li.last,
.accept-privacy-policy label{
  line-height: 24px;
}

.private-message-send-page a, .attributes label {
  line-height: 28px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem !important;
}

table {
  display:table !important;
}
.gender span > * {
  display: inline-block !important;
  line-height: 24px !important;
}

.form-control[readonly] {
  background-color:#fff;
}

.ml-10 {
  margin-left:10px;
}
.ml-12 {
  margin-left: 12px;
}
a:focus, a:hover {
  text-decoration:none;
}

// Btn Primary

body .btn {
  border: 0;
  text-transform: uppercase;
  padding: 14px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 30px !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover,
  &:active,
  &:focus {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
}

.btn-primary {
  background: rgb(255,220,100);
  background: linear-gradient(90deg, rgba(255,220,100,1) 27%, rgba(255,198,0,1) 68%);
}





.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus,
.btn-primary.focus, .btn-primary:focus, .form-control:focus, .btn.focus, .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
textarea#AddProductReview_ReviewText,
textarea#PersonalMessage {
    height: 100%;
}
.admin-header-links a:hover, .admin-header-links a:focus {
  color: #b7b7b7;
}

/* Start Navbar style css */
.navbar-light .navbar-nav .nav-link, .navbar-expand-lg .navbar-nav li {
  color: #555;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 5px 10px;
}
.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #4ab2f1;
}
.navbar-expand-lg .navbar-nav ul.dropdown-menu.sub-menusublist.first-level li a {
  display: block;
  position: relative;
  padding: 10px 20px 10px 30px;
  color: #444;
}
.navbar-expand-lg .navbar-nav ul.dropdown-menu.sub-menusublist.first-level li:hover > a {
  background-color: #f6f6f6;
}
.navbar {
  padding:0;
}
.nav-link {
  padding: .2em 1em;
}
.dropdown-menu {
  padding: 0;
}
.navbar-expand-lg .navbar-nav ul.dropdown-menu.sub-menusublist.first-level li a:before {
  content: "";
  position: absolute;
  top: 17px;
  left: 15px;
  width: 5px;
  height: 5px;
  background-color: #ddd;
}
.navbar-expand-lg .navbar-nav ul.dropdown-menu.sub-menusublist.first-level li {
  padding: 0;
  border-left:none !important;
}
.navbar ul.navbar-nav > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level {
  margin-left: 158px;
  top: 10px;
}
.navbar ul.navbar-nav > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level {
  margin-left: 158px;
  top: 10px;
}
.navbar ul.navbar-nav > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level > li.nav-item.dropdown > ul.dropdown-menu.sub-menusublist.first-level {
  margin-left: 158px;
  top: 10px;
}
.navbar-expand-lg .navbar-nav li {
  border-left: 1px solid #ddd;
  padding: 0 20px;
}
.navbar-expand-lg .navbar-nav li:first-child {
  border-left: none;
}
.navbar-expand-lg .navbar-nav {
  margin: 0 auto;
}
/* End Navbar style css */

.block.block-popular-tags .nav-link {
  padding:0 !important
}
.block {
  display: block;
}

ul {
  margin-bottom:0;
}
select {
}
.dropdown-item.active, .dropdown-item:active {
  background-color: transparent !important;
}
#store-theme {
  margin: 0 auto;
}
.grid-list-icon {
  float: right;
  text-align: right;
}
.remove_right_padding.grid-list-icon {
  padding: 0 !important;
  width: auto !important;
}

.form-control {
  border-radius: 0;
  padding: 6px;
}

.post-body ul {
  text-align:left;
}

.breadcrumb {
  width: 100%;
}

label.remember {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  line-height: 24px;
  margin-left: 10px;
}
.forgot-password {
  line-height: 24px;
}
.address-list .btn-primary:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.forum-edit-page strong {
  line-height:28px;
}

.table-wrapper label, .shipping-method label, .address-list label, .product-review-item label, .topic-post label, .accept-privacy-policy label,
.private-message-view-page label, .apply-vendor-page label, .terms-of-service label, .blogpost-page label, .forums-table-section label,
.checkout-page label, .news-item-page label, .profile-page label {
    margin-bottom: 0 !important;
}
.private-messages-box .select label, .forum-subscriptions-page .select-boxes label {
    margin-right: 0;
}
.rowcheckbox {
    font-size: 0;
}
.dropdown-toggle::after {
    content:none;
}
.newsletter-validation, .newsletter-result {
    font-size: 13px;
    color: #a94442;
}
.subscribe .custom-control, .unsubscribe .custom-control {
    display: inline-block;
}
.unsubscribe {
    padding-left: 10px;
}

.ship-to-same-address .custom-checkbox, .shipping-method .custom-radio, .pickup-in-store .custom-checkbox, .use-reward-points .custom-checkbox, .terms-of-service .custom-checkbox,
.forum-edit-page .custom-checkbox, .basic-search .custom-checkbox, .customer-info-page .custom-checkbox, .remove-from-cart .custom-checkbox, .add-to-cart .custom-checkbox,
.attributes .custom-checkbox, .attributes .custom-radio, .payment-details .custom-radio, .use-reward-points .custom-checkbox {
    display: inline-block;
}

.private-message-view-page .message-body {
  border-top: none !important;
}
.btn {
  border-radius: 0 !important;
}
.block.block-popular-blogtags .nav-link {
  padding: 0 !important;
}

.master-wrapper-page {
  overflow: hidden;
}

@media all and (max-width: 1000px) {

/*** GLOBAL TABLES ***/

.cart {
    display: block;
}

.order-details-page .data-table {
    display: block;
}

.order-details-page .data-table colgroup,
.order-details-page .data-table thead {
    display: none;
}

.order-details-page .data-table tbody {
    overflow: hidden;
}

.order-details-page .data-table td {
    display: block;
    border: none;
    padding: 10px;
}

.order-details-page .data-table td.product,
.order-details-page .data-table td.tracking-number {
    min-width: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px 10px 15px;
    text-align: center;
}

/*** MOBILE NAVIGATION ***/

.html-home-page section {
  padding-top: 74px;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
}

section {
  padding-top: $grid-gutter-width * 9;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
}




.header-menu {
  position: relative;
  z-index: 1;
  width: 90%;
  max-width: 600px;
  margin: 0 auto 40px;
}

.menu-toggle {
  background: #4ab2f1 url('../images/menu-icon.png') right center no-repeat;
  padding: 15px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}


.top-menu > li {
  position: relative;
  margin: 1px 0;
}

.top-menu .sublist {
  display: none;
  background-color: #fff;
  padding: 5px 0;
}

.top-menu .sublist li {
  position: relative;
  margin: 1px 0 1px 20px;
}

.top-menu .sublist li a {
  display: block;
  padding: 15px 18px;
  font-size: 15px;
}

.sublist-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  border-left: 1px solid #fff;
  background: url('../images/toggle-black.png') center no-repeat;
  padding: 15px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

.sublist .sublist-toggle {
  height: 49px;
}

.sublist .sublist li {
  background-color: #f6f6f6;
}

.sublist .sublist .sublist li {
  background-color: #fff;
}

.block-category-navigation {
 // display: none;
}

.block-account-navigation {
  float: none;
  margin: auto !important;
}


.html-product-details-page .product-tags-box {
  margin: 0 auto;
}

}

@media all and (min-width: 769px) {

  /**** GLOBAL ***/

  .home-page-polls li {
      min-width: 100px;
  }

  /**** GLOBAL TABLES ***/

  .order-details-page .data-table tr {
      width: 100%;
      margin: 0;
  }

  .order-details-page .data-table tr:nth-child(2n+1) {
      clear: none;
  }

  .order-details-page .data-table tr:nth-child(3n+1) {
      clear: both;
  }

}

@media all and (min-width: 992px) {

  /*** GLOBAL STYLESnop accelerate ***/

  .center-2 {
      float: right;
  }

  .side-2 {
      float: left;
  }

  .page {
      text-align: left;
  }

  .inputs input[type="text"],
  .inputs input[type="email"],
  .inputs input[type="tel"],
  .inputs input[type="password"],
  .inputs select {
      min-height: 50px;
  }

  .inputs .option-list li {
      margin: 0 10px 0 0;
  }

  .center-1 .buttons {
      text-align: center;
  }

  .page-title a + h1 {
      float: left;
  }

  .link-rss {
      display: block;
      float: right;
      margin: 6px 0 0;
  }

  /*** GLOBAL TABLES ***/

  .cart tr {
      width: auto;
      margin: 0;
  }

  .order-details-page .data-table tr {
      width: auto;
      margin: 0;
  }

  .order-details-page .data-table label {
      display: none;
  }

}

@media (min-width: 1000px) {

  .no-pr {
      padding-right: 0
  }

  .no-pl {
      padding-left: 0
  }

  .no-plr {
      padding-left: 0;
      padding-right: 0;
  }
}

@media all and (min-width: 1001px) {

    /*** GLOBAL STYLE S ***/

    .center-2 {
        float: right;
        width: 73%;
    }

    .side-2 {
        float: left;
        width: 25%;
    }

    .page {
        text-align: left;
    }

    .inputs input[type="text"],
    .inputs input[type="email"],
    .inputs input[type="tel"],
    .inputs input[type="password"],
    .inputs select {
        min-height: 40px;
    }

    .inputs .option-list li {
        margin: 0 10px 0 0;
    }

    .center-1 .buttons {
        text-align: center;
    }

    .center-2 .inputs label {
        width: 136px;
    }

    .page-title a + h1 {
        float: left;
    }

    .link-rss {
        display: block;
        float: right;
        margin: 6px 0 0;
    }

    /*** GLOBAL TABLES ***/

    .cart tr {
        width: auto;
        margin: 0;
    }

    .cart label {
        display: none;
    }
        .cart label.custom-control-label {
            display: block;
        }
    .order-details-page .data-table tr {
        width: auto;
        margin: 0;
    }

    .order-details-page .data-table label {
        display: none;
    }

    .html-home-page .theme-custom {
      margin: 0 0 60px !important;
  }

}

@media (max-width:575px) {

  .attributes .datepicker {
      margin: 0 auto;
  }
  .navbar-expand-lg .navbar-nav ul.dropdown-menu.sub-menusublist.first-level li a.arrow.next {
      padding: 10px;
  }
}

@media (min-width: 768px) {

  .attribute-label {
      text-align: right;
  }
  .message-error,  .username-not-available-status, .poll-vote-error, .password-error {
      margin-top: -15px;
  }

  .enter-address .col-form-label,
  .product-reviews-page .col-form-label, .email-a-friend-page .col-form-label, .password-recovery-page .col-form-label, .address-edit-page .edit-address .col-form-label, .return-request-page .col-form-label,
  .change-password-page .col-form-label, .forum-edit-page .col-form-label, .private-message-send-page .col-form-label,
  .news-item-page .col-form-label, .apply-vendor-page .col-form-label, .move-topic-page .col-form-label,
  .blogpost-page .col-form-label, .forum-search-page .col-form-label, .returning-wrapper .col-form-label, .vendorinfo-page .col-form-label,
  .check-gift-card-balance-page .col-form-label{
      text-align: left;
  }
  .forum-subject, .topic-subject, input#Subscribed {
      text-align: left;
  }
  .rental-attributes .attribute-label {
      text-align: left;
  }
  .move-topic-page .col-form-label {
      text-align: left;
  }
}

.home-page-polls li .custom-control {
  text-align: left;
}

.opc .section > label {
  margin-bottom: 10px !important;
}

.apply-vendor-page .terms-of-service > * {
  width: auto;
  line-height: 25px !important;
}

.overview-buttons .btn {
  text-transform: inherit !important;
}

.nav-pills {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.tabSelect {
  display: block;
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}