

.html-home-page .master-wrapper-page {
    padding-top: 100px;
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

.html-home-page  {
    .header {
        @include media-breakpoint-up(lg) {
            position: absolute;
            background: rgba(0, 0, 0, 0.9);
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
        }
    }
}


.spc-products,
.l-homepage-featured,
.l-hompege-shopswitcher {
    background-color: white;
}


// Main Slideshow

.nivo-directionNav {
    position: absolute;
    bottom: 0;right: 0;
    display: block;
    font-size: 0;
    z-index: 999;
    a {
        position: relative;
        cursor: pointer;
        display: inline-block;
        width: 40px;
        height: 60px;
    }
}

.l-main-slider {
    position: relative;
    .nivo-caption {
        width: auto;
        bottom: 50%;left: 5%;right: 5%;
        font-size: 20px;
        padding: 5px;
        opacity: 1;
        background: rgba(0, 0, 0, 0.3);
        text-align: center;


        @include media-breakpoint-up(lg) {
            bottom: 35%;left: 5%;right: auto;
            font-size: 38px;
            padding: $grid-gutter-width;
            text-align: left;
        }
    }
    p {
        margin-bottom: 0;
        text-transform: uppercase;
    }
    .nivo-controlNav {
        display: none;
        position: absolute;
        bottom: 2%;left: 0;right: 0;
        z-index: 9;
        @include media-breakpoint-up(lg) {
            display: block;
        }
        .active {
            border: 2px solid $brand;
        }
    }
}

.c-bricks-top {
    position: absolute;
    left: 2%;top: 2%;
    &--inner {
        position: relative;
    }
    .c-bricks-item {
        width: 25px;
        height: 25px;
        background-color: $brand;
        margin: $grid-gutter-width / 2;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        &:nth-child(1) {
            background-color: $brand-secondary;
        }
        &:nth-child(3) {
            position: absolute;
            top: -$grid-gutter-width / 2;
            left: 32px;
        }
    }
}

.c-bricks-bottom {
    position: absolute;
    right: 2%;bottom: 2%;
    &--inner {
        position: relative;
    }
    .c-bricks-item {
        width: 25px;
        height: 25px;
        background-color: $brand;
        margin: $grid-gutter-width / 2;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        &:nth-child(2) {
            background-color: $brand-secondary;
        }
        &:nth-child(3) {
            position: absolute;
            bottom: -$grid-gutter-width / 2;
            right: 33px;
        }
    }
}

// Shop Hightlights (HTML Widget)

.l-homepage-highlights {
    background: url(images/body-bg.gif);
    &--inner {
        max-width: 1440px;
        margin: auto;
    }
    &-column {
        position: relative;
        min-height: 60px;
    }
    &-icon {
        position: absolute;
        width: 60px;height: 60px;
        background-color: $gray-900;
        border-radius: 100%;
        img {
            position: absolute;
            top: 0;bottom: 0;right: 0;left: 0;
            margin: auto;
        }
    }
    &-text {
        color: white;
        padding-left: 80px;
        padding-right: $grid-gutter-width;
        &-heading {
            font-weight: bold;
            padding: 20px 0;
        }
        p {
            font-size: 13px;
            font-weight: 300;
            margin-bottom: 0;
        }
    }
}

// Homepage Product Tabs (HTML + JCarusel)

.l-homepage-bike-featured,
.l-homepage-parts-featured {
    .c-product-item {
        border: 1px solid $gray-200;
    }
    .c-tabs-image {
        padding-right: 0;
        background-color: #dfad00;
        &--container {
            background-size: cover;
            background-position: 50%;
            height: 100%;
            position: relative;
            transition-property: transform;
            transition-duration: .5s;
            transition-timing-function: ease-in-out;
            border-radius: 0;
        }
    } 
    .block-title {
        margin-bottom: 0;
    }   
}

.l-homepage-bike-featured {
    .c-tabs-image {
        padding-right: 0;
        background-color: #dfad00;
        border-bottom: 1px solid #ddd;
        &--container {
            background-image: url("/images/left-bike-img.jpg");;
        }
    }
    .product-grid {
        .item-box {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 0;
            @media (min-width: 576px) {
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 0;
            }
            @media (min-width: 768px) {
                flex: 0 0 25%;
                max-width: 25%;
                margin-bottom: 0;
            }
            .product-item {
                box-shadow: none;
                border-radius: 0;
                margin: -1px 0 0 0;
                border-top: 0;border-left: 0;
            }
        }
    }
}

.nop-jcarousel {
    &.product-grid {
        .title {
            font-size: 2.125rem;
            line-height: 1;
            font-weight: 300;
            margin-bottom: 32px;
            text-transform: uppercase;
            position: relative;
            padding: 0;
            text-align: left;
        }
    }
}



.l-homepage-parts-featured {
    margin-top: -2px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    .product-grid {
        .item-grid {
            padding: $grid-gutter-width;
        }
        .item-box {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 0;
            padding: $grid-gutter-width / 2;
            @media (min-width: 576px) {
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 0;
                padding: $grid-gutter-width / 2;
            }
            @media (min-width: 768px) {
                flex: 0 0 25%;
                max-width: 25%;
                margin-bottom: 0;
            }
        }
    }
}

// Homepage Product Parts

.l-homepage-parts-featured {
    .c-parts-content {
        .item-box {
            .c-product-item-actions {
                display: none !important;
            }
        }
    }
    .c-tabs-image--container {
        background-image: url("/images/right-parts-img.jpg");
    }
}

// Brands Carusel

.l-homepage-brands {
    position: relative;
    &::before {
        position: absolute;
        content: "";
        width: 0;height: 0;
        border-left: 60px solid transparent;
        border-right: 60px solid transparent;
        border-top: 30px solid $gray-100;
        left: 0;right: 0;
        bottom: -29px;
        margin: auto;
    }
    .manufacturer-grid {
        padding: $grid-gutter-width 0;
    }
    .slick-carousel {
        max-width: 1140px;
        margin: auto;
    }
    .item-box {
        padding: $grid-gutter-width;
        margin: 0;
        opacity: 0.8;
        .manufacturer-item {
            position: relative;
            min-height: 80px;
        }
        &:hover,
        &:active,
        &:focus {
            opacity: 1;
        }
    }
    .title {
        display: none;
        button {
            position: absolute;
            margin: auto;
            top: 0;bottom: 0;
            width: 40px;height: 40px;
            background-color: #ebebeb;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-size: 0;
            border-width: 0;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
            transition: all .15s ease;
            border-radius: $border-radius;
            z-index: 9;
            &:hover,
            &:active,
            &:focus {
                background-color: #ddd;
                transform: translateY(-1px);
                box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
                outline: none;
            }
            &:before {
                content: "";
                text-transform: none;
                font-size: 14px;
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                top: 0;left: 0;right: 0;bottom: 0;margin: auto;
                transition: all 0.2s ease-in-out;
                line-height: 2.9;
            }
            &.slick-next {
                &:before {
                    content: "\f054";
                }
            }
            &.slick-prev {
                &:before {
                    content: "\f053";
                }
            }
        }
    }
    .picture {
        position: relative;
        @include media-breakpoint-up(lg) {
            position: absolute;
        }
        a::before {
            padding: 0;
        }
        img {
            width: 100%;
        }
    }
}

// Featured Product

.sale-of-the-day-offer {
    .grid {
        .product-element {
            max-width: 1040px;
            margin: auto;
        }
        .sale-item {
            position: relative;
            .item-picture {
                @include media-breakpoint-up(lg) {
                    padding-right: 45%;
                }
                a {
                    padding-right: $grid-gutter-width * 3;
                    img {
                        width: 100%
                    }
                }
            }
            .overview {
                position: relative;
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    right: 0;top: 0;
                    width: 380px;
                    text-align: left;
                }
                .product-name {
                    a {
                        color: black;
                        font-weight: bold;
                    }
                }
            }
            .short-description,
            .bottom-detail {
                padding: 0 0 $grid-gutter-width 0;
                border-radius: $border-radius;
            }
            .short-description {

            }
            .bottom-detail {
                .prices {
                    padding-bottom: $grid-gutter-width / 2;
                    border-bottom: 1px solid $border-color;
                }
 
                .actual-price {
                    font-size: 32px;
                    color: $brand-secondary;
                }
            }
        }
    }
}

.item-navigation-buttons {
    position: relative;
    margin: 0 30%;
    button {
        width: 50px;height: 50px;
        margin: 0 3px;
        background-color: white;
        border: none;
        position: absolute;
        transition: all 0.2s ease-in-out;
        border-radius: 50%;
        box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
        &:hover,
        &:focus,
        &:active {
            transform: translateY(-1px);
            box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
            color: #6b7c93;
            outline: none;
        }
    }
    .previous-button {
        left: -5px;
    }
    .next-button {
        right: -5px;
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 25px;
        width: 100%;height: 1px;
        background-color: $border-color;
    }
}

// Dirt Element

.dirt-element {
    width: 100%;
    padding: $grid-gutter-width * 4 0;
    background-image: url(/images/dirt-tran1.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
}

.html-home-page .dirt-element {
    background-color: #f8f8f8;
    background-image: url(/images/dirt.png);
}


// FoxNet Articles

.article-page {
    .article-box {
        flex: 0 0 100%;
        max-width: 100%;
        @media (min-width: 576px) {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 1rem;
        }
        @media (min-width: 768px) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            margin-bottom: 1rem;
        }
        .article-item {
            background-color: white;
            border-radius: 6px;
            overflow: hidden;
            img {
                width: 100%;
            }
            .details {
                padding: $grid-gutter-width * 2;
            }
        }
    }

}

// 4.2 Homapage Rich Blog

.l-homepage-blogs {
    background-color: rgba(0,0,0, 0.9);
    .block-title {
        color: white;
    }
    .view-all {
        text-align: right;
        a {
            color: $brand;
        }
    }
}

// Mosaic Blogs

.l-hompage-mosaic {
    .container {
        padding: 0;
        max-width: unset;
    }
    .slider-wrapper {
        &:hover {
            .nivo-main-image {
                transform: scale(1.2);
            }
        }

        a {
            position: relative;
            display: block;
            width: 100%;height: 100%;
            z-index: 9;
            &::before {
                content: "";
                width: 100%;height: 100%;
                display: block;
                position: absolute;
                background-color: rgba(0,0,0,0.5);
                top: 0;bottom: 0;left: 0;right: 0;
                z-index: 1;
            }
        }
    }
    .nivo-main-image {
        transition: all 1.0s;
        transform: scale(1);
    }
    .l-list__col {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    .l-list__col-1 {
        .slider-wrapper {
            @include media-breakpoint-up(lg) {
                height: 100%;
            }
        }
        .nivo-main-image {
            max-width: 100%;
            @include media-breakpoint-up(lg) {
                display: block !important;
                position: absolute !important;
                width: auto !important;
                height: 100%;
                max-width: fit-content;
            }
        }
        .nivo-caption {
            top: auto;bottom: 10%;left: 5%;
            background-color: transparent;
            text-transform: uppercase;
        }
    }
    .l-list__col-2 {
        .nivo-caption {
            background-color: transparent;
            text-transform: uppercase;
            top: 10%;bottom: auto;
        }
    }
    .nivo-caption {
        color: white;
        text-align: left;
        font-size: 1.6rem;
        width: auto;
        z-index: 99;
        .brand {
            color: $brand;
        }
    }
    .slider-wrapper {
        position: relative;
        overflow: hidden;
    }
}

// 4.2 Homapage Product Tabs

.nop-jcarousel {
    position: relative;
    .title .btn {
        position: absolute;
        top: 0;bottom: 0;
        max-width: 100%;max-height: 100%;
        margin: auto;
        transition: all 0.3s ease;
        width: 35px;height: 35px;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        border: none;
        font-size: 0;
        overflow: hidden;
        white-space: nowrap;
        &.slick-prev {
            left: 0;
            background-image: url(images/jcarousel-left.png);
        }
        &.slick-next {
            right: 0;
            background-image: url(images/jcarousel-right.png);
        }
    } 
}




