/*********** CONTACT & EMAIL A FRIEND & APPLY FOR VENDOR ACCOUNT ***********/

.email-a-friend-page .page-title,
.apply-vendor-page .page-title {
    margin: 0 0 -1px;
}

.contact-page .result,
.contact-page .message-error,
.email-a-friend-page .result,
.email-a-friend-page .message-error,
.apply-vendor-page .result,
.apply-vendor-page .message-error {
    margin: 0 0 20px;
}

.contact-page .topic-block,
.email-a-friend-page .title,
.apply-vendor-page .title {
    margin: 25px 0;
}

.email-a-friend-page .title h2 {
    font-weight: normal;
    color: #4ab2f1;
}

.apply-vendor-page .terms-of-service {
    text-align: center;
}

.apply-vendor-page .terms-of-service > * {
    display: inline-block;
    line-height: 32px;
}

.apply-vendor-page .terms-of-service span,
.apply-vendor-page .terms-of-service a {
    color: #4ab2f1;
    cursor: pointer;
}

.manufacturer-grid .title, .vendor-grid .title {
    padding: 0 0 15px;
}