$global-font-path: "/Themes/Bicikla/Content/fonts/";


// Site default font
$brand-font-family: "Roboto/";


/*
@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-SemiBoldItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-SemiBoldItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-ExtraLightItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraLightItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraBoldItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-BlackItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-BlackItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-MediumItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-MediumItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Black.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Black.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-ExtraBold.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraBold.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-BoldItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-BoldItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Regular.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Regular.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Medium.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Medium.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-SemiBold.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-SemiBold.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-ThinItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-ThinItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-ExtraLight.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraLight.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Light.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Light.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Bold.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Bold.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-LightItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-LightItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Italic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Italic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url($global-font-path + $brand-font-family + 'Poppins-Thin.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Poppins-Thin.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

*/

//
// Font ROBOTO
//

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Bold.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Bold.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Italic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Italic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Black.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Black.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Thin.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Thin.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-BoldItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-BoldItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Regular.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Regular.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Light.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Light.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-MediumItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-MediumItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-Medium.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-Medium.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-LightItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-LightItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-BlackItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-BlackItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url($global-font-path + $brand-font-family + 'Roboto-ThinItalic.woff2') format('woff2'),
        url($global-font-path + $brand-font-family + 'Roboto-ThinItalic.woff') format('woff'),
        url($global-font-path + $brand-font-family + 'Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}



//
// Font Awesome Pro
//

$brand-font-family2: "fontawesomepro/";

@font-face {
  font-family: "Font Awesome 5 Pro";
  src: url($global-font-path + $brand-font-family2 + 'fa-solid-900.woff2') format('woff2'),
      url($global-font-path + $brand-font-family2 + 'fa-solid-900.woff') format('woff'),
      url($global-font-path + $brand-font-family2 + 'fa-solid-900.ttf') format('truetype'),
      url($global-font-path + $brand-font-family2 + 'fa-solid-900.svg') format('svg'),
      url($global-font-path + $brand-font-family2 + 'fa-solid-900.eot') format('eot');

  src: url($global-font-path + $brand-font-family2 + 'fa-regular-400.woff2') format('woff2'),
      url($global-font-path + $brand-font-family2 + 'fa-regular-400.woff') format('woff'),
      url($global-font-path + $brand-font-family2 + 'fa-regular-400.ttf') format('truetype'),
      url($global-font-path + $brand-font-family2 + 'fa-regular-400.svg') format('svg'),
      url($global-font-path + $brand-font-family2 + 'fa-regular-400.eot') format('eot');

  src: url($global-font-path + $brand-font-family2 + 'fa-light-300.woff2') format('woff2'),
      url($global-font-path + $brand-font-family2 + 'fa-light-300.woff') format('woff'),
      url($global-font-path + $brand-font-family2 + 'fa-light-300.ttf') format('truetype'),
      url($global-font-path + $brand-font-family2 + 'fa-light-300.svg') format('svg'),
      url($global-font-path + $brand-font-family2 + 'fa-light-300.eot') format('eot');

}

.fal, .fad, .fa, .far, .fas  {
    font-family: "Font Awesome 5 Pro";
}
  
.fal {
    font-weight: 300;
}

.fab {
    font-family: Font Awesome\ 5 Brands;
}