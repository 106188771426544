/* Recently View Products */

.block {

    .c-product-item__picture,
    .c-product-item__details {
        float: left;
    }
    .c-product-item__picture {
        width: 25%;
        background-color: white;
        a {
            position: relative;
            display: block;
            padding: 0;
        }

    }

    .c-product-item__details {
        width: 75%;
        a {
            padding: 10px;
        }
    }
}
.block-recently-viewed-products {
    .list  {
        padding: 0 0 0 $grid-gutter-width;
       li {
            margin-bottom: 0.5rem;
            a {
                color: black;
                display: block;
            }
       } 
    }
    .item-box {
        background-color: white;
        margin-bottom: 0;
        border-radius: $border-radius;
        overflow: hidden;
        &:hover,
        &:active,
        &:focus {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        }
    }
}