
/********** WISHLIST & COMPARE LIST **********/

.html-shopping-cart-page,
.html-checkout-page,
.html-wishlist-page {
    .master-wrapper-content {
        padding: $grid-gutter-width * 6 0;
        @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width * 4 0;
        }
    }
}


.custom-control-label::after {
    top: 0.2625rem;
}

.wishlist-page .page-title {
    margin: 0 0 -1px;
}

.wishlist-content {
    margin: 0 0 20px;
}

.wishlist-content .message-error {
    margin: 20px 0;
}

.wishlist-content .product-content {
    min-width: 300px;
    text-align: left;
}

.wishlist-content .tax-shipping-info {
    margin: 0 0 20px;
    text-align: center;
}

.wishlist-content .tax-shipping-info a:hover {
    text-decoration: underline;
}

.shopping-cart-page .share-info {
    text-align: center;
}

.share-info {
    .share-label {
    }
}

.wishlist-page .share-info span {
    display: block;
    margin: 0 0 5px;
}

.wishlist-page .no-data {
    margin: 25px 0 0;
}

.compare-products-page {
    position: relative;
    overflow: hidden;
}


.compare-products-page .table-wrapper {
    margin: 0 0 30px;
    padding: 0 0 1px; /*IE mobile table border fix*/
}

.compare-products-table td {
    min-width: 150px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 20px;
    text-align: left;
    vertical-align: top;
    color: #444;
}

.compare-products-table td:first-child {
    min-width: 0;
}

.compare-products-table .picture {
    display: block;
    position: relative;
    max-width: 100%;
    height: 200px;
    overflow: hidden;
}

.compare-products-table .picture img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.compare-products-table .product-name td {
    font-weight: bold;
}

.compare-products-table .product-name td a:hover {
    color: #4ab2f1;
}

.compare-products-table .short-description td {
    line-height: 22px;
}

.compare-products-table .full-description td {
    display: none;
}

.compare-products-page .no-data {
    margin: 25px 0 0;
}

/********** SHOPPING CART **********/

.order-progress {
    margin: 0 0 50px;
    background-color: #f6f6f6;
    padding: 15px 0;
    text-align: center;
}

.order-progress ul {
    font-size: 0;
}

.order-progress li {
    display: inline-block;
    margin: 10px;
}

.order-progress a {
    display: block;
    min-width: 70px;
    font-size: 14px; /*reset zeroing*/
    color: #444;
}

.order-progress a:before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 15px;
    border: 1px solid #bbb;
    background: #fff url('../images/pointer.png') center no-repeat;
}

.order-progress li.active-step a {
    cursor: pointer;
}

.order-progress li.inactive-step a {
    opacity: 0.3;
    cursor: default;
}

.shopping-cart-page .message-error {
    margin: 20px 0;
}

.shopping-cart-page td .message-error {
    margin: 10px 0 0;
    text-align: left;
}

.shopping-cart-page .tax-shipping-info {
    margin: 20px 0;
    text-align: center;
}

.shopping-cart-page .tax-shipping-info a:hover {
    text-decoration: underline;
}

.shopping-cart-page .common-buttons{
    margin: 0 0 30px;
    padding: 20px 0;
    text-align: right;
    .btn {
        margin-left: 1rem;
    }

    @include media-breakpoint-up(lg) {
        text-align: right;
    }
}

.checkout-attributes ul {
    font-size: 0;
}

.checkout-attributes li {
    display: inline-block;
    margin: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 10px;
    font-size: 14px; /*reset zeroing*/
}

.checkout-attributes .attribute-squares li {
    border: none;
    padding: 0;
    line-height: 0;
}

.checkout-attributes li label {
    display: inline-block;
    margin: 0 0 0 5px;
}

.selected-checkout-attributes br {
    content: "";
    display: block;
    margin: 3px;
}

.cart-footer:after {
    content: "";
    display: block;
    clear: both;
}



.shopping-cart-page .total-info,
.checkout-page .total-info,
.details-page-cart .total-info  {
    .cart-total {
        tbody {
            tr {
                border-bottom: 1px solid $border-color;
                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
}

.cart-footer .total-info {
    margin-bottom: $grid-gutter-width * 1.5;
}


.cart-total td {
    width: 50%;
    padding: 5px 0;
    text-align: right;
}

.cart-total td:first-child {
    text-align: left;
}

.cart-total .giftcard-remaining {
    display: block;
    font-style: italic;
}

.cart-total .order-total {
    font-size: 20px;
    color: #DA251C;
}

.order-total {
    strong {
        color: #DA251C;
    }
}


.cart-total .order-total strong {
    font-weight: normal;
}

.cart-total .earn-reward-points {
    font-style: italic;
}

.min-amount-warning {
    margin: 0 0 20px;
    padding: 0 30px;
    color: #e4434b;
}

.terms-of-service {
    margin: 0 -5px $grid-gutter-width * 2;
    padding: 0 5px;
}


.cart-footer .addon-buttons {
    margin: 20px 0 0;
    padding: 0 10px;
}

.cart-collaterals {
    max-width: 100%;
    margin: 0 auto 50px;
    padding: 25px 0 0;
}

.cart-collaterals > div {
    margin: 0 0 50px;
}

.cart-collaterals .button-2 {
    display: inline-block;
    border: none;
    background-color: #aaa;
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
}

.cart-collaterals .button-2:hover {
    background-color: #999;
}

.cart-collaterals .title {
    margin: 0 0 5px;
    font-size: 18px;
    color: #444;
}

.cart-collaterals .hint {
    margin: 0 0 10px;
}

.cart-collaterals .deals > div {
    margin: 0 0 20px;
}

.coupon-box,
.giftcard-box {
    display: inline-block;
    width: 100%;
    .coupon-code {
        position: relative;
        input[type="submit"] {
            position: absolute;
            right: 2px;
            top: 2px;
        }
    }
}

.cart td.quantity input {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $border-color;
}

.terms-of-service label {
    cursor: pointer;
    text-transform: none;
    color: #585858;
    font-weight: normal;
}

.cart-collaterals .coupon-code input[type="text"] {
    float: left;
    width: 60%;
    height: 36px; /*safari padding fix*/
    margin: 0 -1px 0 0;
}

.cart-collaterals .coupon-code input[type="submit"] {
    float: left;
    width: 40%;
    height: 36px; /*safari padding fix*/
    padding: 0;
}

.cart-collaterals .message-failure {
    margin: 5px 0 0;
    font-size: 12px;
    color: #721c24;
    background-color: transparent;
    border-color: transparent;
}

.cart-collaterals .message-success {
    margin: 5px 0 0;
    font-size: 12px;
    color: #155724;
    background-color: transparent;
    border-color: transparent;
}

.cart-collaterals .current-code {
    margin: 5px 0 0;
    color: #004085;
    background-color: transparent;
    border-color: transparent;
}

.remove-discount-button,
.remove-gift-card-button {
    width: 16px;
    height: 16px;
    border: 1px solid #999;
    background: #fff url('../images/close.png') center no-repeat;
    cursor: pointer;
}

.cart-collaterals .shipping select {
    height: 36px;
}

.cart-collaterals .shipping-results {
    margin: 30px 0 0;
}

.cart-collaterals .shipping-results li {
    margin: 20px 0;
}

.cart-collaterals .shipping-results li strong {
    display: block;
    margin: 0 0 5px;
    color: #444;
}

.shopping-cart-page .no-data {
    margin: 25px 0 0;
}

.cart-footer {
    padding-top: $grid-gutter-width * 2;
    border-top: 1px solid $brand;
    .footer-item {
        .total-inner {
            padding: $grid-gutter-width;
            margin-bottom: $grid-gutter-width;
            border-radius: 6px;
            border: 1px solid $border-color;
        }
    }
}

.checkout-page .opc {
    .btn {
        margin: 0 0.2rem;
    }
}


.cart-footer {
    .title {
        margin-bottom: 5px;
    }
    .hint {
        padding-bottom: $grid-gutter-width;
    }
}
.total {
    .total-inner {
        padding: $grid-gutter-width;
        background-color: $white;
    }
}

@media all and (min-width: 769px) {

        /*** SHOPPING CART ***/

        .order-progress {
            padding: 20px;
        }
    
        .order-progress li {
            margin: 0 10px;
        }
    
        .order-progress a {
            min-width: 80px;
        }
    
        .cart-options .common-buttons {
            text-align: right;
        }
    
        .cart-footer .totals {
            float: right;
            width: 45%;
            margin: 0 2.5%;
        }
    
    
        .cart-footer .cart-collaterals {
            float: left;
            /*margin: 0 2.5%;*/
        }
    
        .cart-footer .cart-collaterals label {
            text-align: left;
        }
    
        /*** CHECKOUT ***/

        .checkout-page .selected-checkout-attributes {
            float: right;
            text-align: right;
        }
    
        .checkout-page .cart-footer .totals {
            max-width: 350px;
            margin: 0;
        }
    
        .checkout-page .address-grid {
            overflow: hidden;
        }
    
        .checkout-page .address-item {
            float: left;
            /*width: 48%;
            margin: 0 1% 40px;*/
        }
    
        .shipping-method .method-list li,
        .payment-method .method-list li {
            display: inline-block;
            vertical-align: top;
        }
    
        .payment-method .payment-logo {
            display: block;
            background-color: #f6f6f6;
            padding: 10px;
        }
    
        .payment-method .payment-details {
            display: block;
            width: auto;
            min-width: 0;
            margin: 0;
            text-align: center;
        }
    
        .payment-info .info {
            padding: 30px 75px;
        }
    
    /*** ORDER DETAILS & SHIPPING DETAILS ***/

    .order-details-page .section.options {
        text-align: right;
    }

    .order-details-page .selected-checkout-attributes {
        padding: 0 10px;
    }

    .order-details-page .tax-shipping-info {
        text-align: right;
    }

}

.checkout-attributes {
    margin-bottom: $grid-gutter-width * 2;
}

.table-bordered thead th, .table-bordered thead td {
    background-color: $gray-100;
}

.shop-note {
    position: relative;
    text-align: left;
    .html-widget {
        border: 1px dashed $border-color;
        padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width $grid-gutter-width * 3;
        border-radius: 6px;
    }
    &--icon {
        position: absolute;
        top: 0;left: $grid-gutter-width / 2;
        background-color: $brand-secondary;
        color: white;
        width: 30px;height: 30px;
        border-bottom-right-radius: 6px;border-top-left-radius: 6px;
        text-align: center;
        line-height: 2.1;
    }
    ul {
        li {
            margin-bottom: 0.5rem;
            &:last-child {
                margin-bottom: 0;
            }
            > span {
                font-weight: 600;
                margin-right: 0.3rem;
                text-transform: uppercase;
            }
        }
    }
}


@media all and (min-width: 992px) {

    /*** SHOPPING CART ***/

    .shopping-cart-page .cart-options {
        overflow: hidden;
    }

    .shopping-cart-page .checkout-attributes li {
        margin: 5px 10px 5px 0;
    }

    .shopping-cart-page .selected-checkout-attributes {
        float: right;
        max-width: 395px;
        margin: 15px 0;
    }

    /*** CHECKOUT ***/

    .order-progress {
        margin: -30px 0 50px;
    }
    /*.checkout-page .address-item {
	    width: 31.33333%;
    }*/
    .checkout-page .terms-of-service {
        text-align: center;
        clear: both;
    }

    .payment-info .info {
        padding: 30px 15px;
    }

    .payment-info .info td:first-child {
        width: 265px !important;
        margin: 0 10px 0 0;
        text-align: right;
    }

    .payment-info .info td:only-child {
        width: 100% !important;
    }

    .opc .order-review-data ul {
        text-align: left;
    }
}

@media all and (min-width: 1367px) {

    /*** SHOPPING CART ***/
    
    .cart-footer .totals {
        width: 395px;
        margin: 0;
    }

    .cart-footer .cart-collaterals {
        margin: 0;
    }

    .cart-footer .cart-collaterals .deals {
        float: left;
    }

    .cart-footer .cart-collaterals .shipping {
        float: right;
        width: 360px;
    }

    .cart-footer .estimate-shipping .inputs label {
        display: inline-block;
        width: 35%;
    }

    .cart-footer .estimate-shipping .inputs input,
    .cart-footer .estimate-shipping .inputs select {
        width: 65%;
    }


    /*** CHECKOUT ***/

    .shipping-method .method-list li,
    .payment-method .method-list li {
        margin: 0;
    }

    .payment-info .info td:first-child {
        width: 375px !important;
    }

    .payment-info .info td:only-child {
        width: 100% !important;
    }

}

@media all and (min-width: 769px) {

    /*** SHOPPING CART ***/

    .order-progress {
        padding: 20px;
    }

    .order-progress li {
        margin: 0 10px;
    }

    .order-progress a {
        min-width: 80px;
    }

    .cart-options .common-buttons {
        text-align: right;
    }

    .cart-footer .totals {
        float: right;
        width: 45%;
        margin: 0 2.5%;
    }

    .cart-footer .cart-collaterals {
        float: left;
        width: 45%;
        margin: 0 2.5%;
    }

    .cart-footer .cart-collaterals div,
    .cart-footer .cart-collaterals label {
        text-align: left;
    }

}

.checkout-buttons {
    button {
        width: 100%;
    }
}


.compare-products-page {
    .compare-products-table {
        border-radius: $border-radius;
    }
}


@media all and (min-width: 992px) {

    /*** WISHLIST & COMPARE LIST ***/

    .compare-products-page .clear-list {
        position: absolute;
        top: 40px;
        right: 4%;
    }

    .compare-products-table .full-description td {
        display: table-cell;
    }

    .compare-products-table .full-description td p {
        margin-top: 0;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .giftcard label {
        text-align: right;
    }
    .forum-edit-page strong, .private-message-send-page .customer-name {
        text-align: left;
    }
}

@media (min-width:992px) and (max-width:1367px) {
    .cart-footer .cart-collaterals .deals {
        float:right;
    }
}



@media all and (min-width: 1367px) {

    /*** SHOPPING CART ***/

    .cart-footer .totals {
        width: 395px;
        margin: 0;
    }

    .cart-footer .cart-collaterals {
        width: 760px;
        margin: 0;
    }

    .cart-footer .cart-collaterals .deals {
        float: left;
        width: 360px;
    }

    .cart-footer .cart-collaterals .shipping {
        float: right;
        width: 360px;
    }

    .cart-footer .estimate-shipping .inputs label {
        display: inline-block;
        width: 35%;
    }

    .cart-footer .estimate-shipping .inputs input,
    .cart-footer .estimate-shipping .inputs select {
        width: 65%;
    }
}


@media (min-width: 1367px) {
    .cart-footer .cart-collaterals .shipping {
        width: inherit !important;
    }
}


@media (max-width:480px) {

    /* Root Theme CSS */
    
    .forum-edit-page .buttons input, .move-topic-page .buttons input, .private-messages .buttons input {
        margin-bottom: 10px;
    }
    .payment-method .payment-details {
        text-align: center !important;
        display: block !important;
    }
    .cart-collaterals .coupon-code input[type="text"] {
        width:100% !important;
    }
    .cart-collaterals .coupon-code input[type="submit"] {
        clear: both;
        float: none !important;
        margin-top: 5px;
        width: auto !important;
        padding: 0 15px 0 15px !important;
    }
}


@media (max-width: 1367px) {
    .cart-footer .cart-collaterals .deals {
        padding-left:0 !important
    }
}

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .shopping-cart-page .checkout-attributes {
        float: none !important;
    }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .shopping-cart-page .checkout-attributes {
        float: none !important;
    }
}

#payment-info-buttons-container, #confirm-order-buttons-container {
    clear: both;
}

#opc-confirm_order {
    clear: both;
}

.cart-collaterals > div {
    margin: 0 0 25px !important;
}

.cart td,
.data-table td,
.forum-table td {
  min-width: 20px;
}

.cart-header-row {
    > th {
        font-size: 13px;
        text-transform: uppercase;
    }
}

.cart label {
    font-size: 0;
    margin-right: 0rem;
}

.cart .remove-from-cart,
.cart .add-to-cart,
.data-table .select-boxes,
.data-table .order {
    text-align: center;

}

.cart td, 
.data-table td, 
.forum-table td {
    vertical-align: middle;
    text-align: center;
}

.cart td {
    &.child {
        text-align: left;
        .custom-checkbox {
            display: inline-block;
        }
    }
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;left: -1.5rem;
    display: block;
    width: 1rem;height: 1rem;
    pointer-events: none;
    content: "";
    background-color: white;
    border: 1px solid $border-color;
}

.accept-label {
    margin-left: $grid-gutter-width;
    display: inline-block;
}

.cart-options {
    .checkout-attributes {
        width: 100%;
        margin-bottom: $grid-gutter-width*2;
    }
}

.cart-footer {
    .estimate-shipping {
        label {
            @include media-breakpoint-up(lg) {
                margin-bottom: $grid-gutter-width;
            }
        }
    }
    .footer-item {
        margin-bottom: $grid-gutter-width;
    }
    .buttons {
        text-align: center;
        margin-bottom: 10px;
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
    .alert {
        margin-top: 3.5rem;
    }
}

.shipping-options {
    .select-box {
        float: none;
        padding: 0;
        @include media-breakpoint-up(lg) {
            float: right;
        }
    }
}




/* Data Table Css by S.K */

.table th, .table td {
    text-align: center;
}

.child {
    .custom-control {
        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            left: 3px;
            top: 3px;
            opacity: 0;
            z-index: 9;
        }
    }
}


#shopping-cart,
#wishlist {
    tbody {
        .product-picture {
            padding: 5px;
            @include media-breakpoint-up(md) {
                padding: 0;
            }
            @include media-breakpoint-up(lg) {
                padding: 5px;
            }
            img {
                width: 70%;
                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
            }
        }
    }
}

#wishlist {
    tbody {
        .product-picture {
            padding: 5px;
            @include media-breakpoint-up(md) {
                padding: 5;
            }
        }   
    }
}

.action-cell {
    display: none;
    @include media-breakpoint-down(lg) {
        display: table-cell;
    }
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child, 
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
    padding-left: 0;
}

.page .custom-control-label::after {
    top: 0.1rem;
}

.shipping-method .custom-control {
    line-height: 19px;
}

.custom-control-label {
    &::before,
    &::after {
        left: -1.2rem;
    }
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, 
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 14px;
    width: 14px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 14px;
    content: '+';
    background-color: $gray-700;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before, 
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
    content: '-';
    background-color: $brand;
    color: white;
}