@import "../../node_modules/bootstrap/scss/_functions"; // added by Sistemi.hr - prevent need of importing the functions file in every file that imports variables.scss
// @import "../../node_modules/bootstrap/scss/_variables"; // added by Sistemi.hr - prevent need of importing the functions file in every file that imports variables.scss

// Variables overrides

//
// Color system
//
// stylelint-disable
$white:    #fff;
$gray-100: #f8f8f8;
$gray-200: #ebebeb;
$gray-300: #6d6f7b; 
$gray-400: #323648;
$gray-500: #EEEEEE; // Svijetlo Sivi Text
$gray-600: #888888; // Navigation Siva
$gray-700: #585858; // Text siva
$gray-800: #343434;
$gray-900: #323648;
$black:    #000;

$brand: #FFC600;
$brand-secondary: #a92020;
$brand-gradient: linear-gradient(90deg, rgba(255,220,100,1) 27%, rgba(255,198,0,1) 68%);


$highlight:#725dad;
$colors: (
  "brand":      $brand,
  "highlight":  $highlight
);

$primary:       $brand;
$secondary:     $gray-600;
$light:         $gray-200;
$info:          $highlight;
$theme-colors:(
  "primary":    $primary,
  "secondary":  $secondary,
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-300;
$body-color:                $gray-900;
$body-color-light:          $gray-100;

// Links
//
// Style anchor elements.

$link-color:                theme-color("primary");
$link-decoration:           none;
$link-hover-color:          darken($link-color, 33%);
$link-hover-decoration:     underline;
$border-color: #dddddd;
$border-radius: 6px;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

// Our brand and project font-sizes and line-heights are held in maps.
// Access these using `@include font()` from `utilities/typography`.
$text: (
  heading-alpha: (
    small: (
      font-size: 22px,
      line-height: 1
    ),
    large: (
      font-size: 34px,
      line-height: 1
    )
  ),
  heading-bravo: (
    small: (
      font-size: 18px,
      line-height: 1
    ),
    large: (
      font-size: 24px,
      line-height: 1
    )
  ),
  heading-charlie: (
    small: (
      font-size: 16px,
      line-height: 1
    ),
    large: (
      font-size: 20px,
      line-height: 1
    )
  ),
  heading-delta: (
    small: (
      font-size: 15px,
      line-height: 1
    ),
    large: (
      font-size: 15px,
      line-height: 1
    )
  ),
  heading-echo: (
    small: (
      font-size: 14px,
      line-height: 1
    ),
    large: (
      font-size: 16px,
      line-height: 1
    )
  ),
  text-lead: (
    small: (
      font-size: 16px,
      line-height: 1
    ),
    large: (
      font-size: 17px,
      line-height: 1
    )
  ),
  text-body: (
    small: (
      font-size: 14px,
      line-height: 1
    ),
    large: (
      font-size: 14px,
      line-height: 1
    )
  ),
  text-smallprint: (
    small: (
      font-size: 11px,
      line-height: 1
    ),
    large: (
      font-size: 12px,
      line-height: 1
    )
  )
) !default;


$font-size-base:              font-size-rem(text-body,large);
$font-size-lg:                font-size-rem(text-lead,large);
$font-size-sm:                font-size-rem(text-smallprint,large);

$h1-font-size:                font-size-rem(heading-alpha,large);
$h2-font-size:                font-size-rem(heading-bravo,large);
$h3-font-size:                font-size-rem(heading-charlie,large);
$h4-font-size:                font-size-rem(heading-delta,large);
$h5-font-size:                font-size-rem(heading-echo,large);
$h6-font-size:                $font-size-base;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$headings-font-family:        inherit;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              inherit;

$display1-size:               font-size-rem(heading-alpha,large);
$display2-size:               font-size-rem(heading-bravo,large);
$display3-size:               font-size-rem(heading-charlie,large);
$display4-size:               font-size-rem(heading-delta,large);

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              font-size-rem(text-lead,large);
$lead-font-weight:            300;

$small-font-size:             80%;

$text-muted:                  $gray-600;

$grid-gutter-width:           16px !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
), $spacers);
