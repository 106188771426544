/****** SEARCH & SITEMAP ******/

.search-page .page-title {
    margin: 0 0 -1px;
}

.search-input .fieldset {
    margin: 0 0 30px;
}

.search-input .form-fields {
    padding: 30px 15px 10px;
}

.search-input input + label {
    display: inline;
    margin: 0 0 0 5px;
}

.advanced-search {
    margin: 30px 0 0;
}

.advanced-search .price-range {
    display: inline-block;
}

.advanced-search .price-range input {
    display: inline-block;
    width: 80px;
    margin: 3px;

}

.search-input .buttons {
    text-align: center;
}

.page .custom-control-label::before {
    top: 0.1rem;
}
.page .custom-control-label::after {
    top: 0.15rem;
}

.search-input .button-1 {
    min-width: 140px;
    border: none;
    background-color: #DA251C;
    padding: 10px 30px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
}

.search-input .button-1:hover {
    background-color: #8a8a8a;
}

.search-results {
    margin: 30px 0 0;
}

.search-results .warning,
.search-results .no-result {
    margin: 10px 0;
    color: #721c24;
}

.sitemap-page .description {
    margin: 0 0 30px;
    text-align: center;
}

.sitemap-page .entity {
    margin: 0 0 30px;
}

.sitemap-page .entity-title {
    margin: 0 0 15px;
    padding: 0 10px;
    text-align: center;
}

.sitemap-page .entity-title h2 {
    font-weight: normal;
    color: #444;
}

.sitemap-page .entity-body {
    border-top: 1px solid #e6e6e6;
    background-color: #f9f9f9;
    padding: 30px 15px;
    text-align: center;
}

.sitemap-page ul {
    font-size: 0;
}

.sitemap-page li {
    display: inline-block;
    position: relative;
    margin: 0 8px;
    padding: 0 12px;
    line-height: 50px;
}

.sitemap-page li:before {
    content: "";
    position: absolute;
    top: 23px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #ddd;
}

.sitemap-page a {
    color: #444;
}

.sitemap-page a:hover {
    color: #4ab2f1;
}

.sitemap-page a:empty {
    display: none;
}

/*** About Us Page CSS ***/


.topic-page {
    h2 {
        background-color: $gray-100;
        padding: $grid-gutter-width / 2;
    }
    label {
        display: inline-block;
        margin: 0;
        margin-right: 0.5rem;
    }
}

ul.workers {
    list-style-type: none;
    margin-right: -8px;margin-left: -8px;
    .workers-item {
        background-color: $gray-100;
        padding: $grid-gutter-width;
        text-align: center;
        border-radius: 6px;
        .name,
        .position {
            display: block;
            padding-top: 4px;
        }
        .name {
            font-weight: bold;
        }
    }
}

/*** Service Page Tables CSS ***/

.service-price-table {
    .table th, .table td {
        text-align: left;
    }
    .table th {
        width: 20%;
    }
    .table {
        td {
            &:nth-child(2) {
                width: 70%;
            }

        }
    }
}

// Upper Contact Features

.contact-us-feature {
    margin: 0 0 $grid-gutter-width*2 0;
    padding: $grid-gutter-width;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    p {
        margin: 0;
    }

    .contact-phone {
        visibility: hidden;
        font-size: 0;
        line-height: normal;
    }
}

// Contact Form

.contact-page {
    .form-fields {
        position: relative;
        @include media-breakpoint-up(lg) {
        }
    }
    form {
        .form-group {
            .req {
                display: inline-block;
            }
        }
        .col-form-input {
            input,
            textarea {
                height: 50px;
            }
        }
    }
    .map-holder {
        border: 1px solid $border-color;
        border-radius: $border-radius;
    }
    .buttons {
        text-align: right;
    }

}

.fieldset, .section {
    position: relative;
}

.col-form-label {
    display: inline-block;
    line-height: 1 !important;
}

.map-holder {
    height: 520px;
}

.captcha-box {
    margin: $grid-gutter-width 0;
    @include media-breakpoint-up(lg) {
        text-align: right;
    }
}

.ribbon-position.top-left {
    top: 0;
    left: 0;
}