/* ==========================================================================
   COMPONENTS / #TYPOGRAPHY
   ========================================================================== */

/**
 * In order to divorce our semantic decisions from our stylistic ones, we only
 * define opinionated typographical styles against classes, NOT against
 * typographic HTML elements.
 *
 * https://csswizardry.com/2016/02/managing-typography-on-large-apps/
 *
 * Using the properties defined in toolkit-core/settings/typography,
 * the loop below will generate us a suite of mobile-first responsive
 * typographical component classes:
 *
 *   Heading-level Typography
 *   ---------------------------------
 *   .o-heading-alpha
 *   .o-heading-bravo
 *   .o-heading-charlie
 *   .o-heading-delta
 *
 *   Text-level Typography
 *   ---------------------------------
 *   .o-text-lead
 *   .o-text-body
 *   .o-text-smallprint
 */

// Separate out the properties to get the font size name.
@each $name, $properties in $text {
    // Output the font size name with component prefix.
    .o-#{$name} {
        @include font($name, small);
        @include media-breakpoint-up(lg) {
        @include font($name, large);
        }
    }
}

a, a:hover, a:focus {
    text-decoration: none !important;
 }
 
 .o-title 
 { 
    &--underlined {
       @extend .pb-2;
       @extend .mb-2;
       border-bottom: 2px solid color("brand");
    }
 }
 .o-title {
    &--alpha,   h1 & { 
      
        @include media-breakpoint-up(sm) {
        @include font(heading-alpha, large);
        }
     }
    &--bravo,   h2 & { @include font(heading-bravo, large);}
    &--charlie, h3 & { @include font(heading-charlie, large);}
    &--delta,   h4 & { @include font(heading-delta, large);}
    &--echo,    h5 & { @include font(heading-echo, large);}
 }
 .o-text {
    &--body {  @include font(text-body, large); }
    &--lead {  @include font(text-lead, large); }
    &--smallprint {  @include font(text-smallprint, large); }
 }

 //
 // za deklaraciju heading unutar 2sxc komponenti
 .co-content 
 {
    h1, h2, h3, h4, h5, h6 {
       color: color("brand");
    }
    h1 { @include font(heading-alpha, large);}
    h2 { @include font(heading-bravo, large);}
    h3 { @include font(heading-charlie, large);}
    h4 { @include font(heading-delta, large);}
    h5 { @include font(heading-echo, large);}
 }

.o-font-weight-light{
   font-weight: 300;
}

.o-font-weight-regular{
   font-weight: normal;
}

.o-font-weight-semibold{
   font-weight: 600;
}

.o-font-weight-bold{
   font-weight: 700;
}

.o-font-weight-extrabold{
   font-weight: 800;
}

.o-text-color__white{
   color: white;
}
.o-text-color__dark {
   color: $gray-800;
}
.o-text-color__primary {
   color:$primary;
}
.o-text-color__secondary {
   color: $secondary;
}