.c-header-menu {
    display: flex;
    flex-direction: column;
    justify-content: center; 
}

.mm-menu-header {
    background-color: $brand;
    color: white;
    text-align: right;
    a {
        display: inline-block;
        padding: 2px 12px;
    }
}


.menu-title {
    display: none;
    // width: 100%;
    // margin: 0 auto $grid-gutter-width * 2;
    // max-width: 100%;
    // background: $brand url(images/menu-icon.png) right center no-repeat;
    // padding: $grid-gutter-width;
    // font-size: 15px;
    // font-weight: bold;
    // color: white;
    // text-transform: uppercase;
    // cursor: pointer;
    // text-shadow: none;
}

.mega-menu.main-menu {
        position: relative;
        display: block;
        z-index: 5;
        border: none;
        margin: 0;
        @include media-breakpoint-up(xl) {
            padding-left: 74px;
        }
        .is-home {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            @include media-breakpoint-up(xl) {
                display: block;
            }
            a {
                display: block;
                padding: $grid-gutter-width;
                &::before {
                    content: "\f80a";
                    display: inline-block;
                    font-family: "Font Awesome 5 Pro";
                    margin-top: 0px;
                    font-size: 22px;
                }
            }
        }
    }

    // Mobile Menu

    .menu-title {
        width: 100%;
        margin: 0 auto 40px;
        max-width: 100%;
        background: $brand url(images/menu-icon.png) right center no-repeat;
        padding: 15px;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
        
    }

    // Desktop Menu

    .mega-menu {
        text-align: center;
        .row-wrapper {
            padding: $grid-gutter-width /2 $grid-gutter-width;
        }
        .box {
            position: relative;
            padding-top: $grid-gutter-width;
            padding-bottom: $grid-gutter-width;
            .picture-title-wrap {
                position: relative;
                margin: 0 0 5px;
            }
        }
        &.mega-menu::after {
            display: block;
            clear: both;
            content: "";
            //content:unset;
        }
        li {
            display: inline-block;
            @include media-breakpoint-up(xl) {
                float: none;
            }
            &:first-child {
                > span,
                > a {
                    &::before {
                        display: none;
                    }
                }
            }
            > span,
            > a {
                @include media-breakpoint-up(xl) { 
                    padding: $grid-gutter-width;
                }
                position: relative;
                display: block;
                color: white;
                padding: $grid-gutter-width;
                font-size: 15px;
                text-transform: uppercase;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
                &:hover {
                    color: $brand;
                    cursor: pointer;
                }
            }
        }
        .fullWidth {
            &.categories {
                .box {
                    .title {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: 0;
                        background: rgba(255,255,255,.85);
                        z-index: 1;
                    }
                }
            }
        }
    }
    
    .dropdown {
        display: none;
        position: absolute;
        text-align: left;
        background: white;
        box-shadow: 0 1px 2px rgba(0,0,0,0.2);
        border-top: 1px solid $gray-400;
        z-index: 1;
        &.fullWidth {
            left: 0;
            width: 100%;
        }
        &.active {
            display: block;
        }
    }

@include media-breakpoint-down (md) {

    .mega-menu-responsive {
        display: none;
        width: 100%;
        max-width: 100%;
        margin: -$grid-gutter-width * 2 auto $grid-gutter-width * 2;
        background-color: transparent !important;
        > li {
            position: relative;
            margin: 1px 0;
            background-color: $gray-100;
            > a,
            > span {
                display: block;
                padding: 18px;
                font-size: 15px;
            }
        }
    }

}

@include media-breakpoint-down (lg) {

    .mobile-menu-icon span {
        display: block;
        //background: url(../images/menu-button.png) no-repeat;
        background-position: center center;
        //float:right;
        cursor: pointer;
        font-size: 31px;
        color: $brand;
        position: relative;
        //padding-left: 10px;
        //margin-right: 10px;
    }
    .closebtn {
        display: block;
        width: 60px;
        height: 38px;
        background: url(../images/close-button.png) no-repeat;
        background-position: center center;
        float: right;
        filter: invert(100%);
    }
    .header-menu {
        margin: 0 auto !important;
    }

}

.mm-panels .mm-listitem:after {
    content: '';
    border-color: inherit;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: block;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
}

.top-menu .mm-listitem__btn:not(.mm-listitem__text) {
    border-left-width: 0;
}

.top-menu > li > a {
    display: block;
    padding: 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
}

.top-menu .mm-navbar__title {
    font-size: 15px;
    text-transform: uppercase;
    color: black;
    font-weight: 600;
}


// nop4You Theme

.wsmenu {
    text-align: center;
    ul {
        display: inline-block;
        a {
            text-transform: uppercase;
            font-size: 16px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
    .megamenu {
        width: 100%;
        max-width: 1140px;
        left: 0; right: 0;
        margin: auto !important;
        position: absolute;
        top: 68px;
        z-index: 1000;
        margin: 0px;
        text-align: left;
        padding: $grid-gutter-width;
        font-size: 15px;
        background: white;
        opacity: 0;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        -o-transition: -o-transform 0.3s, opacity 0.3s;
        -ms-transition: -ms-transform 0.3s, opacity 0.3s;
        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025);
        border-radius: 0px 0px 6px 6px;
        &::before {
            content: "";
            display: block;
            top: -30px;right: 0;
            width: 100%;
            padding: 16px;
            position: absolute;
        }
        .megacolimage {
            width: 25%;
            float: left;
            margin: 0;
            > li {
                a {                   
                    display: block;
                    padding: $grid-gutter-width / 4 0px;
                    color: black;
                    text-transform: capitalize;
                    font-size: 16;
                    text-shadow: none;
                }
            }
        }
    }
}

.megacolimage-inner {
    position: relative;
    padding: $grid-gutter-width / 2;
    &--catimage {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        img {
            position: relative;
            display: block;
            width: 100%;
            height: 90px;
        }
        .title {
            position: absolute;
            top: 0;
            margin: 0;
            font-size: 13px;
            font-weight: 600;
            background-color: $brand;
            padding: 3px 8px;
            text-shadow: none;
            color: black;
            z-index: 2;
            border-bottom-right-radius: 5px;
        }
        &:hover {
            &::before {
                background-color: transparent;
                background-color: rgba(0, 0, 0, 0.5);
                content: "";
                display: block;
                position: absolute;
                width: 100%;height: 100%;
                z-index: 1;
            }
        }
    }
}

.megamenu-cat {
    position: relative;
    margin: -$grid-gutter-width;
    padding: $grid-gutter-width * 1.5;
    background: $gray-200;
    margin-bottom: $grid-gutter-width;
    overflow: hidden;
    &--title {
        font-size: 74px;
        position: absolute;
        top: -40px;left: -24px;
        opacity: 0.3;
    }
}