/********** REGISTRATION, LOGIN, ACCOUNT PAGES  **********/

.html-account-page {
    .block-category-navigation {
        display: none;
    }
}

.gender {
    display: inline-block;
}

.gender span {
    display: inline-block;
    margin: 0 5px;
}

.gender span > * {
    display: inline;
    margin: 0 5px;
    line-height: 32px;
}

.date-of-birth select + select {
    margin: 0 0 0 10px;
}

#check-availability-button {
    margin: 10px 0 0;
    border: none;
    background-color: #888;
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.html-account-page {
    .block-category-navigation {
        margin: 0;
        .listbox,
        .title {
            display: none;
        }
    }
}


#check-availability-button:hover {
    background-color: #999;
}

.accept-privacy-policy {
    margin: 0 0 20px;
}

.accept-privacy-policy > * {
    display: inline-block;
    line-height: 32px;
}

.accept-privacy-policy span,
.accept-privacy-policy a {
    color: #DA251C;
    cursor: pointer;
}

.return-request-page h1 a {
    color: #DA251C;
}

.return-request-page .return-request-file {
    font-size: 14px;
}

.return-request-page .return-request-file .uploadedfileinput {
    display: inline-block;
}

.registration-page .message-error,
.login-page .message-error,
.account-page .message-error {
    margin: 0 0 10px;
}

.registration-result-page .result {
    margin: 0 0 $grid-gutter-width;
    padding: 30px 0;
    text-align: center;
    font-size: 16px;
}

.customer-info-page .email-to-revalidate,
.customer-info-page .readonly-username,
.customer-info-page .vat-status {
    margin: 0 5px;
    font-size: 14px;
}

.customer-info-page .vat-status {
    display: block;
}

.registration-page .vat-note,
.customer-info-page .vat-note,
.customer-info-page .email-to-revalidate-note {
    display: block;
    font-size: 14px;
    margin-top: 7px;
    white-space: normal;
}

.login-page .new-wrapper .topic-block {
    margin: 0;
}

.login-page .new-wrapper .button-1 {
    margin: 0 0 5px;
}

.login-page .returning-wrapper {
    margin: $grid-gutter-width *2 0;
    @include media-breakpoint-up(lg) {
        margin: 0;
    }
}

.new-wrapper-inner {
    background-color: white;
    padding: $grid-gutter-width * 2;
    border-radius: 6px;
    .title {
        border-bottom: 1px solid $border-color;
    }
    label {
        text-align: left !important;
        padding-left: 0;
    }
    .login-actions {
        display: flex;
        justify-content: space-between;
        .custom-checkbox {
            padding-left: 0;
            label {
                font-weight: normal;
                text-transform: none;
                color: $gray-600;
            }
        }
    }
}


.login-page .returning-wrapper .inputs.reversed {
    white-space: normal;
}

.login-page .returning-wrapper .inputs.reversed * {
    display: inline-block;
    width: auto;
    margin: 5px;
    font-size: 14px; /*reset zeroing*/
    color: #444;
}

.login-page .returning-wrapper .inputs.reversed a:hover {
    color: #4ab2f1;
}

.login-page .captcha-box {
    margin: -15px 0 20px;
}

.external-authentication {
    margin: 0 0 50px;
    overflow: hidden;
    text-align: center;
}

.external-authentication .title {
    margin: 0 0 15px;
    font-size: 20px;
    color: #444;
}

.external-authentication .buttons {
    margin: 0 0 30px;
    border-top: 1px solid #e6e6e6;
    padding: 30px;
}

.external-auth-errors {
    color: #e4444c;
}

.external-auth-association {
    color: #444;
}

.external-auth-association a {
    color: #4ab2f1;
}

.external-auth-association a:hover {
    text-decoration: underline;
}

.password-recovery-page .tooltip-reset {
    margin-bottom: $grid-gutter-width;
}

.add-more-external-records {
    text-align: center;
}

.add-more-external-records a {
    color: #4ab2f1;
}

.add-more-external-records a:hover {
    text-decoration: underline;
}

.order-item-top .buttons {
    position: absolute;
    right: 0;top: 5px;
}

.account-page,
.registration-page,
.email-a-friend-page {
    .buttons {
        text-align: right;
    }
} 

.address-item-top {
    .buttons {
        position: absolute;
        right: 22px;top: 5px;
    }
    .edit-address-button {
        border: 0;
        background-color: transparent;
    }
}

.account-page .description {
    margin: 0 0 25px;
    padding: 0 10px;
}

.account-page .table-wrapper {
    margin: 0 0 30px
}


.gdpr-tools-page .result {
    margin: 20px 0;
    font-size: 13px;
    color: $brand;
}

.gdpr-tools-page .buttons {
    margin-top: $grid-gutter-width;
}

.address-list-page,
.order-list-page {
    .info {
        background-color: $gray-100;
        padding: $grid-gutter-width;
        border-radius: 6px;
        li {
            padding-bottom: 0.5rem;
        }
        .name {
            margin-bottom: 0.5rem;
            border-bottom: 1px solid $border-color;
            font-weight: bold;
        }
    }
}

.checkout-page .address-item ul, 
.address-list-page .info, 
.order-list-page .info {
    word-wrap: break-word;
}

.downloadable-products,
.back-in-stock-subscriptions,
.reward-points,
.customer-reviews {
    display: none !important;
}


.address-list-page .button-2,
.order-list-page .button-2 {
    display: inline-block;
    margin: 0 0 0 10px;
    border: none;
    padding: 0 0 0 18px;
    font-size: 12px;
    color: #444;
    box-shadow: none;
    &:hover {
        box-shadow: none;
    }
}


.retry-recurring-order-button {
    background: url('../images/retry.png') left center no-repeat;
}

.return-items-button {
    background: url('../images/return.png') left center no-repeat;
}

.order-details-button {
    background: url('../images/details.png') left center no-repeat;
}

.address-list-page .button-2:hover,
.order-list-page .button-2:hover {
    color: $brand;
}

.address-edit-page .message-error {
    margin: 20px 0;
}

.recurring-payments table td {
    white-space: nowrap;
}

.recurring-payments table td:first-child {
    min-width: 120px;
    white-space: normal;
}

.recurring-payments .button-2 {
    font-size: 14px;
}

.return-request-list-page .details {
    border-top: 1px solid #e6e6e6;
    background-color: #f9f9f9;
    padding: 20px;
    line-height: 22px;
    color: #444;
}

.return-request-list-page label {
    margin: 0 5px 0 0;
}

.return-request-list-page a {
    color: #4ab2f1;
}

.return-request-list-page a:hover {
    text-decoration: underline;
}

.return-request-list-page .comments div {
    margin: 10px 0 0;
    font-size: 15px;
    font-style: italic;
    color: #444;
}

.downloadable-products-page table td {
    color: #444;
}

.reward-points-overview {
    margin: 0 0 25px;
}

.reward-points-history table td {
    color: #444;
}

.reward-points-history table td:first-child {
    min-width: 120px;
}

.change-password-page .result,
.change-password-page .message-error {
    margin: 20px 0;
}

.change-password-page .fieldset {
    margin: 0 0 30px;
}

.avatar-page .page-title {
    margin: 0;
}

.avatar-page .page-body {
    margin: 0 0 30px;
    overflow: hidden;
    background-color: #f9f9f9;
    padding: 30px 25px;
}

.avatar-page .message-error {
    margin: 0 0 10px;
}

.avatar-page .image {
    margin: 0 0 10px;
}

.avatar-page .image img {
    border: 1px solid #ccc;
}

.avatar-page .button-1 {
    font-size: 14px;
}

.avatar-page .button-2 {
    border: none;
    background-color: #888;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.avatar-page .button-2:hover {
    background-color: #999;
}

.avatar-page .info {
    color: #444;
}

.vendorinfo-page .button-2 {
    border: medium none;
    background-color: #888;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.vendorinfo-page .button-2:hover {
    background-color: #999;
}

.vendorinfo-page .vendor-picture {
    width: 273px;
}

.vendorinfo-page .vendor-picture * {
    display: block;
    margin-top: 15px;
}


.check-gift-card-balance-page .page-title {
    margin: 0 0 -1px;
}

.check-gift-card-balance-page .result,
.check-gift-card-balance-page .message-error {
    margin: 20px 0;
}

.check-gift-card-balance-page .fieldset {
    margin: 0 0 30px;
}

@include media-breakpoint-up(lg) {

    /*** REGISTRATION, LOGIN, ACCOUNT ***/

    .gender span {
        margin: 0 10px 0 0;
    }
    .custom-attributes ul.option-list li,
    .vendor-attributes ul.option-list li {
        margin: 0 10px 0 0;
    }
    .gender span > * {
        margin: 0 5px 0 0;
    }

    .date-of-birth select + select {
        margin: 0 0 0 11px;
    }

    #check-availability-button {
        display: block;
        margin: 10px auto;
    }

}

@media all and (min-width: 992px) {

    .avatar-page .message-error,
    .avatar-page .buttons {
        text-align: left;
    }

    .registration-page .vat-note {
        margin-left: 275px;
    }

    .customer-info-page .vat-note,
    .customer-info-page .email-to-revalidate-note {
        margin-left: 150px;
    }

    .customer-info-page .vat-status {
        display: inline-block;
    }
}

@media all and (min-width: 1001px) {

    /*** REGISTRATION, LOGIN, ACCOUNT ***/

    .gender span {
        margin: 0 10px 0 0;
    }

    .gender span > * {
        margin: 0 5px 0 0;
    }

    .date-of-birth select + select {
        margin: 0 0 0 11px;
    }

    #check-availability-button {
        display: block;
        margin: 10px auto;
    }

    .login-page .returning-wrapper .inputs label {
        width: 110px;
    }

    .avatar-page .message-error,
    .avatar-page .buttons {
        text-align: left;
    }

    .registration-page .vat-note {
        margin-left: 275px;
    }

    .customer-info-page .vat-note,
    .customer-info-page .email-to-revalidate-note {
        margin-left: 150px;
    }

    .customer-info-page .vat-status {
        display: inline-block;
    }
}

.login-page .validation-summary-errors {
    margin-top: 0 !important;
    margin: 0 0 0 0;
    width: 100%;
}


//*** Customer Pages. ***/

.registration-page,
.account-page,
.password-recovery-page {
    .fieldset {
        .title {
            text-align: left;
            border-bottom: 1px solid $border-color;
        }
    }
}

label {
    text-transform: uppercase;
    color: black;
    font-weight: bold;
}


// Customer Account Navigation

.address-item,
.order-item {
    margin-bottom: $grid-gutter-width;
    ul.info {
        label {
            margin: 0;
            padding-right: 5px;
        }
    }
    button {
        padding: $grid-gutter-width / 2 $grid-gutter-width;
        &.delete-address-button {
            width: 35px;height: 35px;
            padding: 6px;
            background-color: red;
            color: white;
            border-radius: 50% !important;
        }
    }

}

.address-list-page .add-button {
    text-align: center;
}

/****** ORDER DETAILS & SHIPPING DETAILS ******/

.order-details-area {
    .billing-info-wrap,
    .shipping-info-wrap {
        > div {
            padding: $grid-gutter-width;
            background-color: $gray-100;
            border-radius: 6px;
        }
    }
}

.order-details-page {
    .order-overview,
    .order-details-area {
        margin-bottom: $grid-gutter-width * 2;
        .billing-info-wrap {
            margin-bottom: $grid-gutter-width;
        }
    }
    .products {
        .title {
            margin-bottom: $grid-gutter-width;
        }
    }
}

.details-page-cart {
    display: inline-block;
    width: 100%;
    margin-top: $grid-gutter-width;
}

.order-details-page .totals {
    width: 100%;
    float: right;
    @include media-breakpoint-up(lg) {
        width: 33.333%;
    }
}

.order-details-page .order-overview .order-number {
    margin: 0 0 10px;
    text-transform: uppercase;
}

.order-details-page .download a {
    font-weight: normal;
}

.order-details-page .download a:hover {
    text-decoration: underline;
}

.user-agreement-page .terms-of-agreement {
    margin: 0 0 20px;
    padding: 20px;
    text-align: center;
}

.user-agreement-page .button-1 {
    min-width: 140px;
    border: none;
    padding: 10px 30px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
}

.registration-result-page {
    .buttons {
        text-align: right;
    }
}

.order-details-page .tax-shipping-info {
    margin: 30px 0;
}

.order-details-page .tax-shipping-info a:hover {
    text-decoration: underline;
}

.order-details-page .actions {
    margin: $grid-gutter-width 0 0;
    text-align: right;
}

.order-details-page .total-info,
.shopping-cart-page .total-info,
.checkout-page .total-info {
    .cart-total {
        tbody {
            tr {
                td {
                    padding: $grid-gutter-width 0;
                    label {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

/* Page Print Version */

@media print {

    header,
    footer,
    .admin-header-links,
    .also-purchased-products-grid,
    .c-product-buttons__wrapper,
    .product-ribbon,
    .offcanvasBtn,
    .page-title,
    .c-previous-next-product__wrapper,
    .order-details-print {
        display: none !important;
    }

    .order-details-area .billing-info-wrap > div, .order-details-area .shipping-info-wrap > div {
        margin: 0 -16px;
    }

    .order-details-page .actions {
        display: none;
    }

    .order-details-page .totals {
        width: 33.333%;
    }
}


.ui-tabs {
	position: relative;/* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
	padding: .2em;
}
.ui-tabs .ui-tabs-nav {
	margin: 0;
	padding: .2em .2em 0;
}
.ui-tabs .ui-tabs-nav li {
	list-style: none;
	float: left;
	position: relative;
	top: 0;
	margin: 1px .2em 0 0;
	border-bottom-width: 0;
	padding: 0;
	white-space: nowrap;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
	float: left;
	padding: .5em 1em;
	text-decoration: none;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active {
	margin-bottom: -1px;
	padding-bottom: 1px;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-state-disabled .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-tabs-loading .ui-tabs-anchor {
	cursor: text;
}
.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
	cursor: pointer;
}
.ui-tabs .ui-tabs-panel {
	display: block;
	border-width: 0;
	padding: 1em 1.4em;
	background: none;
}