.html-manufacturer-page .master-wrapper-content
 {
    padding: $grid-gutter-width * 6 0;
    @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width * 4 0;
    }
}

.manufacturer-page {
    .block-title {
        margin-bottom: $grid-gutter-width / 2;
    }
}

.category-page {
    background-color: $gray-100;
    .breadcrumb {
        margin: $grid-gutter-width / 2 0;
    }
}

.html-category-page {
    .header {
            @include media-breakpoint-up(lg) {
            position: absolute;
            background: rgba(0, 0, 0, 0.9);
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
        }
    }
    .dirt-element {
        background-color: #f5f5f5;
        background-image: url(/images/dirt-tran1.png);
    }
}

.compare-products-link {
    text-align: center;
    a {
        display: block;
        width: 50px;height: 50px;
        color: inherit;
        background-color: $gray-200;
        padding: $grid-gutter-width;
        border-radius: 100%;line-height: 1.2;
        margin-left: 1rem;
        &:focus,
        &:hover,
        &:active {
            border-color: transparent;
            background-color: #ddd;
        }
    }
}

.c-global-masthead {
    background-color: $gray-200;
    padding-bottom: $grid-gutter-width;
    &--inner {
        padding: 180px 0 36px;
        text-align: center;
        background-size: 100%;
        background-position: 65% 94%;
        background-repeat: no-repeat;
        @media (min-width: 992px) {
            padding: 260px 0 100px;
            background-size: cover;
            text-align: center;
        }
        .title {
            display: inline-block;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            padding: $grid-gutter-width;
            border-radius: $border-radius;
            border-left: 3px solid $brand;
            border-right: 3px solid $brand;
        }
    }
}

/* Catalog Header */

.product-page-size,
.product-sorting {
    select {
        background-color: #ebebeb;
        padding: 16px 32px 16px 16px !important;
        height: 50px !important;
        appearance: none;
        background-position: right 15px center;
        border-radius: 30px;
        border: 0;
        background-image: url(../images/arrow.svg);
        background-repeat: no-repeat;

    }  
}

.product-page-size {
    select {
        width: 70px;
    }
}

// Homepage Carousel

.nop-jcarousel {
    .product-description {
        display: none;
    }
}

.c-product-item {
    position: relative;
    padding: 0 $grid-gutter-width $grid-gutter-width;
    background-color: white;
    height: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    &:hover {
        box-shadow: 0 0 4px 0 rgba(0,0,0,.2);
    }
    .product-title {
        a {
            color: black;
        }
    }
    &-actions {
        button {
            .fa-shopping-cart {
                margin-right: 5px;
            }
        }
    }
    &__picture {
        position: relative;
        z-index: 1;
        border-bottom: 0;
        a {
            display: block;
            position: relative;
            img {
                position: absolute;
                top: 0;right: 0;bottom: 0;left: 0;
                max-width: 100%;max-height: 100%;
                margin: auto;
            }
        }
    }
}

.item-box {
    position: relative;
    margin-bottom: 1.8%;
    .prices {
        position: relative;
    }
    .actual-price {
        color: $brand-secondary;
    }
    .old-price {
        position: absolute;
        left: 0;right: 0;
        text-decoration: line-through;
    }
}

.item-box .list-picture {
    z-index: 1;
    overflow: hidden;
    background-color: #fff;
    margin: 0 0 20px;
}

.item-box .list-picture {
    margin: 0;
}

.item-box .list-picture a {
    display: block;
    position: relative;
}

.item-box .list-picture a {
    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.item-box .picture a:before {
    content: "";
    display: block;
    padding-top: 80%;
}

.item-box .list-picture a img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.item-box .sku {
    margin: 0 0 15px;
}

.item-box .product-rating-box {
    display: inline-block;
    margin: 0 0 10px;
}

.item-box .rating {
    background: url('../images/rating1.png') repeat-x;
    width: 95px;
    height: 14px;
}

.item-box .rating div {
    background: url('../images/rating2.png') repeat-x;
    height: 14px;
}


.item-box .description a {
    display: block;
    overflow: hidden;
}

.item-box .add-to-compare-list-button,
.item-box .product-box-add-to-cart-button {
    width: 45px;height: 45px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    transition: all .15s ease;
    &:hover,
    &:active,
    &:focus {
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    }
    @include media-breakpoint-up(lg) {
        width: 45px;height: 45px;
        border-radius: 50% !important;
    }

}

@include media-breakpoint-up(lg) {
    .jCarouselMainWrapper .slick-next, .jCarouselMainWrapper .slick-prev {
        top: 0;
    }
}

/* Bootstrap Inline Select List */

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 1px;
}


@media only screen and (max-width:991px){
    .page-selectors {
        select {
            height: 21px !important;
            padding: 2px !important;
            font-size: 12px;
        }
    }
}



.c-compare-product__link {
    width: 45px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    border: 1px solid $gray-400;
    margin-left: 10px;
    color: $gray-600;
    &:hover {
        color: $brand;
        border-color: $brand;
    }
    @media only screen and (max-width: 991px){
    height: 21px;
    line-height: 21px;
    }
}

.left-panel .nopAjaxFilters7Spikes .block .filtersGroupPanel,
.left-panel .nopAjaxFilters7Spikes .checkbox-list label,
.left-panel .priceRangeMinPanel, .left-panel .priceRangeMaxPanel {
    color: $gray-900;
    font-size: 0.875rem;
}

.product-selectors {
    &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        float: none;
        justify-content: space-between;
        @include media-breakpoint-up(lg) {
            float: right;
        }
        label {
            display: none;
            @include media-breakpoint-up(lg) {
                display: inline-block;
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }
        .mobile-sort-icon {
            display: inline-block;
            color: #3FB693;
            font-size: 2rem;
            margin-right: $grid-gutter-width / 2;
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
        .product-page-size {
            display: none;
            margin: 0 0 0 $grid-gutter-width;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
}

// Catalog Mobile Filter Button

.mobile-catalog-filer {
    margin-left: $grid-gutter-width;
    button {
        margin: 0;
        width: 100%;
        padding: 0 $grid-gutter-width;
        font-size: 2rem;
        &:hover,
        &:focus,
        &:active {
            color: $brand;
        }
    }
}

.filter-no-1028,
.filter-no-1026,
 {
    .filtersGroupPanel {
        .checkbox-list {
            position: relative;
            height: 180px;
            overflow-y: scroll;
            scrollbar-color: $brand $gray-200;
            border: 1px dashed $border-color;
            padding: 10px;
            background-color: white;
            @include media-breakpoint-up(lg) {
                border-right: 0;
            }
        }
    } 
}

/* ribbon wrapping element */

.product-ribbon {
	display: inline-block;
	vertical-align: middle;
	position: relative;
}

/* ribbon label without image*/

.ribbon-text {
	font-size: 12px;
    line-height: normal;
    color: white;
    padding: 8px 12px;
    margin-bottom: 0;
}

// Avaliability Semafor

.catalog-card-stock {
    position: absolute;
    bottom: 0;left: 0;right: 0;
    .availability-semafor {
        position: relative;
        display: block;
        &::before {
            content: ' ';
            width: 100px;height: 4px;
            display: block;
            position: absolute;
            right: 0;left: 0;top: auto;bottom: 0px;
            margin: auto;
            border-radius: 8px 8px 0px 0px;border: 0; 
        }
    }
}

.cross-sells .c-product-item,
.articles-related-products .c-product-item,
.l-homepage-bike-featured .c-product-item, 
.l-homepage-parts-featured .c-product-item {
    border: 1px solid #ebebeb;
}

.c-last-view-products {
    .nop-jcarousel {
        position: relative;
        margin: 0 auto;
        max-width: 100%;
    }
}

.checkbox-list::-webkit-scrollbar {
	width: 16px;
	background-color: $gray-200;
}

.checkbox-list::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: $brand;
}

.scroll-mobile {
    position: absolute;
    right: $grid-gutter-width;
    font-size: 50px;
    color: $border-color;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}



