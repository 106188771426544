
// FoxNet Articles List

.block.block-articlegroup-navigation {
    .title {
        margin-bottom: 0;
    }
    .listbox {
        padding: $grid-gutter-width;
        ul.list {
            padding-left: $grid-gutter-width;
            list-style-type: square;
            li {
                margin-bottom: 1rem;
            }
        }
    }
}

.article-page {
    &.list {
        .article-box {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: $grid-gutter-width;
        }    
    }
}

// FoxNet Articles Detailed Pages

