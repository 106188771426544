.newsletter-subscribe-button {
    font-size: 14px !important;
}

.newsletter-email {
    position: relative;
    width: 100%;
}

.newsletter-email input[type="email"] {
    width: 100% !important;
}
.newsletter-email .newsletter-subscribe-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
}
