/********** CATEGORY PAGE **********/

.breadcrumb {
    padding: 0;
    background-color: transparent;
    li {
        display: inline-block;
        a {
            &:hover {
                color: $brand;
            }
        }

    }
}

.breadcrumb {
    li * {
        display: inline-block;
        line-height: 32px;
    }
    .current-item {
        color: black;
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f84a";
    color: $brand;
    padding-right: 0.4rem;
}

.category-description,
.manufacturer-description,
.vendor-description {
    margin: 0 0 25px;
    line-height: 22px;
}

.contact-vendor {
    margin: 0 0 30px;
}

.contact-vendor .button-2 {
    border: none;
    background-color: #4ab2f1;
    padding: 10px 15px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
}

.contact-vendor .button-2:hover {
    background-color: #248ece;
}

.item-box {
    .details {
        @media (max-width: 768px) {
            padding: 0;
        }
    }
}

.product-selectors:after {
    content: "";
    display: block;
    clear: both;
}

.product-selectors span {
    vertical-align: middle;
}

.product-selectors select {
    margin: 0;
}

.product-selectors .product-viewmode {
    display: none;
    font-size: 0;
}

.product-filters {
    display: table;
    width: 100%;
    margin: -20px 0 20px;
    border-bottom: 1px solid #ddd;
}

.product-filter {
    margin: 0 0 10px;
}

.product-filter .filter-title {
    margin: 0 0 10px;
    background-color: #f6f6f6;
    padding: 10px;
    color: #444;
}

.product-filter .filter-title strong {
    font-weight: normal;
}

.product-filter .filter-content {
    padding: 10px;
    color: #444;
}

.product-filter .available-items:after {
    content: "";
    display: block;
    clear: both;
}

.product-filter .group {
    margin: 0 0 10px;
}

.product-filter .group li {
}

.product-filter .group li a,
.product-filter .group li strong {
    display: inline-block;
    padding: 5px 0;
}

.product-filter .group li a:hover {
    color: #4ab2f1;
}

.product-filter .group li.item {
    display: inline-block;
    position: relative;
    margin: 0 15px 0 0;
    padding: 0 0 0 15px;
}

.product-filter .group li.item:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #ddd;
}

.product-filter .group li.color-item {
    padding: 0;
    display: inline-block;
}

.product-filter .group li.color-item a {
    padding: 0;
}

.product-filter .group li.color-item:before {
    display: none;
}

.filtered-items .title {
    margin: 0 0 5px;
    font-style: italic;
}

.filtered-items .item {
    color: #4ab2f1;
}

.remove-filter {
    margin: 15px 0;
}

.remove-filter a {
    display: inline-block;
    background-color: #aaa;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
}

.remove-filter a:hover {
    background-color: #999;
}

.item-grid:after {
    content: "";
    display: block;
    clear: both;
}

.item-box .add-to-compare-list-button:hover {
    background-color: #ddd;
}

.home-page-category-grid,
.sub-category-grid,
.vendor-grid {
    margin: 0 0 20px;
}

.home-page-category-grid .title,
.sub-category-grid .title {
    text-align: center;
    font-weight: normal;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.home-page-category-grid .title a,
.sub-category-grid .title a {
    display: block;
    padding: 0 0 15px;
}

.home-page-category-grid .picture,
.sub-category-grid .picture {
    margin: 0;
}

.home-page-category-grid .picture a:before,
.sub-category-grid .picture a:before {
    padding-top: 65%;
}

.manufacturer-grid .title {
    margin: 0 0 15px;
    text-align: center;
    font-size: 16px;
}

.pager {
    margin: 20px 0;
    text-align: center;
}

.pager ul {
    display: inline-block;
}

.pager li {
    display: inline-block;
    margin: 0 5px;
    vertical-align: top;
}

.pager li a,
.pager li span {
    display: block;
    min-width: 40px;
    height: 45px;
    padding: 8px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    line-height: 1.6;
}

.pager li span {
    border-bottom: 3px solid;

}

.pager li.previous-page *,
.pager li.next-page *,
.pager li.first-page *,
.pager li.last-page * {
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    color: #fff;
}

.pager li.previous-page * {
    background-image: url('../images/prev.png');
}

.pager li.next-page * {
    background-image: url('../images/next.png');
}

.pager li.first-page * {
    background-image: url('../images/first.png');
}

.pager li.last-page * {
    background-image: url('../images/last.png');
}

.pager li a:hover {
    border-bottom: 3px solid;
}

@media all and (min-width: 769px) {

    /*** CATEGORY ***/
    /*
    .product-selectors {
        text-align: left;
    }
    */

    .product-selectors .product-viewmode {
        display: inline-block;
        float: right;
    }

    .product-selectors .product-viewmode span {
        display: none;
    }

    .product-selectors .product-viewmode a {
        display: inline-block;
        width: 24px;
        height: 32px;
        margin: 0 0 0 12px;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    .product-selectors .product-viewmode a.grid {
        background-image: url('../images/view-grid.png');
    }

    .product-selectors .product-viewmode a.list {
        background-image: url('../images/view-list.png');
    }

    .product-selectors .product-viewmode a.selected {
        opacity: 1;
    }

    .item-box:nth-child(2n+1) {
        clear: none;
    }

    .item-box:nth-child(3n+1) {
        clear: both;
    }

    .product-list .item-box {
        float: none;
        width: auto;
        margin: 0 0 20px;
    }

    .product-list .item-box .product-item:after {
        content: "";
        display: block;
        clear: both;
    }

    .product-list .item-box .picture {
        float: left;
        width: 220px;
    }

    .product-list .item-box:hover .picture a img {
        opacity: 1;
    }

    .product-list .item-box .picture:hover a img {
        opacity: 0.85;
    }

    .product-list .item-box .picture + .details {
        margin: 0 0 0 240px;
    }

    .product-list .item-box .product-title {
        height: auto;
        padding: 10px 0;
        font-size: 18px;
    }

    .product-list .item-box .description {
        display: block;
        margin: 0 0 10px;
        line-height: 22px;
    }

    .product-list .item-box .prices {
        height: auto;
        margin: 0 0 30px;
    }

    .product-list .item-box .product-box-add-to-cart-button {
        width: auto;
        min-width: 150px;
        padding: 0 20px;
    }

    .product-list .item-box .add-to-compare-list-button,
    .product-list .item-box .add-to-wishlist-button {
        width: 42px;
    }

}


@include media-breakpoint-up(sm) {

    /*** CATEGORY ***/

    .product-filter {
        display: table-cell;
        vertical-align: top;
        text-align: left;
    }

    .product-filter.price-range-filter {
        width: 200px;
    }

    .product-filter .product-spec-group {
        float: left;
        margin: 0 25px 10px 0;
    }

    .product-filter .product-spec-group.product-spec-color {
        max-width: 190px;
    }

    .product-filter .group li.item {
        display: block;
        margin: 0;
    }

    .product-filter .group li.color-item {
        display: inline-block;
    }

    .item-box:nth-child(2n+1) {
        clear: both;
    }

    .item-box .product-rating-box {
        margin: 0 0 15px;
    }

}

@include media-breakpoint-up(sm) {

    /*** CATEGORY ***/

    /*
    .product-selectors {
        text-align: left;
    }
    */

    .product-selectors .product-viewmode {
        display: inline-block;
        float: right;
    }

    .product-selectors .product-viewmode span {
        display: none;
    }

    .product-selectors .product-viewmode a {
        display: inline-block;
        width: 24px;
        height: 32px;
        margin: 0 0 0 12px;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    .product-selectors .product-viewmode a.grid {
        background-image: url('../images/view-grid.png');
    }

    .product-selectors .product-viewmode a.list {
        background-image: url('../images/view-list.png');
    }

    .product-selectors .product-viewmode a.selected {
        opacity: 1;
    }

    .item-box:nth-child(2n+1) {
        clear: none;
    }

    .item-box:nth-child(3n+1) {
        clear: both;
    }

    .product-list .item-box {
        float: none;
        width: auto;
        margin: 0 0 20px;
    }

    // dodatak za Catalog Ajax Filter List PSlugin

    .product-list {
        .product-item {
            &.list {
                .details {
                    width: auto !important;
                    margin: 0 0 0 240px;
                    padding: 0;
                }
            }
        }
    }

    //////////////////////////////////////////////

    .product-list .item-box .product-item:after {
        content: "";
        display: block;
        clear: both;
    }

    .product-list .item-box .list-picture {
        float: left;
        width: 220px;
    }

    .product-list .item-box:hover .picture a img {
        opacity: 1;
    }

    .product-list .item-box .picture:hover a img {
        opacity: 0.85;
    }

    .product-list .item-box .picture + .details {
        margin: 0 0 0 240px;
    }

    .product-list .item-box .product-title {
        height: auto;
        padding: 10px 0;
        font-size: 18px;
    }

    .product-list .item-box .description {
        display: block;
        margin: 0 0 10px;
        line-height: 22px;
    }

    .product-list .item-box .prices {
        height: auto;
        margin: 0 0 30px;
    }

    .product-list .item-box .product-box-add-to-cart-button {
        width: auto;
        min-width: 150px;
        padding: 0 20px;
    }

    .product-list .item-box .add-to-compare-list-button,
    .product-list .item-box .add-to-wishlist-button {
        width: 42px;
    }
}


@include media-breakpoint-up(lg) {

    /*** CATEGORY ***/

    .breadcrumb {
       margin: 0 0 9px;
   }

   .home-page .product-grid .item-box:nth-child(3n+1),
   .product-details-page .product-grid .item-box:nth-child(3n+1),
   .shopping-cart-page .product-grid .item-box:nth-child(3n+1) {
       clear: none;
   }

   .home-page .product-grid .item-box:nth-child(4n+1),
   .product-details-page .product-grid .item-box:nth-child(4n+1),
   .shopping-cart-page .product-grid .item-box:nth-child(4n+1) {
       clear: both;
   }

}

/* Css By S.K. */


.tree-menu a {
  display: block;
  vertical-align: middle;
  text-decoration: none;
  -moz-transition: background-color 0.25s linear;
  -webkit-transition: background-color 0.25s linear;
  -o-transition: background-color 0.25s linear;
  transition: background-color 0.25s linear;
  -moz-transition-delay: 0.15s;
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.tree-menu a:hover {
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}


// Left Side Category Navigation

.block-category-navigation,
.block-account-navigation,
.block-recently-viewed-products,
.priceRangeFilterPanel7Spikes,
.manufacturerFilterPanel7Spikes,
.attributeFilterPanel7Spikes,
.onSaleFilterPanel7Spikes,
.specificationFilterPanel7Spikes {
    .title {
        padding: 0 0 $grid-gutter-width / 2;
        margin-left: $grid-gutter-width;
        font-weight: bold;
        text-align: left;
        border-bottom: 1px solid $border-color;
        &::before {
            content: "";
            position: absolute;
            left: 0;bottom: 0;
            display: block;
            width: 18px;height: 3px;
            background-color: $brand;
        }
    }
}

.block-category-navigation,
.block-account-navigation {
    .listbox {
        ul {
            display: block;
            li {
                padding: $grid-gutter-width / 4 0;
                &.active {
                    font-weight: bold;
                    a {
                        color: black;
                        &:hover,
                        &:active {
                            color: black;
                        }
                    }
                }
                &.opened {
                    .plus-nav {
                        &:before {
                            content: "-";
                        }
                    }
                }
                .plus-nav {
                    position: relative;
                    display: block;
                    float: right;
                    width: 25px;height: 25px;
                    cursor: pointer;
                    &:before {
                        position: absolute;
                        content: "+";
                        display: block;
                        width: 20px;height: 20px;
                        background: rgb(255,220,100);
                        background: linear-gradient(90deg, rgba(255,220,100,1) 27%, rgba(255,198,0,1) 68%);
                        top: 0;right: 0;
                        line-height: normal;
                        text-align: center;
                        border-radius: 4px;
                    }
                }
                a {
                    font-size: 1rem;
                    display: inline-block;
                    position: relative;
                    transition: all 0.1s ease;
                }
            }
        }
        ul.list {
            > li {
                &:last-child {
                    border: 0;
                }
                > a {
                    color: black;
                    font-weight: bold;
                }
            }
        }
        .sublist {
            margin: $grid-gutter-width / 2 0 $grid-gutter-width / 2 $grid-gutter-width;
        }
    }
}

// Left Side Filters

.left-panel {
    .block {
       margin-bottom: 3rem;
    }
}


.nopAjaxFilters7Spikes {
    .filtersGroupPanel {
        padding: 0 0 0$grid-gutter-width;
    }
    .price-range-slider {
        margin: 0 0 20px;
        border-radius: 0;
        height: 1px;
        border: 1px solid $gray-900;
        .ui-slider-range {
            top: 1px;
            height: 0;
            background: $gray-900;
        }
        .ui-slider-handle {
            top: -8px !important;
            width: 17px !important;
            height: 17px !important;
            padding: 0 !important;
            cursor: pointer !important;
            background: $brand !important;
            background: $brand-gradient !important;
            border-radius: 4px !important;
            margin: 0 0 0 -10px !important;
            border: 0;
            border-radius: 4px;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
            &:nth-child(2) {
                margin-left: -2px !important;
            }
            &:last-child {
                margin-left: -16px !important;
            }
        }
    }
}

.priceRangeMinMaxPanel {
    .priceRangeMaxPanel,
    .priceRangeMinPanel,{
        background-color: black;
        color: white;
        padding: 4px;
        border-radius: 4px;
    }
} 

.nopAjaxFilters7Spikes .block .title a.toggleControl:after {
	content: "";
	position: absolute;
	top: 40%;right: 5px;
	width: 12px;height: 19px;
	margin-top: -8px;
}

.nopAjaxFilters7Spikes .block .title a.clearFilterOptions {
    position: absolute;
    top: 50%;
    right: 40px;
    font-size: 14px;
    line-height: 1;
    text-transform: lowercase;
    color: $brand-secondary;
    cursor: pointer;
    margin-top: -7px;
}

.nopAjaxFilters7Spikes .block .title a.clearPriceRangeFilter {
    position: absolute;
    top: 50%;
    right: 40px;
    font-size: 14px;
    line-height: 1;
    text-transform: lowercase;
    color: $brand-secondary;
    cursor: pointer;
    margin-top: -7px;
}

.block-manufacturer-navigation {
    select {
        width: 100%;
        height: 40px !important;
        border-color: $gray-300;
    }
}
.nopAjaxFilters7Spikes .checkbox-list label {
    position: relative;
    display: block;
    font-size: 16px;
    color: #444;
    padding: 2px 0 4px 25px;
    cursor: pointer;
    -webkit-transition: background .15s ease-in-out;
    transition: background .15s ease-in-out;
}