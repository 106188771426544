/*********** FOOTER ***********/

.c-global-footer {
    background-color: rgba(0,0,0, 0.9);
    padding: 56px 0 0;
    &__upper {
        padding: 56px 0;
        border-top: 1px solid $brand;
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }
    &__bottom {
        background-color: rgba(255,255,255, 0.1);
        padding: $grid-gutter-width * 1.5 0;
        ul > li {
            margin-bottom: 0.5rem;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
    }
    &__lower {
        padding: $grid-gutter-width / 2 0;
    }
    &__lower-item {
        display: inline-block;
        padding: 16px 0;
        margin-bottom: 5px;
        &.footer-implementation {
            padding-left: $grid-gutter-width / 2;
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }   
    }

    &__block {
        &.address {
            order: 1;
            @include media-breakpoint-up(lg) {
                order: unset;
            }
        }
        &.shop {
            order: 2;
            @include media-breakpoint-up(lg) {
                order: unset;
            }
        }
        &.links {
            order: 3;
            @include media-breakpoint-up(lg) {
                order: unset;
            }
        }
        .o-title {
            position: relative;
            padding-bottom: $grid-gutter-width;
            color: white;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
                text-align: left;
            }
            &::before,
            &::after {
                content: "\f078";
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                top: 0;
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
            &::before {
                content: none;
            }
            &::after {
                right: $grid-gutter-width;
            }
        }
        .l-footer-list {
            .info-title {
                margin-bottom: 0.5rem;
                color: $brand;
                font-weight: bold;
            }
            a,ul {
                color: #888888;
            }
        }

    }


    &__social-info {
        .o-title {
            padding-bottom: $grid-gutter-width / 2;
        }
    }

}


.footer-title-inner {
    position: relative;
    .footer-title-box {
        position: absolute;
        top: -20px;
        left: 0;
        padding: 10px 20px;
        background-color: $brand;
        color: $white;
        font-size: 18px;
    }
}


@include media-breakpoint-up (sm) {

    .c-global-footer {
        &__lower-item {
            padding-left: 0;
        }
        &__lower-item {
            &.footer-implementation {
                padding-left: $grid-gutter-width;
            }   
        }

    }
}

@include media-breakpoint-up (md) {

    .c-global-footer {
  
        &__lower-item {
            display: inline-block;
            padding-right: $grid-gutter-width;
            margin-bottom: 5px;
            &.footer-implementation {
                padding-left: $grid-gutter-width;
            }   
        }
        
        &__lower-item {
            &.footer-implementation {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 1px;
                    height: 10px;
                    background: $gray-600;
                    margin: auto;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 1px;
                    height: 10px;
                    background: $gray-600;
                    margin: auto;
                }
            }
        }

    }

    .lower-block {
        padding: 0 $grid-gutter-width;
        &.footer-implementation {
            padding-left: $grid-gutter-width;
        }
    }

    .follow-us {
        width: 98%;
        margin: 25px auto 0;
    }

}

@include media-breakpoint-up (lg) {

    .c-global-footer {
        &__block {
            margin-bottom: 0;
        }

        &__contact-info {
            ul {
                li {
                    .kamir-map-marker,
                    .kamir-clock,
                    .kamir-phone {
                        line-height: 35px;
                    }
                }
            }
        }    
    }

    .footer-upper:after {
        content: "";
        display: block;
        clear: both;
    }

    .footer-lower:after {
        content: "";
        display: block;
        clear: both;
    }

    .theme-selector {
        float: left;
        margin: 0 0 0 15px;
    }

}


// 4.2

.l-footer-list {
    ul > li {
        line-height: 20px;
        margin-bottom: 0.5rem;
    }
}
