// =============================================================================
// TOOLS / #FUNCTIONS
// =============================================================================

// Rounding
// ==============================================

// Three functions to round numbers to a specified decimal place.
// @author Takeru Suzuki (gist.github.com/terkel/4373420)

// decimal-round()
// ------------------------------------

// Round a number to specified digits.
//
// For example:
//   decimal-round(0.333)    => 0
//   decimal-round(0.333, 1) => 0.3
//   decimal-round(0.333, 2) => 0.33
//   decimal-round(0.666)    => 1
//   decimal-round(0.666, 1) => 0.7
//   decimal-round(0.666, 2) => 0.67
//
@function decimal-round($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
      @warn "#{ $number } is not a number.";
      @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
      @warn "#{ $digits } is not a number.";
      @return $number;
    } @else if not unitless($digits) {
      @warn "#{ $digits } has a unit.";
      @return $number;
    }
  
    @for $i from 1 through $digits {
      $n: $n * 10;
    }
  
    @if $mode == round {
      @return round($number * $n) / $n;
    } @else if $mode == ceil {
      @return ceil($number * $n) / $n;
    } @else if $mode == floor {
      @return floor($number * $n) / $n;
    } @else {
      @warn "#{ $mode } is undefined keyword.";
      @return $number;
    }
  }
  
  // decimal-ceil()
  // ------------------------------------
  
  // Ceil a number to specified digits.
  //
  // For example:
  //   decimal-ceil(0.333)    => 1
  //   decimal-ceil(0.333, 1) => 0.4
  //   decimal-ceil(0.333, 2) => 0.34
  //   decimal-ceil(0.666)    => 1
  //   decimal-ceil(0.666, 1) => 0.7
  //   decimal-ceil(0.666, 2) => 0.67
  //
  @function decimal-ceil($number, $digits: 0) {
    @return decimal-round($number, $digits, ceil);
  }
  
  // decimal-floor()
  // ------------------------------------
  
  // Floor a number to specified digits.
  //
  // For example:
  //   decimal-floor(0.333)    => 0
  //   decimal-floor(0.333, 1) => 0.3
  //   decimal-floor(0.333, 2) => 0.33
  //   decimal-floor(0.666)    => 0
  //   decimal-floor(0.666, 1) => 0.6
  //   decimal-floor(0.666, 2) => 0.66
  //
  @function decimal-floor($number, $digits: 0) {
    @return decimal-round($number, $digits, floor);
  }
  
  // Maps
  // ==============================================
  
  // map-get-deep()
  // ------------------------------------
  
  // Access nested map values.
  // @author Hugo Giraudel (with modifications)
  @function map-get-deep($map, $keys...) {
    @if map-has-keys-deep($map, $keys...) {
      @each $key in $keys {
        $map: map-get($map, $key);
      }
  
      @return $map;
    }
  
    @warn "Unknown keys of `#{$keys...}` in the defined map";
    @return null;
  }
  
  // map-has-keys-deep()
  // ------------------------------------
  
  // Check for keys in nested maps.
  // @author Hugo Giraudel
  @function map-has-keys-deep($map, $keys...) {
    @each $key in $keys {
      @if not map-has-key($map, $key) {
        @return false;
      }
      $map: map-get($map, $key);
    }
  
    @return true;
  }
  
  // z-index()
  // ------------------------------------
  
  // Function to get value from `$z-index` instead of using `map-get`.
  //
  // Usage:
  //
  //   .foo {
  //     z-index: z-index(<key>);
  //   }
  //
  @function z-index($key) {
    @return map-get-deep($z-index, $key);
  }
  
  
  // reverse()
  // ------------------------------------
  
  // Function to reverse list of values
  //
  // Example:
  //    reverse(1,2,3,4) => 4,3,2,1
  //    reverse(a 1, b 2, c 3) => c 3, b 2, a 1
  //
  @function reverse($list) {
    $reversed-list: ();
  
    @for $index from length($list)*-1 through -1 {
      $reversed-list: append($reversed-list, nth($list, abs($index)), comma);
    }
  
    @return $reversed-list;
  }
  
  // strip-unit()
  // ------------------------------------
  
  // Function to remove the unit from a value
  // i.e. strip-unit(20px) would return 20.
  @function strip-unit($num) {
    @if type_of($num) == number {
      @return $num / ($num * 0 + 1);
    }
  
    @else {
      @warn "Value must be a number i.e. 12, 24px etc.";
    }
  }
  
  // rem conversion utilities
  
  $rem-baseline: 16px !default;
  $rem-fallback: false !default;
  $rem-px-only: false !default;
  
  @function rem-separator($list, $separator: false) {
    @if $separator == "comma" or $separator == "space" {
      @return append($list, null, $separator);
    } 
    
    @if function-exists("list-separator") == true {
      @return list-separator($list);
    }
  
    // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
    $test-list: ();
    @each $item in $list {
      $test-list: append($test-list, $item, space);
    }
  
    @return if($test-list == $list, space, comma);
  }
  
  @mixin rem-baseline($zoom: 100%) {
    font-size: $zoom / 16px * $rem-baseline;
  }
  
  @function rem-convert($to, $values...) {
    $result: ();
    $separator: rem-separator($values);
    
    @each $value in $values {
      @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
        $result: append($result, $value / 1rem * $rem-baseline, $separator);
      } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
        $result: append($result, $value / $rem-baseline * 1rem, $separator);
      } @else if type-of($value) == "list" {
        $value-separator: rem-separator($value);
        $value: rem-convert($to, $value...);
        $value: rem-separator($value, $value-separator);
        $result: append($result, $value, $separator);
      } @else {
        $result: append($result, $value, $separator);
      }
    }
  
    @return if(length($result) == 1, nth($result, 1), $result);
  }
  
  @function rem($values...) {
    @if $rem-px-only {
      @return rem-convert(px, $values...);
    } @else {
      @return rem-convert(rem, $values...);
    }
  }
  
  @mixin rem($properties, $values...) {
    @if type-of($properties) == "map" {
      @each $property in map-keys($properties) {
        @include rem($property, map-get($properties, $property));
      }
    } @else {
      @each $property in $properties {
        @if $rem-fallback or $rem-px-only {
          #{$property}: rem-convert(px, $values...);
        }
        @if not $rem-px-only {
          #{$property}: rem-convert(rem, $values...);
        }
      }
    }
  }