// =============================================================================
// TOOLS / #TYPOGRAPHY
// =============================================================================

// Functions
// ==============================================

// font-size()
// ------------------------------------

// Function to get font-size from `$text` instead of using `map-get`.
@function font-size($key, $variant:large) {
    @return map-get-deep($text, $key, $variant, font-size);
    // @return _text-map-get(font-size, $key, $variant);
  }
  
  // Function to get font-size from `$text`map in px and convert to rem.
  @function font-size-rem($key, $variant:large) {
    @return rem(map-get-deep($text, $key, $variant, font-size));
  }
  
  // line-height()
  // ------------------------------------
  
  // Function to get line-height from `$text` instead of using `map-get`.
  @function line-height($key, $variant:large) {
    @return map-get-deep($text, $key, $variant, line-height);
  }
  
  // Mixins
  // ==============================================
  
  // @include font()
  // ------------------------------------
  
  // Create a fully formed type style (sizing and line-height) by passing in a
  // single value, e.g:
  //
  // .foo {
  //   @include font(text-body);
  // }
  //
  // This will generate a rem-based font-size with its pixel fallback and
  // line-height, e.g:
  //
  // .foo {
  //   font-size: 18px;
  //   font-size: 1rem;
  //   line-height: 1.4;
  // }
  
  @mixin font($key, $variant:large, $line-height:auto) {
    $font-size: font-size($key, $variant);
  
    @include rem("font-size", $font-size);
  
    @if (type-of($line-height)==number or $line-height=='inherit' or $line-height=='normal') {
      line-height: $line-height;
    }
    @else if ($line-height !='none' and $line-height !=false) {
      line-height: line-height($key, $variant);
    }
  
  }
  
  // @include font-size()
  // ------------------------------------
  
  /// Generate a font-size and baseline-compatible line-height.
  ///
  /// @example scss
  ///   .element {
  ///     @include font-size(20px, 24px);
  ///   }
  
  @mixin font-size($font-size, $line-height: auto) {
    @if (type-of($font-size)==number) {
      @if (unit($font-size) !="px") {
        @error "`#{$font-size}` needs to be a pixel value.";
      }
    }
    @else {
      @error "`#{$font-size}` needs to be a number.";
    }
  
    // We provide a `px` fallback for old IEs not supporting `rem` values.
    font-size: $font-size;
    //font-size: ($font-size / $font-size-base) * 1rem $important;
    @include rem("font-size", $font-size);
    @if ($line-height=='auto') {
      @include rem("line-height", ceil($font-size / $line-height-base) * ($line-height-base / $font-size));
    }
    @else {
      @if (type-of($line-height)==number or $line-height=='inherit' or $line-height=='normal') {
        //line-height: $line-height $important;
        @include rem("line-height", $line-height);
      }
      @else if ($line-height !='none' and $line-height !=false) {
        @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`.";
      }
    }
  }
  
  // @include font-size-variable()
  // ------------------------------------
  
  /// Generate a font-size and baseline-compatible line-height in 2 dimensions one for bigger screens and one for smaler screens.
  ///
  /// @example scss
  ///   .element {
  ///     @include font-size-variable(18px, 12px, 1.5rem, 1.2rem);
  ///   }
  
  @mixin font-size-variable($font-size-large, $font-size-small, $line-height-large: auto, $line-height-small: auto) {
    @include font-size($font-size-small, $line-height-small);
    @include media-breakpoint-up(xl) {
      @include font-size($font-size-large, $line-height-large);
    }
  }
  
  // @include font-family()
  // ------------------------------------
  
  /// Human friendly font family keywords.
  ///
  /// @example scss
  ///   .element {
  ///     @include font-family(source-sans-pro);
  ///   }
  
  @mixin font-family($family) {
    @if ($family=="source-sans-pro") {
      font-family: 'source-sans-pro', sans-serif;
    }
  
    @else if ($family=="encode-sans-condensed") {
      font-family: 'encode-sans-condensed', sans-serif;
    }
  
    @else {
      @warn "#{$family} is not a valid font-family."
    }
  };
  
  // @include font-weight()
  // ------------------------------------
  
  /// Human friendly font weight keywords.
  /// Map keywords onto our numbered weights for convenience.
  ///
  /// @example scss
  ///   .element {
  ///     @include font-weight(bold);
  ///   }
  
  @mixin font-weight($weight) {
    @if ($weight==normal or $weight==regular) {
      font-weight: 400;
    }
    @else if ($weight==extralight) {
      font-weight: 200;
    }
    @else if ($weight==light) {
      font-weight: 300;
    }
    @else if ($weight==semibold or $weight==medium) {
      font-weight: 600;
    }
    @else if ($weight==bold) {
      font-weight: 700;
    }
    @else if ($weight==black) {
      font-weight: 900;
    }
    @else {
      @warn "#{$weight} is not a valid font-weight."
    }
  };
  