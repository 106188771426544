.search-box .ui-autocomplete-loading {
    background: #fff url('../images/ajax-loader-small.gif') right center no-repeat;
}

.search-box {
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    form {
        position: relative;
        width: 100%;
        @include media-breakpoint-up(lg) {
            margin: 0 $grid-gutter-width * 2;   
        }
        .search-box-text {
            width: 100%;
            height: 50px;
            padding: $grid-gutter-width / 2;
            border: 0;
        }
        .search-box-button {
            position: absolute;
            right: 0;
            min-width: 50px;
            height: 50px;
            border: none;
            background-color: white;
            color: $brand;
            padding: 0 $grid-gutter-width;
        }
    }
 }

 .c-search-wrapper {
     position: relative;
     display: inline-block;
     input {
        font-family: "Font Awesome 5 Pro";
     }
     &::before {
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        top: 0;bottom: 0;right: 0;left: 0;
        margin: auto;
        content: "\f002";
     }

 }


 .c-header-catalog-badge {
    text-align: center;
    line-height: 56px;
    background: $brand;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 16px;
    span {
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        @media (max-width: 1199px){
            font-size: 16px;
        }
    }
    .kamir-chevron-double-down-regular {
        font-size: 14px;
        line-height: 56px;
        @media (max-width: 1199px){
            font-size: 12px;
        }
    }
    @media (max-width: 991px){
        display: none;  
    }
 }

 .c-search-box__wrapper {
     padding: 16px 0 16px 8px;
 }