/********** PRODUCT PAGE **********/

.html-product-details-page {
    .master-wrapper-page {
        background-color: $gray-100;
    }
    .master-wrapper-content {
        padding-top: 0;
    }
    .dirt-element {
        background-color: $gray-100;
        background-image: url(/images/dirt-tran1.png);
    }
}


// Specification Table 1 

.keindl-spec {
    p {
        background-color: $gray-200;
        margin-bottom: 0.3rem;
        padding: $grid-gutter-width;
        @include media-breakpoint-up(lg) {
            display: block;
            padding: 0;
        }
        .label {
            padding: 0;
            margin-right: $grid-gutter-width;
            display: block;
            width: 25%;
            font-weight: bold;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) {
                display: inline-block;
                padding: $grid-gutter-width / 2;
                border-right: 5px solid $gray-200;
            }
        }
        &:nth-child(even) {
            background-color: #ccc;
        }
    }
}

// Specification Table 2

.ui-tabs-panel {
    .row {
        margin: 0;
    }
}

.module_specs {
    .line {
        background-color: $gray-200;
        margin-bottom: 0.3rem;
        .l,
        .r {
            display: inline-block;
        }
        .l {
            padding: $grid-gutter-width / 2;
            width: 25%;
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
        .r {
            padding: 8px;
            width: 75%;
            border-left: 5px solid $gray-200;
        }
        &:nth-child(even) {
            background-color: #ccc;
        }
    }
}






.html-product-details-page {
    .c-global-breadcrumbs {
        display: block;
        padding: $grid-gutter-width / 2 0;
        background-color: $gray-200;
        .breadcrumb {
            display: inline-block;
            margin: 0;
            text-align: center;
        }
    }
    .header {
        position: relative;
        background-color: black;
        box-shadow: none;
    }
}

.gallery {
    .picture {
        position: relative;
        max-width: 100%;
        overflow: hidden;
    }
}

.c-product-stripe {
    background-color: white;
    padding: $grid-gutter-width * 2;
    display: block !important;
    @include media-breakpoint-up(lg) {
        display: flex !important;
    }
    &-headlights {
        ul {
            li {
                display: inline-block;
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
        }
        &--item {
            position: relative;
            .headlights-item--icon {
                position: absolute;
                top: 0;left: 0;
                background-color: $gray-200;
                width: 40px;height: 40px;
                border-radius: 50%;
                line-height: 40px;
                text-align: center;
            }
            .headlights-item--text {
                padding-left: 50px;
                line-height: 40px;
            }
        }
    }
    &-share {
        &--label {
            display: inline-block;
            margin-right: $grid-gutter-width;
        }
        &--content {
            display: inline-block;
            .btn {
                width: 40px;height: 40px;
                line-height: 1px;
                text-align: center;
            }
        }
    }
}

.upper-panel-body {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.upper-panel-body {
    padding: $grid-gutter-width * 2;
}

.lower-panel-body {
    padding: $grid-gutter-width * 4 0 $grid-gutter-width * 2;
}

// Product Page Tabs Rules //

.productTabs-header {
    > ul {
        > li.Dokumenti {
            display: none;
        }
    }
}

.productTabs-body {
    > div.Dokumenti {
        display: none !important;
    }
}

.role-administrators,
.role-forum-moderators,
.role-registered {
    .productTabs-header {
        > ul {
            > li.Dokumenti {
                display: inline-block;
            }
        }
    }
    .productTabs-body {
        > div.Dokumenti {
                display: inline-block !important;
        }
    }
}

.product-essential:after {
    content: "";
    display: block;
    clear: both;
}

.gallery .picture-wrapper {
    margin: 0 0 10px;
}

.gallery .picture img,
.gallery .picture-thumbs img,
.variant-picture img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.gallery .picture-thumbs {
    overflow: hidden;
    font-size: 0;
    margin-top: $grid-gutter-width*2;
    @include media-breakpoint-up(lg) {
        margin: 0;
    }
}

.gallery .picture-thumbs .thumb-item {
    display: inline-block;
    position: relative;
    width: 100px;height: 100px;
    margin: 0 10px 0 0;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.4;
    &:hover {
        opacity: 1;
    }
    &.cloudzoom-gallery-active {
        opacity: 1;
    }
}

.overview {
    position: relative;
    @include media-breakpoint-up(lg) {
        padding-left: $grid-gutter-width * 2;
    }
    &-bottom {
        color: black;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        padding: $grid-gutter-width / 2 0;
    }
}

.overview .discontinued-product {
    background: #f3f3f3;
    margin: 0 0 20px;
}


.overview .discontinued-product h4 {
    display: inline-block;
    font-size: 14px;
    padding: 17px 17px 17px 40px;
    background: url('../images/discontinued-product-icon.png') no-repeat 13px center;
    color: #de444c;
}

.overview {
    .product-price {
        label {
            color: black;
            margin: 0;
        }
    }
    .price-layout-bottom {
        color: $gray-600;
        font-size: 11px;
    }
} 

.overview .short-description {
    margin: 0 0 15px;
    line-height: 22px;
    color: #666;
}

.product-no-reviews,
.product-reviews-overview {
    margin: 0 0 15px;
    color: $gray-700;
}

.sku-number {
    width: 100%;
    margin-top: $grid-gutter-width / 2;
    @include media-breakpoint-up(lg) {
        width: auto;
    }
}

.l-price-input {
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
        margin: 0;
    }
}

.price-layout {
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
        margin: 0;
    }
}

.l-actions-buttons {
    @include media-breakpoint-up(xl) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        max-width: 100%;
    }
    .add-to-cart,
    .add-to-wishlist,
    .compare-product {
        display: inline-block;
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }
    .add-to-wishlist,
    .compare-product {
        margin-left: 0.5rem;
    }
    .compare-product {
        display: none;
        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }
    .price-layout {
        margin: $grid-gutter-width 0;
        @include media-breakpoint-up(lg) {
            margin: 0 $grid-gutter-width / 2;
        }
    }
}


.product-no-reviews a {
    text-decoration: underline;
}

.product-review-box .rating {
    width: 95px;
    height: 14px;
    background: url('../images/rating1.png') repeat-x;
}

.product-review-box .rating div {
    height: 14px;
    background: url('../images/rating2.png') repeat-x;
}

.product-review-links a {
    text-decoration: underline;
}

.product-review-links a:hover {
    color: $gray-600;
}

.overview .value a,
.variant-overview .value a {
    color: $brand;
}

.overview .value a:hover,
.variant-overview .value a:hover {
    text-decoration: underline;
    color:  $gray-600;
}

.overview-buttons {
    margin: 0 0 25px;
    font-size: 0;
}

.overview-buttons div {
    width: 80%;
    margin: 0 auto 3px;
}

.overview .button-2:hover,
.variant-overview .button-2:hover {
}

.overview .add-to-compare-list-button {
    //background-image: url('../images/compare-button.png') !important;
}

.overview .email-a-friend-button {
    //background-image: url('../images/email-button.png') !important;
}

.overview .subscribe-button,
.variant-overview .subscribe-button {
    background-image: url('../images/email-button.png') !important;
}

.overview .download-sample-button,
.variant-overview .download-sample-button {
    background-image: url('../images/download-button.png') !important;
}

.overview .add-to-wishlist-button,
.variant-overview .add-to-wishlist-button {
    //background-image: url('../images/wishlist-button.png') !important;
}


.overview .availability .back-in-stock-subscription,
.variant-overview .availability .back-in-stock-subscription {
    display: inline-block;
}


.overview .delivery,
.variant-overview .delivery {
    overflow: hidden;
}

.overview .free-shipping,
.variant-overview .free-shipping {
    display: none;
    margin: 0 0 15px;
    background: url('../images/shipping.png') left center no-repeat;
    padding: 2px 0 2px 28px;
    font-weight: bold;
    color: $gray-700;
}

.overview .free-shipping.visible,
.variant-overview .free-shipping.visible {
    display: inline-block;
}

.overview .delivery-date,
.variant-overview .delivery-date {
    margin: 10px 0;
}

.overview .min-qty-notification,
.variant-overview .min-qty-notification {
    margin: 10px 0;
    font-style: italic;
    color: $gray-700;
}

.overview .download-sample,
.variant-overview .download-sample {
    display: inline-block;
    margin: 0 0 25px;
}

.overview .old-product-price,
.variant-overview .old-product-price,
.overview .non-discounted-price,
.variant-overview .non-discounted-price {
    color: #999;
    text-decoration: line-through;
}

.overview .discounted-price, {
    font-size: 20px;
    font-weight: bold;
    color: $brand-secondary;
}

.overview .tax-shipping-info a,
.variant-overview .tax-shipping-info a {
    color: $gray-600;
}

.overview .tax-shipping-info a:hover,
.variant-overview .tax-shipping-info a:hover {
    text-decoration: underline;
}

.customer-entered-price {
    margin: 0 0 20px;
    overflow: hidden;
}

.customer-entered-price .price-input label {
    display: block;
    padding: 0 0 5px;
    font-weight: bold;
    color: $gray-700;
}

.customer-entered-price .price-input input {
    width: 170px;
    text-align: center;
}

.customer-entered-price .price-range {
    margin: 5px 0 0;
    color: #777;
}

.tier-prices {
    margin: 0 0 20px;
}

.tier-prices .title {
    display: none;
}

.tier-prices table td {
    border: 1px solid #ddd;
    padding: 10px;
    color: $gray-700;
}

.tier-prices .field-header {
    min-width: 80px;
}

.tier-prices .item-price {
    background-color: #f9f9f9;
    font-size: 16px;
    color: #e4434b;
}

.overview .add-to-cart-panel,
.variant-overview .add-to-cart-panel {
    display: inline-block;
    position: relative;
}


.overview .qty-label,
.variant-overview .qty-label {
    display: none !important;
}

.overview .qty-input,
.variant-overview .qty-input {
    //width: 45px;
    //height: 43px;
    //padding: 0 12px;
    text-align: center;
    color: $gray-700;
}

.overview .qty-dropdown,
.variant-overview .qty-dropdown {
    height: 43px;
    padding: 0 12px;
    font-size: 15px;
    color: $gray-700;
}

.product-share-button {
    display: inline-block;
    overflow: hidden;
    
}

.full-description {
    clear: both;
    line-height: 24px;
}

.product-collateral {
    margin: 0 0 75px;
}

.product-collateral .title {
    margin: 0 0 10px;
    color: $gray-700;
}

.product-collateral .title strong {
    font-weight: normal;
}

.product-variant-line {
    margin: 0 0 30px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 20px;
}

.product-variant-line:after {
    content: "";
    display: block;
    clear: both;
}

.variant-picture {
    position: relative;
    width: 220px;
    height: 220px;
    margin: 0 auto 15px;
    overflow: hidden;
}

.variant-overview {
    width: 100%;
}

.variant-overview .variant-name {
    margin: 0 0 10px;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: $gray-600;
}

.variant-overview .variant-description {
    margin: 0 0 15px;
    line-height: 22px;
}

.variant-overview .add-to-wishlist {
    margin: 0 0 25px;
}

.variant-overview .add-to-wishlist-button {
    display: inline-block;
    width: auto;
}

.variant-overview .add-to-cart {
    margin: 0 0 10px;
}

.product-specs-box {
    margin: 0 0 40px;
    .table-wrapper {
        border: 1px solid $border-color;
        border-radius: 6px;
    }
    table {
        margin: 0;
        tr:nth-child(2) {
            border-top: 1px solid $border-color;
        }
        td {
            border: 0;
        }
        .spec-name {
            border-right: 1px solid $border-color;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}

.product-specs-box td {
    padding: 10px;
}

.product-tags-list {
    font-size: 0;
}

.product-tags-list li {
    display: inline-block;
}

.product-tags-list li.separator {
    margin: 0 5px 0 3px;
}

.product-tags-list a {
    display: inline-block;
    line-height: 24px;
}

.product-tags-list a:hover {
    color: $gray-600;
}

.attributes {
    margin: 0 0 15px;

}

.attributes dl {
    overflow: hidden;
    padding: 0 2px; /* fix for Chrome in Mac, the checkboxes get cut off */
}

.attributes dt,
.attribute-label {
    display: block;
    margin: 0 0 5px;
    white-space: nowrap;
    font-weight: bold;
    color: $gray-700;
}

.attributes dd,
.attribute-data {
    margin: 0 0 10px;
}

.attributes li {
    margin: 8px 0 12px;
}

.attributes select {
    width: auto;
    min-width: 100px;
    max-width: 100%;
}

.attributes .option-list.attribute-squares label {
    margin: 0 5px;
    padding: $grid-gutter-width/3;
    line-height: normal;
    border-radius: 50%;
    box-shadow: inset 0 0 0 5px #fff, 0 0 0 1px #ced4da;
}

.attributes .option-list.attribute-squares .selected-value label {
    box-shadow: inset 0 0 0 5px #fff, 0 0 0 1px $brand;
}



.attributes .datepicker {
    text-align: center;
    color: $gray-700;
}

.attributes .qty-box input {
    width: 48px;
    height: 32px;
    text-align: center;
}

.ui-datepicker {
    width: 280px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    text-align: center;
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none;
    border-radius: 0;
    padding: 0;
    font: normal 14px Arial, Helvetica, sans-serif;
    color: #777;
}

.ui-datepicker-header {
    position: relative;
    height: 32px;
    background-color: #4ab2f1;
    color: #fff;
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none;
    border-radius: 0;
    background-image: none;
    padding: 0 !important;
    font-weight: normal;
}

.ui-datepicker-header a {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 0;
    /*override jQuery UI styles, do not delete doubled properties*/
    top: 0 !important;
    width: 32px !important;
    height: 32px !important;
    border: none !important;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    background: url('../images/calendar-prev.png') center no-repeat;
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url('../images/calendar-next.png') center no-repeat;
}

.ui-datepicker-header a span {
    /*override jQuery UI styles, do not delete doubled properties*/
    display: none !important;
}

.ui-datepicker-title {
    position: relative;
    z-index: 0;
    line-height: 32px;
    font-weight: bold;
    text-transform: uppercase;
    /*override jQuery UI styles, do not delete doubled properties*/
    line-height: 32px !important;
}

.ui-datepicker-calendar {
    /*override jQuery UI styles, do not delete doubled properties*/
    margin: 0 !important;
    font-size: 14px !important;
}

.ui-datepicker-calendar th {
    background-color: #eee;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
    width: 14.285%;
    border: 1px solid #ddd;
    /*override jQuery UI styles, do not delete doubled properties*/
    padding: 0;
}

.ui-datepicker-calendar th span,
.ui-datepicker-calendar td a {
    display: block;
    min-height: 32px;
    line-height: 32px;
    color: $gray-700;
}

.ui-datepicker-calendar td a {
    /*override jQuery UI styles, do not delete doubled properties*/
    border: none !important;
    background: none !important;
    padding: 0;
    text-align: center;
    color: $gray-700 !important;
}

.ui-datepicker-calendar .ui-state-active {
    outline: 2px solid #4ab2f1;
    font-weight: bold;
    /*override jQuery UI styles, do not delete doubled properties*/
    color: #4ab2f1 !important;
}

.attributes-inner {
    .control-label {
        font-weight: bold;
        color: black;
    }
}

/* Size Option Css (Radio Buttons) */

.size-attribute {
    .custom-radio {
        padding-left: 0;
        .custom-control-input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            margin: 0;
            cursor: pointer;
        }
        .custom-control-input:checked + label {
            background-color: $gray-200;
            transform: scale(1.1, 1.1);
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
            border: 0;
            border-radius: 6px;
        }
        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;height: 100%;
            padding: 0.2rem 1rem;
            transition: transform 300ms ease;
            border-radius: 6px;
            border: 1px solid $border-color;
            &::after,
            &::before {
                content: none;
            }
        }
    }
}


.attribute-squares li {
    display: inline-block;
    margin: 0 5px 5px;
    text-align: center;
}

.attribute-squares .attribute-square-container {
    display: block;
    position: relative;
    z-index: 0;
    padding: 1px;
    border-radius: 50%;
}

.attribute-squares label {
    display: block;
    margin: 0 !important;
    overflow: hidden;
}

.attribute-squares .attribute-square {
    display: table !important;
    width: 32px !important;
    height: 32px !important;
    border: 1px solid $gray-700 !important;
    cursor: pointer;
    border-radius: 50%;
}


.attribute-squares li input {
    position: relative;
    z-index: -1;
    margin: -32px 0 0;
}

.tooltip-container {
    position: absolute;
    width: 200px;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #eeeeee;
    text-align: center;
    visibility: hidden;
    border-radius: 5px;
}

.tooltip-container:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-bottom: 7px solid #eeeeee;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
}

.tooltip-container:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-bottom: 6px solid #FFFFFF;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

.tooltip {
    position: initial;
    display: inline-block;
    color: $gray-700;
    opacity:1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background: #f0f0f0 no-repeat 100% 5%;
    border: #c0c0c0 1px dotted;
    text-align: Left;
    padding: 10px 10px 5px 5px;
    position: absolute;
    z-index: 1;
    left: 10%;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    cursor: help;
}

.attribute-squares li:hover .tooltip-container {
    visibility: visible;
    margin-left: -83px;
    margin-top: 5px;
    z-index: 999;
}

.tooltip-container .tooltip-header {
    background-color: #F6F6F6;
    height: 25px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding-left: 10px;
    padding-top: 5px;
    text-align: left;
    width: 198px;
    font-size: 15px;
    border-bottom: 1px #EEEEEE solid;
}

.tooltip-container .tooltip-body {
    margin: 5px;
}

.tooltip-container .tooltip-body img {
    border: 0px #FFF;
    width: 190px;
}

.giftcard {
    margin: 0 0 20px;
    overflow: hidden;
}

.giftcard div {
    margin: 0 0 10px;
}

.giftcard label {
    display: block;
    margin: 0 auto 5px;
    font-weight: bold;
    color: $gray-700;
}

.giftcard input,
.giftcard textarea {
    max-width: 100%;
}

.giftcard textarea {
    height: 150px;
}

/********** PRODUCT REVIEWS **********/
.product-reviews-page h1 a {
    color: $gray-600;
}

.product-reviews-page h1 a:before,
.product-reviews-page h1 a:after {
    content: "''";
}


/*
.product-reviews-page .title {
    margin: 0 0 20px;
    font-size: 20px;
}
*/


/*
.product-reviews-page .title strong {
    font-weight: normal;
}
*/

/*
.write-review {
    background-color: $body-color-light;
    margin: 0 0 60px;
    padding: $grid-gutter-width;
    .fieldset {
        margin: 0 0 30px;
    }
}
*/


.write-review {
    .review-rating {
        margin: 10px 0;
        text-align: left;
    }
}

.write-review .review-rating label {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
}

.write-review .review-rating ul {
    font-size: 0;
    vertical-align: middle;
}

.write-review .review-rating li {
    display: inline-block;
    margin: 0 5px;
    cursor: default;
    vertical-align: middle;
    font-size: 14px;
    height: 17px;
}

.write-review .review-rating li.first {
    color: $brand;
}

.write-review .review-rating li.rating-options {
    padding-top: 2px;
}

.write-review .review-rating li.rating-options input {
    vertical-align: baseline;
}

.write-review .review-rating li.last {
    color: #4cb17c;
}

.write-review .captcha-box {
    margin: 15px 0 0;
}

.product-reviews-page .result,
.product-reviews-page .message-error {
    margin: 0 0 20px;
}

.product-review-list {
    overflow: hidden;
}

.product-review-item {
    margin: 0 0 40px;
}

.product-review-item .review-item-head {
    overflow: hidden;
    background-color: $body-color-light;
    padding: 10px;
}

.product-review-item .review-title {
    line-height: 20px;
    font-size: 15px;
    color: $gray-700;
}

.product-review-item .product-review-box {
    margin: 3px 0 0;
}

.product-review-item .review-content {
    padding: 20px 0;
}

.product-review-item .review-text {
    margin: 0 0 15px;
    line-height: 22px;
}

.product-review-item .reply {
    margin: 15px 0;
    border-top: 1px solid #ddd;
}

.product-review-item .reply-header {
    margin: 15px 0;
    font-weight: bold;
}

.product-review-item .reply-text {
    line-height: 22px;
}

.product-review-item .review-info label {
    font-weight: normal;
}

.product-review-item .review-info a,
.product-review-helpfulness .vote {
    display: inline-block;
    padding: 0 5px;
    color: $gray-600;
    cursor: pointer;
}

.product-review-helpfulness .question {
    margin: 0 5px 0 0;
    font-style: italic;
}

.product-review-helpfulness .result {
    margin: 0 0 0 10px;
}


@media all and (min-width: 481px) {

    /*** PRODUCT PAGE ***/

    .overview-buttons div {
        display: inline-block;
        width: auto;
        margin: 0 1px 2px;
    }

}

@include media-breakpoint-up(md) {

    /*** PRODUCT PAGE ***/

    .variant-picture {
         float: left;
    }
        
    .variant-picture + .variant-overview {
        width: auto;
        margin: 0 0 0 240px;
    }
        
    .variant-overview {
        text-align: left;
    }

    /*** PRODUCT REVIEWS ***/

    .product-review-item .review-title {
        float: left;
        max-width: 600px;
    }

    .product-review-item .product-review-box {
        float: right;
    }

    /*** PRODUCT PAGE ***/

    .variant-picture {
        float: left;
    }

    .variant-picture + .variant-overview {
        width: auto;
        margin: 0 0 0 240px;
    }

    .variant-overview {
        text-align: left;
    }

    /*** PRODUCT REVIEWS ***/

    .product-review-item .review-title {
        float: left;
        max-width: 600px;
    }

    .product-review-item .product-review-box {
        float: right;
    }
}

.write-review .review-rating div {
    display: inline-block;
    margin: 0 0 0 5px;
    cursor: default;
    vertical-align: middle;
    font-size: 14px;
    height: 17px;
}

@include media-breakpoint-up(lg) {

    /*** PRODUCT PAGE ***/
    .gallery .picture {
        width: auto;
    }

    .rental-attributes {
        overflow: hidden;
    }

    .rental-attributes .attribute-item {
        margin: 0 20px 0 0;
    }

    .rental-attributes .datepicker {
        width: 160px;
        text-align: left;
    }

    .attribute-squares li {
        margin: 0 10px 10px 0;
    }
    /*
    .product-details-page .title {
        text-align: left;
    }
    */


    /*** PRODUCT REVIEWS ***/
    .product-review-item .review-title {
        max-width: 850px;
    }
}


@media (max-width: 991px) {
    .order-list-page .section .title {
        text-align: center;
    }
    .form-control-file {
        margin: 0 auto;
    }
    .customer-entered-price .price-input input {
        margin: 0 auto;
    }
    .full-description ul {
        text-align:left;
    }
    .gallery .picture img {
        position:inherit !important;
    }
    .gallery .picture:before {
        padding:0 !important;
    }
    .attributes dd, .attributes dd select, .attributes dd textarea, .attributes dd text {
        margin: 0 auto !important;
    }
}


@media all and (min-width: 1367px) {

    /*** PRODUCT REVIEWS ***/  
      
    .product-review-item .review-title {
        max-width: 1050px;
    }

    .write-review .review-rating div.name-description {
        margin: 0 5px 0 0;
        min-width: 375px;
    }
}

.write-review .review-rating label {
    display: inline-block !important;
}

.product-reviews-page .message-error {
    padding: 10px;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    display: inherit;
}

.product-tags-list {
    display: block;
}

.giftcard label {
    margin: 0 0 5px 0 !important;
}
.write-review .review-rating ul {
    line-height: 23px;
}

.write-review .review-rating li.rating-options .custom-control.custom-radio {
    display: inline-block;
}

@media (max-width: 767px) {
    .col-md-4.product-span .float-left {
        float: none !important;
    }
    label.col-lg-4.col-6.col-form-label {
        text-align: right;
        line-height: 10px;
    }
    .gender {
        margin: 0 auto;
    }
    .required {
        float:none;
    }
    .attributes .btn-dropdown.form-control {
        width: 100% !important;
    }
    .date-of-birth .date-picker-wrapper {
        width:100%;
    }
    .product-selectors select {
        margin: 0 !important;
    }
    .customer-info-page .custom-checkbox {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .vendorinfo-page .vendor-picture * {
        margin: 15px auto;
    }
    .review-rating ul {
        text-align: center !important;
    }
    .custom-checkbox {margin:0 auto;}
    .attributes .row dd {
        margin: 0 auto;
    }
    .order-summary-content .required {
        right: inherit;
        top: inherit;
    }
}

@media only screen and (max-width: 768px) {
    .product-variant-list .product-variant-line .variant-overview .add-to-wishlist-button {
        margin: 0 auto;
    }
}

.product-review-helpfulness .result {
    background-color: transparent;
    border-color: transparent;
}

.product-filter .nav.product-spec-group {
    display:block;
}


.availability-semafor {
    position: relative;
}
.availability-semafor::before {
    content: ' ';
    width: 15px;height: 15px;
    position: absolute;
    top: -13px;right: -17px;
    border-radius: 50%;
    border: 2px solid $gray-200;
}

.availability {
    .value {
        font-size: 0;
    }
}

.availability--zeleno::before {
    background: greenyellow;
}

.availability--crveno::before {
    background: $brand-secondary;
}

.availability--zuto::before {
    background: orange;
}

.additional-details {
    .value {
        color: black;
        font-weight: 600;
    }
}


.offcanvas-rest.active {
    background: rgba(0,0,0,0);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20;  
}

/* previous next product */

.c-previous-next-product__wrapper.c-previous-next-product__wrapper {
    @media only screen and (max-width:991px){
        display: flex;
        justify-content: space-between;
        padding: $grid-gutter-width 0;
        border-bottom: none;
    }
}

.c-previous-next-product__wrapper .previous-product,
.c-previous-next-product__wrapper .next-product {
    a {
        position: relative;
        background-color: $brand;
        color: white;
        font-size: 1.4rem;
        @include media-breakpoint-up(lg) {
            font-size: 1rem; 
            background-color: transparent;
            color: inherit;
        }
        i {
            position: absolute;
            width: 25px;
            height: 25px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            @include media-breakpoint-up(lg) {
                position: relative;
            }
        }
    }
    p {
        display: none;
        @include media-breakpoint-up(lg) { 
            display: inline-block;
        }
    }
}



.c-previous-next-product__wrapper .previous-product {
    display: inline-block;
    @media only screen and (max-width:991px){

        .previous-product-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    @media only screen and (max-width:767px){
        a {
            width: unset;
        }
    }
}

.c-previous-next-product__wrapper .offcanvasBtn {
    font-size: 14px;
    @media only screen and (max-width:991px){
        width: 30%;
        margin: 0;
    }
    @media only screen and (max-width:767px){
        width: 50%;
    
    }
}





.product-essential.product-essential {
    .share-facebook-icon:active,
    .share-facebook-icon:focus,
    .share-facebook-icon:hover,
    .share-twitter-icon:active,
    .share-twitter-icon:focus,
    .share-twitter-icon:hover,
    .add-to-wishlist-btn__wrapper:active .add-to-wishlist-button--desktop,
    .add-to-wishlist-btn__wrapper:focus .add-to-wishlist-button--desktop,
    .add-to-wishlist-btn__wrapper:hover .add-to-wishlist-button--desktop,
    .add-to-wishlist-btn__wrapper:active .add-to-wishlist-button--mobile,
    .add-to-wishlist-btn__wrapper:focus .add-to-wishlist-button--mobile,
    .add-to-wishlist-btn__wrapper:hover .add-to-wishlist-button--mobile,
    .compare-products-btn__wrapper:active .add-to-compare-list-button--mobile,
    .compare-products-btn__wrapper:focus .add-to-compare-list-button--mobile,
    .compare-products-btn__wrapper:hover .add-to-compare-list-button--mobile   {        
        background: none;
        transition: 0.3s;
        border-color:$gray-400;
    }

    .add-to-cart-button--mobile{
        border: none;
    }

    .add-to-wishlist-button--mobile,
    .add-to-compare-list-button--mobile{
        padding: 0 7px;
        border-left: none;
    }

    .add-to-cart-qty-wrapper {
        position: relative;
        display: inline-block;
        width: 80px;height: 50px;
        padding: 0px 30px 0 0;
    }

    .qty-input {
        width: 50px;height: 50px;
        border-radius: 50%;
        border-color: $border-color;
    }
    
    .add-to-cart-button {
        height: 50px;
    }

    .add-to-cart-qty-wrapper .plus, .add-to-cart-qty-wrapper .minus {
        position: absolute;
        right: 0;
        width: 24px;height: 24px;
        line-height: 1.7;
        cursor: pointer;
        transition: all .2s ease-in-out;
        text-align: center;
        background-color: $gray-200;
        border-radius: 50%;
    }

    .add-to-cart-qty-wrapper .plus {
        top: 0;
        margin-bottom: 1px;
    }
    .add-to-cart-qty-wrapper .minus {
        bottom: 0;
    }
    .share-facebook-icon,
    .share-twitter-icon {
        color: $brand;
        background: $gray-300;
        width: 30px;height: 30px;
        padding: 7px 10px;
        font-size: 16px;
    }
    .share-twitter-icon {
        padding: 8px;
        font-size: 13px;
    }
    .addthis_toolbox .custom_images {
        display: flex;
        .addthis_button_facebook{
            margin-right: $grid-gutter-width/4;
        }
    }
    .c-product-share-buttons__wrapper {
        display: flex;
        justify-content: flex-end;
        .email-a-friend{
            margin-right: 10px; 
        }
    }
}

.add-to-wishlist-button,
.add-to-compare-list-button {
    width: 50px;height: 50px;
}


.productTabs-header {
    margin-bottom: 2rem;
    .ui-tabs-nav {
        @media only screen and (max-width:991px){
            display: flex;
            .file-attachments {
                text-align: left;
            }
         }
    }
}
.productTabs-body {
    @media only screen and (max-width:991px){
        .file-attachments {
            text-align: left;
        }
     }
}

 // Theme Tabs

 .ui-widget.ui-widget-content {
    border: 0;
    background-color: transparent;padding: 0;margin: 0;
    .productTabs-header  {
        @include media-breakpoint-up(lg) {
            padding-right: 0;
        }
         .ui-tabs-nav {
            background-color: transparent;
            border: 0;padding: 0 8px;
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }
    .productTabs-body {
        padding: 0 $grid-gutter-width * 2;
        border-left: 1px solid $border-color;
        label {
            color: black;
            font-weight: bold;
            text-transform: uppercase;
        }
        input,
        textarea {
            padding: $grid-gutter-width / 2;
            border: 0;
            border-radius: 6px;
            background-color: white;
        }
        .buttons {
            text-align: right;
        }
    }
 }

 .ui-tabs .ui-tabs-nav li.ui-tabs-active {
    margin-bottom: -0;
    padding-bottom: 0;
    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;right: 0;
        margin: auto;
        width: 100%;height: 3px;
        background-color: $brand;
        z-index: 9;
        @include media-breakpoint-up(lg) {
            content: "";
            display: block;
            position: absolute;
            top: 0;bottom: 0;right: -3px;
            margin: auto;
            width: 5px;height: 100%;
            background-color: $brand;
            z-index: 9;
        }
    }
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 0;
    margin: 0 !important;
    background: transparent;
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    padding: 1rem 1.4rem;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    color: initial;
    background-color: transparent;
    &:hover {
        background-color: $gray-200;
    }
}

.ui-tabs .ui-tabs-nav .ui-tabs-active {
    background-color: $gray-200;
}

.c-last-view-products {
    width: 100%;
}

.jCarouselMainWrapper {
    .item-box {
        margin: 2%;
    }
}