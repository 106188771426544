.poll strong {
    display: block;
    margin: 10px 0;
    background-color: #f6f6f6;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: normal;
    color: #444;
    text-transform: uppercase;
}

.poll-options,
.poll-results {
    margin: 10px 0 15px;
    overflow: hidden;
    font-size: 15px;
    color: #444;
}

.poll-options li,
.poll-results li {
    margin: 10px 0;
}

.poll-options li > input {
    margin: 0 5px 0 0;
    cursor: pointer;
}

.poll-options li > label {
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

.poll .buttons input {
    border: none;
    background-color: #aaa;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
}

.poll .buttons input:hover {
    background-color: #bbb;
}

.poll-total-votes {
    display: block;
    margin: 10px 0 0;
    font-weight: bold;
    font-style: italic;
    color: #444;
}

.home-page-polls {
    text-align: center;
}

.home-page-polls .title {
    border-bottom: 1px solid #ddd;
    color: #444;
    font-size: 30px;
    font-weight: normal;
    margin: 0 0 30px;
    padding: 0 0 15px;
}

.home-page-polls .title strong {
    font-weight: normal;
}

.home-page-polls li {
    display: inline-block;
    margin: 10px;
}