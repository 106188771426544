/*** FORUM & PROFILE ***/

.forums-main-page .topic-block {
    margin: 0 0 35px;
    text-align: center;
}

.forums-main-page .topic-block p {
    text-align: center;
}

.forums-main-page .pager {
    display: none;
}

.forum-breadcrumb {
    margin: 0 0 40px;
}

.forums-header {
    margin: 0 0 50px;
    overflow: hidden;
}

.forums-header:after {
    content: "";
    display: block;
    clear: both;
}

.forums-header .current-time {
    margin: 0 0 15px;
    color: #444;
}

.forum-search-box .basic {
    overflow: hidden;
    margin: 0 0 10px;
}

.forum-search-box .advanced a {
    line-height: 22px;
    color: #444;
    text-decoration: underline;
}

.forum-search-box .advanced a:hover {
    color: #4ab2f1;
}

.forum-search-page .page-title {
    margin: 0 0 -1px;
}

.forum-search-page .search-error,
.forum-search-page .no-result {
    margin: 0 0 15px;
    text-align: center;
    color: #e4434b;
}

/* forum group */

.forums-table-section {
    margin: 0 0 60px;
}

.forums-table-section + .pager {
    margin: -30px 0 30px;
}

.forums-table-section-title {
    margin: 0 0 15px;
    padding: 0 10px;
}

.forums-table-section-title a,
.forums-table-section-title strong {
    font-size: 22px;
    font-weight: normal;
    color: #444;
}

.forums-table-section-title a:hover {
    color: #4ab2f1;
}

.forums-table-section .image div {
    width: 38px;
    height: 32px;
    margin: auto;
    background: url('../images/topic-type1.png') center no-repeat;
}

.forums-table-section .image div.sticky {
    background: url('../images/topic-type2.png') center no-repeat;
}

.forums-table-section .image div.announcement {
    background: url('../images/topic-type3.png') center no-repeat;
}

.forums-table-section .forum-title,
.forums-table-section .topic-title {
    margin: 5px 0;
}

.forums-table-section .forum-title a,
.forums-table-section .topic-title a {
    font-size: 16px;
    font-weight: bold;
    color: #444;
}

.forums-table-section .forum-title a:hover,
.forums-table-section .topic-title a:hover {
    color: #4ab2f1;
    text-decoration: none;
}

.forums-table-section .topic-title span {
    display: block;
    color: #777;
}

.forums-table-section .forum-description,
.forums-table-section .topic-starter {
    margin: 5px 0;
}

.forums-table-section .latest-post {
    white-space: nowrap;
}

.forums-table-section .latest-post div {
    margin: 5px 0;
}

.forums-table-section .latest-post label {
    color: #777;
}

.forums-table-section .view-all {
    margin: 30px 0 0;
    padding: 0 10px;
}

.forums-table-section .view-all a {
    background-color: #4ab2f1;
    /*padding: 10px 15px;
	font-size: 12px;*/
    color: #fff;
    text-transform: uppercase;
}

.forums-table-section .view-all a:hover {
    background-color: #248ece;
}

/* forum-page, topic page */

.forum-page .forum-info {
    margin: 0 0 30px;
}

.forum-page .forum-name,
.forum-topic-page .topic-name {
    margin: 0 0 25px;
    border-bottom: 1px solid #ddd;
    padding: 0 10px 10px;
}

.forum-page .forum-name h1,
.forum-topic-page .topic-name h1 {
    font-size: 30px;
    font-weight: normal;
}

.forum-actions,
.topic-actions {
    margin: 0 0 30px;
    font-size: 0;
}

.forum-actions .actions a,
.topic-actions .actions a {
    display: inline-block;
    margin: 1px;
    padding: 10px 10px 10px 33px;
    font-size: 14px;
}

.forum-actions .actions .new-topic {
    background: #eee url('../images/new.png') left center no-repeat;
}

.forum-actions .actions .watch-forum,
.topic-actions .actions .watch-forum {
    background: #eee url('../images/watch.png') left center no-repeat;
}

.forum-actions .actions a:hover,
.topic-actions .actions a:hover {
    background-color: #e6e6e6;
}

.forum-actions .pager.upper,
.topic-actions .pager.upper {
    display: none;
    margin: 0;
}

.topic-actions .reply-topic-button {
    background: #eee url('../images/reply.png') left center no-repeat;
}

.topic-actions .watch-topic-button {
    background: #eee url('../images/watch.png') left center no-repeat;
}

.topic-actions .move-topic-button {
    background: #eee url('../images/move.png') left center no-repeat;
}

.topic-actions .edit-topic-button {
    background: #eee url('../images/edit_.png') left center no-repeat;
}

.topic-actions .delete-topic-button {
    background: #eee url('../images/remove_.png') left center no-repeat;
}

.topic-actions.lower .actions {
    display: none;
}

.active-discussions-page .forums-table-section-title {
    margin: 0 0 25px;
    border-bottom: 1px solid #ddd;
    padding: 0 10px 10px;
}

.active-discussions-page .forums-table-section-title strong {
    font-size: 30px;
    font-weight: normal;
}

.active-discussions-page .forums-table-section-body {
    margin: 0 0 20px;
}

/* topic post */

.topic-post {
    margin: 0 0 30px;
    border-top: 1px solid #ddd;
}

.topic-post:last-child {
    border-bottom: 1px solid #ddd;
}

.topic-post:after {
    content: "";
    display: block;
    clear: both;
}

.topic-post .post-info {
    margin: 30px 0;
}

.topic-post .user-info {
    width: 150px;
    margin: 0 auto 15px
}

.topic-post .username {
    display: block;
    margin: 0 0 -1px;
    border: 1px solid #ddd;
    background-color: #f6f6f6;
    padding: 11px 0;
    font-weight: bold;
    color: #444;
}

.topic-post a.username:hover {
    color: #4ab2f1;
}

.topic-post .avatar,
.profile-info-box .avatar {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    overflow: hidden;
}

.topic-post .avatar img,
.profile-info-box .avatar img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.topic-post .avatar img {
    border: solid 2px #CEDBE1 !important;
}

.topic-post .user-stats {
    margin: 0 0 15px;
}

.topic-post .user-stats li {
    display: inline-block;
    margin: 0 10px;
}

.topic-post .user-stats span,
.profile-info-box .profile-stats span {
    margin: 0 0 0 3px;
    font-weight: bold;
    color: #444;
}

.topic-post .pm-button,
.profile-info-box .pm-button {
    display: inline-block;
    border: none;
    background-color: #888;
    padding: 10px 20px;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.topic-post .pm-button:hover,
.profile-info-box .pm-button:hover {
    background-color: #999;
}

.topic-post .post-time {
    margin: 0 0 15px;
    background-color: #f6f6f6;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: bold;
}

.topic-post .post-time span {
    color: #444;
}

.topic-post .post-actions {
    margin: 0 0 15px;
    font-size: 0;
}

.topic-post .post-actions > div {
    display: inline-block;
}

.topic-post .post-actions a {
    display: inline-block;
    margin: 1px 5px;
    font-size: 14px;
}

.topic-post .post-actions .edit-post-button,
.topic-post .post-actions .delete-post-button {
    padding: 10px 10px 10px 33px;
}

.topic-post .post-actions .edit-post-button {
    background: url('../images/edit_.png') left center no-repeat;
}

.topic-post .post-actions .delete-post-button {
    background: url('../images/remove_.png') left center no-repeat;
}

.topic-post .post-actions .edit-post-button:hover,
.topic-post .post-actions .delete-post-button:hover {
    color: #4ab2f1;
}

.topic-post .post-actions .post-link-button {
    padding: 10px;
    font-weight: bold;
    color: #4ab2f1;
}

.topic-post .post-actions .post-link-button:hover {
    text-decoration: underline;
}

.topic-post .post-actions .quote-post-button {
    background-color: #888;
    padding: 10px 20px;
    color: #fff;
}

.topic-post .post-actions .quote-post-button:hover {
    background-color: #999;
    color: #fff;
}

.topic-post .post-text {
    /*topic post-text also gets style from blog post-body*/
    padding: 0 10px 20px;
    line-height: 22px;
    color: #444;
    margin-bottom: 0;
}

.topic-post .post-vote {
    text-align: center;
    display: table;
    margin: 0 auto 20px;
}

.topic-post .user-posted-image,
.latest-posts .user-posted-image {
    max-width: 100%;
    height: auto;
}

.topic-post .quote,
.latest-posts .quote {
    margin: 10px 0;
    border: 1px dashed #ccc;
    background-color: #f9f9f9;
    padding: 10px;
    color: #777;
}

.topic-post .signature {
    border-top: 1px solid #ddd;
    padding: 20px;
    font-size: 13px;
    font-style: italic;
    clear: both;
}

.topic-post .post-vote span.vote {
    cursor: pointer;
    display: block;
    width: 40px;
    height: 24px;
}

.topic-post .post-vote span.up {
    background: url('../images/vote-up.png') no-repeat 50% 50%;
}

.topic-post .post-vote span.vote.up.selected {
    background: url('../images/vote-up-selected.png') no-repeat 50% 50%;
}

.topic-post .post-vote span.down {
    background: url('../images/vote-down.png') no-repeat 50% 50%;
}

.topic-post .post-vote span.vote.down.selected {
    background: url('../images/vote-down-selected.png') no-repeat 50% 50%;
}

.topic-post .vote-count-post {
    display: block;
    font-size: 160%;
}
/* forum edit & send message */

.forum-edit-page .page-title,
.move-topic-page .page-title,
.private-message-send-page .page-title {
    margin: 0 0 -1px;
}

.move-topic-page label {
    white-space: normal;
}

.forum-edit-page .message-error,
.private-message-send-page .message-error {
    margin: 20px 0;
}

.forum-edit-page .inputs strong {
    display: inline-block;
    max-width: 400px;
    font-size: 22px; /*reset zeroing*/
    font-weight: normal;
    color: #444;
    white-space: normal;
}

.forum-edit-page .inputs .topic-subject {
    font-size: 17px;
}

.forum-edit-page .inputs.reversed {
    margin: 0;
}

.forum-edit-page .inputs.reversed label {
    width: auto;
    margin: 5px;
    font-size: 14px; /*reset zeroing*/
    white-space: normal;
}

.forum-edit-page .bb-code-editor-wrapper,
.private-message-send-page .bb-code-editor-wrapper {
    margin: 20px auto;
    background-color: #ddd;
    padding: 10px;
}

.forum-edit-page .toolbar .button,
.private-message-send-page .toolbar .button {
    margin: 0 2px 0 0;
    border: #cec6b5 1px solid;
    padding: 2px;
}

.forum-edit-page .toolbar .button:hover,
.private-message-send-page .toolbar .button:hover {
    border: #333 1px solid;
}

.forum-edit-page textarea,
.private-message-send-page textarea {
    display: block;
    margin: auto;
    height: 100%;
}

.forum-edit-page .buttons input,
.move-topic-page .buttons input,
.private-messages .buttons input {
    min-width: 140px;
    border: none;
    background-color: #999;
    padding: 10px 30px;
    text-align: center;
    font-size: 15px !important;
    color: #fff;
    text-transform: uppercase !important;
}

.forum-edit-page .buttons input:hover,
.move-topic-page .buttons input:hover,
.private-messages .buttons input:hover {
    background-color: #888;
}

.forum-edit-page .buttons .button-1,
.move-topic-page .buttons .button-1,
.private-messages .buttons .button-1 {
    background-color: #4ab2f1;
}

.forum-edit-page .buttons .button-1:hover,
.move-topic-page .buttons .button-1:hover,
.private-messages .buttons .button-1:hover {
    background-color: #248ece;
}

.private-message-send-page a {
    font-size: 14px;
    color: #4ab2f1;
}

.private-message-send-page span {
    font-size: 14px;
    line-height: 24px;
}

/* jQuery tabs */

/*
.ui-tabs {
    override jQuery UI styles, do not delete doubled properties
    padding: 0 !important;
    border: none !important;
}
*/
/*
.ui-tabs-nav {
    margin: 0 0 30px;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    font-size: 0;

    margin: 0 0 30px !important;
    border-width: 0 0 1px;
    border-radius: 0;
    background: none;
    padding: 0 !important;
    line-height: normal;
    font-weight: normal;
    color: #444;
}

.ui-tabs-nav li {
    margin: 0 0 -1px;

    
    float: none !important;
    margin: 0 0 -1px !important;
    border: none !important;
    border-radius: 0;
    background: none !important;
    padding: 0 !important;
}

.ui-tabs-nav li a {
    display: block;
    border: 1px solid #ddd;
    padding: 12px 24px;
    text-align: center;
    font-size: 18px;
    color: #444;
    float: none !important;
    padding: 12px 24px !important;
    @media only screen and (max-width: 767px) {
        padding: 12px !important;
    }
}

*/
/*
.productTabs-header .ui-tabs-nav {
    border-bottom: 2px solid $brand;
}

.productTabs-header .ui-tabs-nav li a  {
    border:none;
    color: $gray-700; 
    font-size: 1rem;
    font-weight: 600;
    @media only screen and (min-width: 992px) {
        font-size: 1rem;
    }
}
*/

/*
.ui-tabs-nav li.ui-state-active a {
    background-color: $body-color-light !important;
    color: $brand !important;
}
*
/*
.productTabs-header .ui-tabs-nav li.ui-state-active a {
    background-color: $brand !important;
    color: $white !important;
    border:none;
}
*/

.ui-tabs-panel {
    /*override jQuery UI styles, do not delete doubled properties*/
    padding: 0 !important;
}

/* inbox & view message */

.private-messages-page .table-wrapper,
.private-message-view-page .view-message {
    margin: 0 0 30px;
}

.private-messages-page th.select {
    text-align: center;
}

.private-messages-page td.from,
.private-messages-page td.to,
.private-messages-page td.subject {
    min-width: 150px;
}

.private-messages-page td.date {
    white-space: nowrap;
}

.private-messages-page td.subject a {
    color: #444;
}

.private-messages-page td.subject a.pm-unread {
    color: #4ab2f1;
}

.private-messages-page .pager {
    margin: 0 0 30px;
}

.private-messages-page .buttons,
.private-message-view-page .buttons {
    font-size: 0;
}

.private-messages-page .buttons .button-1,
.private-messages-page .buttons .button-2,
.private-message-view-page .buttons .button-1,
.private-message-view-page .buttons .button-2 {
    display: block;
    margin: 0 auto 3px;
    font-size: 15px; /*reset zeroing*/
}

.private-message-view-page .view-message {
    margin: 0 0 30px;
}

.private-message-view-page .message-head {
    margin: 0 0 20px;
    line-height: 22px;
}

.private-message-view-page .message-head span {
    font-weight: bold;
    color: #444;
}

.private-message-view-page .message-body {
    border-top: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 30px 20px;
    line-height: 22px;
    text-align: justify;
}

.private-message-view-page .back-pm-button {
    text-transform: capitalize !important;
}

/* profile page  */

.profile-info-box {
    overflow: hidden;
    /*override jQuery UI styles, do not delete doubled properties*/
    line-height: normal;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #777;
}

.profile-info-box .user-details {
    margin: 0 0 30px;
}

.profile-info-box .avatar {
    margin: 0 auto 15px;
}

.profile-info-box .title {
    margin: 0 0 10px;
    font-size: 18px;
    color: #444;
}

.profile-info-box .stats {
    margin: 0 0 15px;
}

.latest-posts {
    /*override jQuery UI styles, do not delete doubled properties*/
    line-height: normal;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #777;
}

.latest-posts .topic {
    margin: 0 0 35px;
}

.latest-posts .topic-title {
    margin: 0 0 20px;
    border-top: 1px solid #ddd;
    background: #f6f6f6;
    padding: 10px;
    font-size: 16px;
}

.latest-posts .topic-title a {
    font-weight: bold;
    color: #444;
}

.latest-posts .topic-title a:hover {
    color: #4ab2f1;
}

.latest-posts .topic-body {
    margin: 0 0 15px;
    line-height: 22px;
}

.latest-posts .topic-data {
    color: #444;
}

/* BB codes */

pre {
    white-space: pre-wrap;
    white-space: normal;
    word-wrap: break-word;
}

.csharpcode {
    margin: 10px 0;
    border: 1px dashed #ccc;
    background-color: #fff;
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
    color: #000;
}

.csharpcode .rem {
    color: green;
}

.csharpcode .kwrd {
    color: #00f;
}

.csharpcode .str {
    color: #006080;
}

.csharpcode .op {
    color: #00c;
}

.csharpcode .preproc {
    color: #c63;
}

.csharpcode .asp {
    background-color: #ff0;
}

.csharpcode .html {
    color: maroon;
}

.csharpcode .attr {
    color: red;
}

.csharpcode .alt {
    width: 100%;
    margin: 0;
    background-color: #f6f6f6;
}

.csharpcode .lnum {
    color: #666;
}


/*********** TOPICS ***********/

/*
.topic-block {
    margin: 0 0 25px;
}
*/

.topic-block-title h2 {
    font-weight: normal;
}

/*
.topic-block-body {
    text-align: justify;
    line-height: 24px;
}
*/

.topic-page .page-body {
    line-height: 22px;
}

.topic-block a,
.topic-page a {
    text-decoration: underline;
}

.topic-block strong,
.topic-page strong {
    color: #444;
}

.home-page .topic-block {
    margin-bottom: 50px;
}

.popup-window .topic-page {
    padding: 15px;
}

.not-found-page p {
    margin: 30px 15px;
}

.topic-password {
    text-align: center;
}

.enter-password-title {
    margin: 0 0 20px;
    color: #444;
}

.enter-password-form {
    display: inline-block;
    overflow: hidden;
}

.enter-password-form input[type="password"] {
    float: left;
    width: 200px;
    height: 40px;
    margin: 0 -1px 0 0;
}

.enter-password-form input[type="submit"] {
    float: left;
    width: auto;
    min-width: 86px;
    height: 40px;
    border: none;
    background-color: #4ab2f1;
    padding: 0 15px;
    color: #fff;
    text-transform: uppercase;
}

.enter-password-form input[type="submit"]:hover {
    background-color: #248ece;
}

@media all and (min-width: 481px) {

    /*** TOPICS ***/

    .enter-password-form input[type="password"] {
        width: 260px;
    }

     /*** FORUM & PROFILE ***/

    .forums-header {
        background-color: #f9f9f9;
        padding: 30px 20px 20px;
    }
    /*
    .ui-tabs-nav li {
        display: inline-block;
        min-width: 150px;
        margin: 0 0 -1px;
        margin: 0 0 -1px !important;
    }
    */

    .private-messages-page .buttons .button-1,
    .private-messages-page .buttons .button-2,
    .private-message-view-page .buttons .button-1,
    .private-message-view-page .buttons .button-2 {
        display: inline-block;
        width: auto;
        margin: 1px;
    }

    .topic-post .post-text {
        float: left;
        width: 90.5%;
        text-align: left;
    }

    .forum-group .forum-table th.latest-post,
    .forum-group .forum-table th.topics,
    .forum-group .forum-table th.posts,
    .forum-group .forum-table td.latest-post,
    .forum-group .forum-table td.topics,
    .forum-group .forum-table td.posts {
        display: table-cell;
    }

    .topic-group .forum-table th.latest-post,
    .topic-group .forum-table td.latest-post {
        display: table-cell;
    }

    .forum-table td.image {
        max-width: 40px;
    }

    .forum-table td.image div {
        max-width: 100%;
        background-size: contain;
    }
}

@media all and (min-width: 769px) {

    /*** FORUM & PROFILE ***/

    .forum-actions,
    .topic-actions {
        overflow: hidden;
    }

    .forum-actions .actions,
    .topic-actions .actions {
        float: left;
    }

    .forum-actions .pager,
    .topic-actions .pager {
        float: right;
        max-width: 450px;
    }

    .forum-actions .pager li,
    .topic-actions .pager li {
        margin: 2px 4px;
    }

    .forum-actions .pager.upper {
        display: block;
    }

    .topic-actions.lower .actions {
        display: block;
    }

    .topic-posts {
        margin: 0 0 30px;
    }

    .topic-post {
        display: table;
        width: 100%;
        margin: 0;
    }

    .topic-post .post-info {
        display: table-cell;
        width: 15%;
        margin: 0;
        vertical-align: top;
        text-align: left;
    }

    .topic-post .user-info {
        width: auto;
    }

    .topic-post .username {
        min-height: 40px;
        margin: 0;
        border: none;
        text-align: center;
    }

    .topic-post .avatar {
        margin: auto;
        border: none;
    }

    .topic-post .avatar img {
        max-width: 80%;
        max-height: 80%;
    }

    .topic-post .user-stats li {
        margin: 0;
    }

    .topic-post .send-pm {
        margin: 0 0 20px;
    }

    .topic-post .pm-button,
    .profile-info-box .pm-button {
        padding: 8px 16px;
        font-size: 13px;
    }

    .topic-post .post-content {
        display: table-cell;
        width: 85%;
        border-left: 1px solid #ddd;
        vertical-align: top;
        text-align: left;
    }

    .topic-post .post-head {
        min-height: 40px;
        overflow: hidden;
        background-color: #f6f6f6;
    }

    .topic-post .post-time {
        float: left;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
    }

    .topic-post .post-actions {
        float: right;
        margin: 0;
    }

    .topic-post .post-actions a {
        margin: 0;
        line-height: 20px;
    }

    .topic-post .post-actions .manage-post a {
    }

    .topic-post .post-actions .quote-post a {
        min-width: 70px;
    }

    .topic-post .post-actions a.post-link-button {
        margin: 0 0 0 10px;
        border-left: 1px solid #ddd;
        text-align: center;
    }

    .topic-post .post-text {
        padding: 20px 5px;
    }

    .topic-post .post-vote {
        padding: 20px 0 20px 15px;
    }

    .forum-table th.replies,
    .forum-table th.views,
    .forum-table th.votes,
    .forum-table td.replies,
    .forum-table td.views,
    .forum-table td.votes {
        display: table-cell;
    }

    .forum-table td.image {
        max-width: none;
    }

    .forum-table td.image div {
        max-width: none;
        background-size: initial;
    }
    /*
    .ui-tabs-nav {
        text-align: left;
    }

    .ui-tabs-nav li {
        margin: 0 10px -1px 0;
        override jQuery UI styles, do not delete doubled properties
        margin: 0 10px -1px 0 !important;
    }
    */

    .profile-info-box .avatar {
        float: left;
        margin: 0 15px 15px 0;
    }

    .profile-info-box .profile-stats {
        float: left;
        text-align: left;
    }

    .profile-info-box .title {
        text-align: left;
    }

    .profile-info-box .send-pm {
        clear: both;
        text-align: left;
    }
}

@media all and (min-width: 992px) {

    /*** TOPICS ***/
    
    .center-1 .topic-block-title {
        text-align: center;
    }

    /*** FORUM & PROFILE ***/
    
    .forum-breadcrumb {
        margin: -30px 0 30px;
    }

    .current-time {
        float: right;
    }

    .forum-search-box {
        float: left;
    }

    .forum-search-box .advanced {
        text-align: left;
    }

    .forum-search-box .advanced a {
        line-height: normal;
    }

    .forums-table-section td {
        min-width: 150px;
    }

    .forums-table-section td:first-child {
        min-width: 90px;
    }

    .forums-table-section .view-all {
        text-align: right;
    }

    .topic-actions .pager.upper {
        display: block;
        max-width: 400px;
    }

    .forum-table .forum-details,
    .forum-table .topic-details {
        min-width: 225px;
    }

    .forum-table td {
        padding: 20px;
    }

    .forum-edit-page textarea,
    .private-message-send-page textarea {
        height: 100%;
    }

    .private-message-send-page .bb-code-editor-wrapper {
        display: inline-block;
        width: 100%;
        margin: 10px 0;
        vertical-align: middle;
    }

}



@media all and (min-width: 1001px) {

    /*** TOPICS ***/

    .center-1 .topic-block-title {
        text-align: center;
    }

    /*** FORUM & PROFILE ***/

    .forum-breadcrumb {
        margin: -30px 0 30px;
    }

    .current-time {
        float: right;
    }

    .forum-search-box {
        float: left;
    }

    .forum-search-box .advanced {
        text-align: left;
    }

    .forum-search-box .advanced a {
        line-height: normal;
    }

    .forums-table-section td {
        min-width: 150px;
    }

    .forums-table-section td:first-child {
        min-width: 90px;
    }

    .forums-table-section .view-all {
        text-align: right;
    }

    .topic-actions .pager.upper {
        display: block;
        max-width: 400px;
    }

    .forum-table .forum-details,
    .forum-table .topic-details {
        min-width: 225px;
    }

    .forum-table td {
        padding: 20px;
    }

    .forum-edit-page textarea,
    .private-message-send-page textarea {
        width: 600px;
        height: 250px;
    }

    .private-message-send-page .bb-code-editor-wrapper {
        display: inline-block;
        width: 400px;
        margin: 10px 0;
        vertical-align: middle;
    }

}

@media all and (min-width: 1367px) {


    /*** FORUM & PROFILE ***/
    
    .forum-actions .pager,
    .topic-actions .pager {
        max-width: 600px !important;
    }

    .topic-post .post-text {
        width: 930px;
        max-width: 100%;
    }
    /* Root Theme CSS */

    .theme-custom {
        max-width: 100% !important;
        margin: 0 auto 60px !important;
    }
    .navbar-toggleable-md .navbar-nav {
        margin-top: 40px;
    }
    .no-lr {
        padding-left:0;
        padding-right:0;
    }
    .s-box {
        padding-left: 0;
        padding-right: 0;
    }

    /*** FORUM & PROFILE ***/

    .forum-actions .pager,
    .topic-actions .pager {
        max-width: 600px !important;
    }
    
    .topic-post .post-text {
        width: 93%;
        max-width: 100%;
    }
    
}

@media (max-width:480px) {    
    .forum-edit-page .buttons input, .move-topic-page .buttons input, .private-messages .buttons input {
        margin-bottom: 10px;
    }
}

.forum-edit-page textarea, .private-message-send-page textarea {
    width: 100%;
}